import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IFraewCladdingSystemType } from 'types/applications/ApplicationEditTypes';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const lookUpApi = createApi({
  reducerPath: 'lookUpApi',
  baseQuery,
  endpoints: builder => ({
    getManufactures: builder.query<IFraewCladdingSystemType[], void>({
      query: () => `manufacturers`,
    }),
    getCladdingSystemTypes: builder.query<IFraewCladdingSystemType[], void>({
      query: () => `claddingSystemTypes`,
    }),
    getInsulationTypes: builder.query<IFraewCladdingSystemType[], void>({
      query: () => `insulationTypes`,
    }),
  }),
});

export const {
  useGetCladdingSystemTypesQuery,
  useGetManufacturesQuery,
  useGetInsulationTypesQuery,
} = lookUpApi;
