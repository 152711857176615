import { ERejectedPaymentStatus } from 'enums/ERejectedPaymentsStatus';
import { ERiskStatus } from 'enums/ERiskStatus';
import { ETeamRole } from 'enums/ETeamRole';
import {
  EPaymentRecommendationStatus,
  IClosingPaymentRequestChecks,
  IClosingPaymentRequestChecksDetails,
  IClosingPaymentRequestOverview,
  IPaymentRequestChecks,
  IPaymentRequestChecksDetails,
  IPaymentRequestCosts,
  IPaymentRequestOverview,
  IPaymentRequestProjectTeam,
  IPaymentRequestReferral,
  IPaymentRequestVariations,
  IPaymentRequestClosingChecks,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  EThirdPartyReferrerType,
  IReferralListItem,
  EReferralStatus,
  EReferralType,
  IThirdPartyReferral,
} from 'types/applications/ReferralTypes';
import { getGuid } from 'util/AppUtils';

export const applicationPaymentRequestOverviewData: IPaymentRequestOverview = {
  totalGrantFunding: 1300000,
  totalGrantPaidToDate: 1300000,
  totalUnClaimedGrant: 1300000,
  projectDurationInMonths: 13,
  paymentMonth: 'August',
  recommendedAmount: 100000,
  additionalRequestedAmountTitle: 'More money',
  additionalRequestedAmount: 25000,
  requestedAmount: 150000,
  scheduledAmount: 125000,
  operationsReasonForCostChange:
    'Consequat duis eiusmod elit do nisi sunt elit consequat consectetur id ipsum amet. Sint anim consectetur aliqua laboris cupidatat nisi commodo exercitation veniam officia excepteur ex.',
  paymentRecommendation: EPaymentRecommendationStatus['Submitted'],
  paymentRecommendationBy: 'John Doe',
  paymentRecommendationDate: '2022-08-20',
  paymentRecommendationReason: '',
  costReports: [
    {
      id: '1',
      name: 'Cost Report 1',
      extension: 'pdf',
    },
    {
      id: '2',
      name: 'Cost Report 1',
      extension: 'pdf',
    },
  ],
  additionalRecommendedAmountTitle: 'Need more money',
  additionalRecommendedAmount: 10000,
  applicantReasonForCostChange:
    'Qui cupidatat cillum eu ex qui ex commodo labore ad incididunt. Et pariatur nostrud veniam ad sunt voluptate qui enim aute. Nisi ipsum deserunt sunt culpa. Amet consequat mollit aliquip est eu laboris aute anim tempor do qui amet commodo ullamco.',
  submittedDate: '2023-11-09',
};

export const referrals: IReferralListItem[] = [
  {
    id: getGuid(),
    companyName: 'Company 1',
    status: EReferralStatus.InProgress,
    type: EReferralType.HomesEngland,
  },
  {
    id: getGuid(),
    companyName: 'Company 2',
    status: EReferralStatus.Complete,
    type: EReferralType.ThirdParty,
    cost: 123000,
    endDate: '2023-09-01',
    startDate: '2023-08-01',
    thirdPartyReferrerType: EThirdPartyReferrerType.CostConsultant,
  },
  {
    id: getGuid(),
    companyName: 'Company 3',
    status: EReferralStatus.Complete,
    type: EReferralType.ThirdParty,
    cost: 25600,
    endDate: '2023-10-30',
    startDate: '2023-08-01',
    thirdPartyReferrerType: EThirdPartyReferrerType.Legal,
  },
];

export const singleReferral: IThirdPartyReferral = {
  id: getGuid(),
  status: EReferralStatus.InProgress,
  companyEmail: 'email@company1.com',
  companyName: 'Company 1',
  companyPhone: '071717171718',
  cost: 123000,
  createdByUser: 'Steven Toast',
  dateCreated: '2023-09-15',
  description:
    'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  endDate: '2023-09-01',
  startDate: '2023-08-01',
  thirdPartyType: EThirdPartyReferrerType.CostConsultant,
};

export const applicationPaymentRequestClosingChecks: IPaymentRequestClosingChecks =
  {
    projectCostEvidenceFiles: [
      {
        id: '1',
        name: 'Project Cost Evidence File 1',
        extension: 'pdf',
      },
      {
        id: '2',
        name: 'Project Cost Evidence File  2',
        extension: 'pdf',
      },
    ],
    buildingRegulatoryApprovalFiles: [
      {
        id: '1',
        name: 'Building Regulatory Approval File 1',
        extension: 'pdf',
      },
    ],
    practicalCompletionCertificateFiles: [
      {
        id: '1',
        name: 'Practical Completion Certificate File 1',
        extension: 'pdf',
      },
    ],
    exitFraewFiles: [
      {
        id: '1',
        name: 'Exit FRAEW File 1',
        extension: 'pdf',
      },
      {
        id: '2',
        name: 'Exit FRAEW File 2',
        extension: 'pdf',
      },
    ],
    leaseholderAndResidentEvidenceFiles: [
      {
        id: '1',
        name: 'Leaseholder And Resident Evidence File 1',
        extension: 'pdf',
      },
      {
        id: '2',
        name: 'Leaseholder And Resident Evidence File 2',
        extension: 'pdf',
      },
    ],
    worksContractFiles: [
      {
        id: '1',
        name: 'Works Contract Document 1',
        extension: 'pdf',
      },
      {
        id: '2',
        name: 'Works Contract Document 2',
        extension: 'pdf',
      },
    ],
  };

export const applicationClosingPaymentRequestOverviewData: IClosingPaymentRequestOverview =
  {
    totalGrantFunding: 1300000,
    totalGrantPaidToDate: 1300000,
    totalUnClaimedGrant: 1300000,
    projectDurationInMonths: 13,
    paymentMonth: 'August',
    recommendedAmount: 100000,
    additionalRequestedAmountTitle: 'More money',
    additionalRequestedAmount: 25000,
    requestedAmount: 150000,
    scheduledAmount: 125000,
    operationsReasonForCostChange:
      'Consequat duis eiusmod elit do nisi sunt elit consequat consectetur id ipsum amet. Sint anim consectetur aliqua laboris cupidatat nisi commodo exercitation veniam officia excepteur ex.',
    paymentRecommendation: EPaymentRecommendationStatus['Rejected'],
    paymentRecommendationBy: 'John Doe',
    paymentRecommendationDate: '2022-08-20',
    paymentRecommendationReason: 'Rejected Reason',
    costReports: [
      {
        id: '1',
        name: 'Cost Report 1',
        extension: 'pdf',
      },
      {
        id: '2',
        name: 'Cost Report 1',
        extension: 'pdf',
      },
    ],
    additionalRecommendedAmountTitle: 'Need more money',
    additionalRecommendedAmount: 10000,
    applicantReasonForCostChange:
      'Qui cupidatat cillum eu ex qui ex commodo labore ad incididunt. Et pariatur nostrud veniam ad sunt voluptate qui enim aute. Nisi ipsum deserunt sunt culpa. Amet consequat mollit aliquip est eu laboris aute anim tempor do qui amet commodo ullamco.',
    projectedCompletionDate: '2022-08-20',
    fraewRiskAssessment: ERiskStatus.MediumTolerable,
    submittedDate: '2023-11-09',
  };

export const applicationPaymentRequestCostsData: IPaymentRequestCosts = {
  id: getGuid(),
  grantPaidToDate: [
    {
      itemName: 'PTFS Payment',
      amount: 50000,
      paid: true,
    },
    {
      itemName: 'July 2023',
      amount: 50000,
      paid: true,
    },
  ],
  rejectedPayments: [
    {
      itemName: 'August 2023',
      amount: 50000,
      status: ERejectedPaymentStatus.Missed,
    },
    {
      itemName: 'September 2023',
      amount: 50000,
      status: ERejectedPaymentStatus.Rejected,
    },
  ],
  currentPayments: [
    {
      id: '1',
      itemName: 'August 2023',
      scheduledCost: 125000,
      submittedCost: 125000,
      confirmedCost: 125000,
      isDirty: false,
    },
  ],
  monthlyCosts: [
    {
      id: '1',
      itemName: 'September 2023',
      scheduledCost: 100000,
      submittedCost: 110000,
      confirmedCost: 110000,
      isDirty: false,
    },
    {
      id: '2',
      itemName: 'October 2023',
      scheduledCost: 75000,
      submittedCost: 75000,
      confirmedCost: 75000,
      isDirty: false,
    },
    {
      id: '3',
      itemName: 'November 2023',
      scheduledCost: 75000,
      submittedCost: 75000,
      confirmedCost: 75000,
      isDirty: false,
    },
    {
      id: '4',
      itemName: 'December 2023',
      scheduledCost: 62500,
      submittedCost: 62500,
      confirmedCost: 62500,
      isDirty: false,
    },
    {
      id: '5',
      itemName: 'January 2024',
      scheduledCost: 82000,
      submittedCost: 82000,
      confirmedCost: 82000,
      isDirty: false,
    },
    {
      id: '6',
      itemName: 'February 2024',
      scheduledCost: 75000,
      submittedCost: 75000,
      confirmedCost: 75000,
      isDirty: false,
    },
    {
      id: '7',
      itemName: 'March 2024',
      scheduledCost: 75000,
      submittedCost: 75000,
      confirmedCost: 75000,
      isDirty: false,
    },
    {
      id: '8',
      itemName: 'April 2024',
      scheduledCost: 75000,
      submittedCost: 75000,
      confirmedCost: 75000,
      isDirty: false,
    },
    {
      id: '9',
      itemName: 'May 2024',
      scheduledCost: 62500,
      submittedCost: 62500,
      confirmedCost: 5500,
      isDirty: false,
    },
    {
      id: '10',
      itemName: 'June 2024',
      scheduledCost: 62500,
      submittedCost: 62500,
      confirmedCost: 62500,
      isDirty: false,
    },
    {
      id: '11',
      itemName: 'July 2024',
      scheduledCost: 63500,
      submittedCost: 63500,
      confirmedCost: 17000,
      isDirty: false,
    },
  ],
  finalPayment: {
    id: undefined,
    itemName: '5% of overall project costs to be paid on completion',
    scheduledCost: 50000,
    submittedCost: 50000,
    confirmedCost: 50000,
  },
  reasonForCostChange: 'Reason for change is.....',
};

export const applicationPaymentRequestTeamData: IPaymentRequestProjectTeam[] = [
  {
    id: '123',
    companyName: 'Company 1',
    companyRegistration: '123456',
    name: 'Bill House',
    role: 'Project Lead',
    teamRole: ETeamRole.LeadContractor,
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    hasChasCertification: true,
    isGrantCertifyingOfficer: true,
    isNew: false,
  },
  {
    id: '1234',
    companyName: 'Company 3',
    companyRegistration: '12356',
    name: 'Bill House',
    role: 'Project Lead',
    teamRole: ETeamRole.LeadDesigner,
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    isGrantCertifyingOfficer: false,
    hasChasCertification: false,
    isNew: true,
  },
  {
    id: '12345',
    companyName: 'Company 2',
    companyRegistration: '123456',
    name: 'Bill House',
    role: 'Project Lead',
    teamRole: ETeamRole.ProjectManager,
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    isGrantCertifyingOfficer: false,
    hasChasCertification: false,
    isNew: false,
  },
];

export const applicationPaymentRequestChecksDetailData: IPaymentRequestChecksDetails =
  {
    totalGrantFunding: 13000000,
    paymentMonth: 'August',
    recommendedAmount: 125000,
    additionalRecommendedAmountTitle: 'Need more money',
    additionalRecommendedAmount: 250000,
    requestedAmount: 150000,
    additionalRequestedAmountTitle: 'Additional Funding',
    additionalRequestedAmount: 250000,
    scheduledAmount: 100000,
    buildingName: '520 Liberty Road',
  };

export const applicationClosingPaymentRequestChecksDetailData: IClosingPaymentRequestChecksDetails =
  {
    totalGrantFunding: 13000000,
    paymentMonth: 'August',
    recommendedAmount: 125000,
    additionalRecommendedAmountTitle: 'Need more money',
    additionalRecommendedAmount: 250000,
    requestedAmount: 150000,
    additionalRequestedAmountTitle: 'Additional Funding',
    additionalRequestedAmount: 250000,
    scheduledAmount: 100000,
    buildingName: '520 Liberty Road',
    projectedCompletionDate: '2022-08-20',
    fraewRiskAssessment: ERiskStatus.MediumTolerable,
  };

export const applicationPaymentRequestChecksData: IPaymentRequestChecks = {
  isPaymentRequestCostsConfirmed: true,
  basicChecksCompleted: false,
  basicChecksCompletedReason: null,
  correctBuildingOnCostReport: true,
  correctBuildingOnCostReportReason: 'Building matched',
  signedOffByGrantCertifyingOfficer: false,
  signedOffByGrantCertifyingOfficerReason: null,
  costReportInFormatSpecified: false,
  costReportInFormatSpecifiedReason: null,
  applicationId: '911',
  paymentRequestId: '111',
  paymentAmount: 9999,
};

export const applicationClosingPaymentRequestChecksData: IClosingPaymentRequestChecks =
  {
    isPaymentRequestCostsConfirmed: true,
    basicChecksCompleted: false,
    basicChecksCompletedReason: null,
    sufficientEvidenceToCoverTotalGrantClaimed: false,
    sufficientEvidenceToCoverTotalGrantClaimedReason: null,
    worksIdentifiedCompleted: false,
    worksIdentifiedCompletedReason: null,
    riskLevelMitigatedToTolerableLevel: false,
    riskLevelMitigatedToTolerableLevelReason: null,
    practicalCompletionCertificationProvidedAndApproved: false,
    practicalCompletionCertificationProvidedAndApprovedReason: null,
    uploadedCertification: false,
    uploadedCertificationReason: null,
    evidenceProvidedToLeaseHoldersAndSurvey: false,
    evidenceProvidedToLeaseHoldersAndSurveyReason: null,
    recommendPaymentApproval: false,
    recommendPaymentApprovalReason: null,
    applicationId: '911',
    paymentRequestId: '111',
    paymentAmount: 9999,
  };

export const applicationPaymentRequestReferralsData: Array<IPaymentRequestReferral> =
  [
    {
      id: getGuid(),
      reason: 'Payment Request - Referral 1',
      status: EReferralStatus.InProgress,
      completeReason: null,
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Payment Request - Referral 2',
      status: EReferralStatus.Complete,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Payment Request - Referral 3',
      status: EReferralStatus.Overdue,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Payment Request - Referral 4',
      status: EReferralStatus.Overdue,
      completeReason: null,
      referredByUserId: 'a90ca444-db7b-4a4b-a468-3dce205b888b',
    },
  ];

export const applicationPaymentRequestVariationData: IPaymentRequestVariations =
  {
    projectDatesChanged: true,
    unsafeCladdingBeenRemoved: true,
    monthOfUnsafeCladdingRemoval: '2023-11-01T00:00:00Z',
    costsChanged: true,
    thirdPartyContributionsChanged: true,
    originalStartDate: '2023-09-15',
    currentStartDate: '2024-01-15',
    originalEndDate: '2024-12-10',
    currentEndDate: '2025-01-20',
  };
