import { useState } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from 'state';
import { createNewTask } from 'state/slices/task';
import { ECmsTaskGroup } from 'types/tasks/CmsTaskTypes';

export const AddTaskIcon = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const isMenuOpen = Boolean(menuAnchorEl);

  const dispatch = useAppDispatch();

  const handleCreateNewTaskClick = (taskGroup: ECmsTaskGroup) => {
    handleCloseMenu();
    dispatch(createNewTask({ taskGroup: taskGroup, referenceId: null }));
  };

  return (
    <Box>
      <IconButton size="small" color="inherit" onClick={handleMenuOpen} aria-label="Add task menu" name="Add task">
        <FontAwesomeIcon icon={faPlusCircle} />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleCreateNewTaskClick(ECmsTaskGroup.Application)} aria-label="Add application task">Add Application Task</MenuItem>
        <MenuItem onClick={() => handleCreateNewTaskClick(ECmsTaskGroup.Pullin)} aria-label="Add pull in task">Add Pull In Task</MenuItem>
      </Menu>
    </Box>
  );
};
