import { useEffect } from 'react';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  useEditFraewInternalWorksMutation,
  useGetFraewInternalWorksQuery,
} from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IFraewInternalWorks } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IFraewInternalWorks) =>
  nameof<IFraewInternalWorks>(fieldName);

interface IEditFraewInternalWorksDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditFraewInternalWorksDrawer({
  onClose,
  onSuccess,
}: IEditFraewInternalWorksDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit FRAEW Internal Works</Typography>
        </DialogTitle>
        <EditFraewInternalWorksForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditFraewInternalWorksFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewInternalWorksForm = ({
  onClose,
  onSuccess,
}: IEditFraewInternalWorksFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: internalWorks, isLoading: formLoading } =
    useGetFraewInternalWorksQuery(applicationId);
  const [editFraewInternalWorks, editFraewInternalWorksResult] =
    useEditFraewInternalWorksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewInternalWorks>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, reset, control, watch } = form;

  const {
    fields: worksFields,
    append: appendInternalWorks,
    remove: removeInternalWorks,
  } = useFieldArray({
    control,
    name: 'works',
    keyName: 'id',
  });

  useEffect(() => {
    const initialFormData = { ...internalWorks, changeReason: '' };
    reset(initialFormData);
  }, [internalWorks, reset]);

  const hasWorks = watch('hasWorks');

  const onSubmit = async (formData: IFraewInternalWorks) => {
    try {
      formData.applicationId = applicationId;
      
      if (hasWorks === false){
        formData.works = [];
      }

      await editFraewInternalWorks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`FRAEW internal works updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW internal works`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={event => {
            event.stopPropagation();
            handleSubmit(onSubmit)(event);
          }}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} mb={2}>
                <FieldLabel label="Are there any elements of the interior which you are recommending are subject to remediation/mitigation works?" />
                <Controller
                  control={control}
                  name="hasWorks"
                  render={({
                    fieldState,
                    field: { onChange, ...fieldProps },
                  }) => (
                    <>
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                          }}
                          exclusive
                        >
                          <ToggleButton value={true}>Yes</ToggleButton>
                          <ToggleButton value={false}>No</ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                      <FieldErrorOrNull
                        fieldState={fieldState}
                        sx={{ ml: '14px' }}
                      />
                    </>
                  )}
                />
              </Grid>
              {hasWorks && (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => {
                          appendInternalWorks({ id: '', description: '' });
                        }}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {worksFields?.map((workField, index) => (
                      <Grid key={workField.id} container>
                        <Grid item xs={11}>
                          <Controller
                            name={`works[${index}].description`}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                fullWidth
                                error={!!fieldState.error}
                                required
                                label="Internal Works"
                                helperText={fieldState.error?.message}
                                multiline
                                autoComplete="off"
                                inputProps={{ maxLength: 1000 }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {
                              removeInternalWorks(index);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editFraewInternalWorksResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
