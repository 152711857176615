import {
  ELiveProjectItemStatus,
  ELiveProjectItemType,
  IGetLiveProjectListResponse,
  ILiveProjectOverview,
} from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { getGuid } from 'util/AppUtils';

export const liveProjectOverviewData: ILiveProjectOverview = {
  startDate: '2023-04-01',
  endDate: '2024-07-31',
  currentSpend: 123456,
  eligibleSpend: 999999,
  worksPackage: {
    id: getGuid(),
    version: 1,
    lastModifiedBy: 'John Haidt',
    lastModifiedDate: '2023/08/03 12:01:00',
  },
  milestones: {
    startOnSite: new Date('2024/01/31'),
    practicalCompletion: new Date('2024/12/31')
  },
};

export const liveProjectListViewData: IGetLiveProjectListResponse = {
  results: [
    {
      id: getGuid(),
      title: 'Variation',
      subTitle: 'Variation 1',
      status: ELiveProjectItemStatus.ToDo,
      type: ELiveProjectItemType.Variation,
      dueDate: '2023-09-27',
      startDate: '2023-09-05',
      lastModifiedBy: 'John Haidt',
    },
    {
      id: getGuid(),
      title: 'Schedule of works',
      subTitle: 'Version 1.0',
      status: ELiveProjectItemStatus.ToDo,
      type: ELiveProjectItemType.ScheduleOfWorks,
      dueDate: '2023-09-27',
      submittedDate: '2023-09-28',
      completedDate: '2023-09-30',
    },
    {
      id: getGuid(),
      title: 'Payment',
      subTitle: 'August Payment Request',
      status: ELiveProjectItemStatus.ToDo,
      type: ELiveProjectItemType.PaymentRequest,
      startDate: '2023-08-01',
      dueDate: '2023-08-31',
    },
    {
      id: getGuid(),
      title: 'Payment',
      subTitle: 'July Payment Request',
      status: ELiveProjectItemStatus.ToDo,
      type: ELiveProjectItemType.PaymentRequest,
      startDate: '2023-07-01',
      dueDate: '2023-07-31',
    },
    {
      id: getGuid(),
      title: 'Payment',
      subTitle: 'Closing Payment Request',
      status: ELiveProjectItemStatus.ToDo,
      type: ELiveProjectItemType.ClosingPaymentRequest,
      startDate: '2023-07-01',
      dueDate: '2023-07-31',
    },
  ],
};
