export enum EQuestionType {
  UkBankAccountDetails = 1,
  PtfsAmount = 2,
  VatNumber = 3,
  WhoseBankAccount = 4,
  ResponsibleEntityCompanyDetails = 5,
  ResponsibleEntityAddress = 6,
  RepresentativeCompanyDetails = 7,
  RepresentativeAddress = 8,
  ResponsibleEntityContactDetails = 9,
  RepresentativeContactDetails = 10,
  BuildingHeight = 11,
  NumberOfStoreys = 12,
  TotalCladdingArea = 13,
  LifeSafetyRiskAssessment = 14,
  AssessmentType = 15,
  RecommendCladding = 16,
  BuildingAddress = 17,
  FireRiskSummary = 18,
  FireRiskReport = 19,
  FreeholderAddress = 20,
  FreeholderCompanyDetails = 21,
  FreeholderContactDetails = 22,
  DeveloperAddress = 23,
  BankAccountVerificationContactDetails = 24,
  PtfsRequired = 25,
  BsrRegistrationCode = 26,
  BuildingName = 27,
  FraewSurveyCompany = 28,
  FraewSurveyDate = 29,
  FraewInstructionDate = 30,
  FraewReportAuthor = 31,
  FraewPeerReviewer = 32,
  FraewCost = 33,
  ProjectMilestones = 34,
  FraewInterimMeasures = 35,
  FraewCurrentEvacuationStrategy = 36,
  FraewNumberOfStairwells = 37,
  FraewExternalManagementPolicies = 38,
  FraewFireRescueServiceAccessRestrictions = 39,
  AlternateFundingRoutes = 40,
  HasBsrCode = 41,
  OriginalDeveloper = 42,
  NumberOfResidentialUnits = 43,
  AnyNonResidentialUnits = 44,
  PartOfDevelopment = 45,
  BuildingNumberOfStoreys = 46,
  RiskSafetyMitigation = 47,
  CostEffectiveCourseOfAction = 48,
  OtherOptionsConsidered = 49,
  CaveatsOrLimitations = 50,
  RecommendedInterimMeasures = 51,
  LeaseholderEngagement = 52,
  FraewInternalWorks = 53,
  FraewExternalWorks = 54,
  FraewCladdingSystemTypes = 55,
  EvidenceForAuthorisation = 56,
  EvidenceForChiefExecutive = 57,
  EvidenceForS151Officer = 58,
}
