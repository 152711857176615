import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  useEditScheduleOfWorksCostsMutation,
  useGetScheduleOfWorksCostsQuery,
  useGetScheduleOfWorksOverviewQuery,
} from 'api/application/scheduleOfWorksApi';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import {
  IScheduleOfWorksCostItem,
  IScheduleOfWorksCosts,
  IScheduleOfWorksRequest,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { ScheduleOfWorksKpiSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/common/ScheduleOfWorksKpiSection';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { CostProfileFinalPaymentSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileFinalPaymentSection';
import { CostProfileFooterSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileFooterSection';
import { CostProfileGrantPaidToDateSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileGrantPaidToDateSection';
import { CostProfileHeaderSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileHeaderSection';
import { CostProfileInitialPaymentsSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileInitialPaymentsSection';
import { CostProfileMonthlyCostsSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileMonthlyCostsSection';
import { CostProfileUpfrontPercentageSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileUpfrontPercentageSection';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { setServerSideFormErrors } from 'util/formUtils';

export const upfrontPercentageFieldItemId =
  '00000000-0000-0000-0000-000000000000';

export const CostProfileSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request =
    !applicationId || !selectedItem
      ? skipToken
      : { applicationId, scheduleOfWorksId: selectedItem?.id };

  const query = useGetScheduleOfWorksCostsQuery(request);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
    };

    return (
      <>
        <ScheduleOfWorksKpiSection />
        <ColumnContainer
          sx={{
            padding: 0,
            borderRadius: 2,
            mt: 2,
            gap: 1,
            mb: 2,
          }}
        >
          <CostProfileGrantPaidToDateSection
            grantPaidToDate={data?.grantPaidToDate}
          />
        </ColumnContainer>
        <ScheduleOfWorksCostsFormMain initialData={initialData} />
      </>
    );
  }

  return null;
};

const ScheduleOfWorksCostsFormMain = ({
  initialData,
}: {
  initialData: IScheduleOfWorksCosts;
}) => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { readOnly } = useScheduleOfWorksContext();

  const request: IScheduleOfWorksRequest = {
    applicationId: applicationId,
    scheduleOfWorksId: selectedItem?.id ?? '',
  };

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ScheduleOfWorks,
  });

  const { data } = useGetScheduleOfWorksOverviewQuery(request);

  const [editScheduleOfWorksCosts, editScheduleOfWorksCostsStatus] =
    useEditScheduleOfWorksCostsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IScheduleOfWorksCosts>({
    defaultValues: initialData,
  });

  const paidPaymentsTotals =
    initialData.grantPaidToDate
      ?.filter(item => item.amount !== undefined)
      .reduce((sum, current) => sum + current.amount, 0) ?? 0;

  const submittedInitialPaymentsTotals = initialData.initialPayments
    ?.filter(item => item.submittedCost !== undefined)
    .reduce((sum, current) => sum + current.submittedCost, 0);

  const submittedMonthlyCostsTotals = initialData.monthlyCosts
    ?.filter(item => item.submittedCost !== undefined)
    .reduce((sum, current) => sum + current.submittedCost, 0);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = form;

  const {
    fields: initialPaymentsFields,
    append: appendInitialPayment,
    remove: removeInitialPayment,
    update: updateInitialPayment,
  } = useFieldArray({
    control,
    name: 'initialPayments',
    keyName: 'key',
  });

  const monthlyCostsFields = useFieldArray({
    control,
    name: 'monthlyCosts',
  }).fields;

  const watchMonthlyCosts = useWatch({
    name: 'monthlyCosts',
    control,
  });
  const watchInitialPayments = useWatch({
    name: 'initialPayments',
    control,
  });
  const watchFinalPaymentConfirmedCost = useWatch({
    name: 'finalPayment.confirmedCost',
    control,
  });

  const totalConfiremdInitialPaymentsCosts =
    watchInitialPayments
      ?.filter(
        item =>
          item.confirmedCost !== undefined &&
          item.confirmedCost.toString() !== ''
      )
      .reduce(
        (sum, current) =>
          sum + parseFloat(current.confirmedCost?.toString() ?? '0'),
        0
      ) ?? 0;

  const totalConfiremdMonthlyCosts =
    watchMonthlyCosts
      ?.filter(
        item =>
          item.confirmedCost !== undefined &&
          item.confirmedCost.toString() !== ''
      )
      .reduce(
        (sum, current) =>
          sum + parseFloat(current.confirmedCost?.toString() ?? '0'),
        0
      ) ?? 0;

  const totalCosts =
    totalConfiremdMonthlyCosts +
    totalConfiremdInitialPaymentsCosts +
    parseFloat(watchFinalPaymentConfirmedCost?.toString() ?? '0');

  const onUpfrontPercentageChange = (
    toggleState: boolean,
    percentage: number
  ) => {
    const upfrontPercentageCostIndex = initialPaymentsFields.findIndex(
      item => item.id === upfrontPercentageFieldItemId
    );
    if (toggleState) {
      const upfrontPayment = data
        ? Math.round(
            Number((data?.totalGrantFunding * (percentage / 100)).toFixed(2))
          )
        : 0;

      const upfrontPercentageCost: IScheduleOfWorksCostItem = {
        id: upfrontPercentageFieldItemId,
        itemName: 'Additional ' + percentage + '% of project costs',
        submittedCost: upfrontPayment,
        confirmedCost: upfrontPayment,
      };

      upfrontPercentageCostIndex > -1
        ? updateInitialPayment(
            upfrontPercentageCostIndex,
            upfrontPercentageCost
          )
        : appendInitialPayment(upfrontPercentageCost);
      return;
    } else if (upfrontPercentageCostIndex > -1) {
      removeInitialPayment(upfrontPercentageCostIndex);
    }
  };

  const onSubmit = async (formData: IScheduleOfWorksCosts) => {
    try {
      formData.id = applicationId;
      formData.scheduleOfWorksId = selectedItem?.id;
      await editScheduleOfWorksCosts(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Schedule Of Works Costs updated successfully`);
          reset({}, { keepValues: true });
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Schedule Of Works costs`);
    }
  };
  return (
    <>
      {initialData.showUpfrontPercentage && (
        <CostProfileUpfrontPercentageSection
          onUpfrontPercentageChange={onUpfrontPercentageChange}
          control={control}
          upfrontPercentageChecked={!!initialData.upfrontPercentage}
          initialPaymentsFields={initialPaymentsFields}
          readOnly={readOnly || isOnHoldForThisType}
        />
      )}
      <CostProfileHeaderSection />
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Schedule Of Works Costs will be lost."
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editScheduleOfWorksCostsStatus.isError}
            error={editScheduleOfWorksCostsStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <ColumnContainer
            sx={{
              padding: 0,
              border: '1px solid lightgray',
              borderRadius: 2,
              mt: 1,
              gap: 1,
            }}
          >
            <CostProfileInitialPaymentsSection
              initialPaymentsFields={initialPaymentsFields}
              control={control}
              readOnly={readOnly || isOnHoldForThisType}
            />
          </ColumnContainer>
          <ColumnContainer
            sx={{
              padding: 0,
              border: '1px solid lightgray',
              borderRadius: 2,
              mt: 2,
              gap: 1,
            }}
          >
            <CostProfileMonthlyCostsSection
              monthlyCostsFields={monthlyCostsFields}
              control={control}
              readOnly={readOnly || isOnHoldForThisType}
            />
          </ColumnContainer>
          <ColumnContainer
            sx={{
              padding: 0,
              border: '1px solid lightgray',
              borderRadius: 2,
              mt: 2,
              gap: 1,
            }}
          >
            <CostProfileFinalPaymentSection
              finalPayment={initialData?.finalPayment}
              control={control}
              readOnly={readOnly || isOnHoldForThisType}
            />
          </ColumnContainer>
          <CostProfileFooterSection
            totalGrantFunding={data ? data?.totalGrantFunding : 0}
            totalSubmittedCosts={
              Number(submittedInitialPaymentsTotals) +
              Number(submittedMonthlyCostsTotals)
            }
            totalConfirmedCosts={totalCosts}
            totalPaidCosts={paidPaymentsTotals}
            readOnly={readOnly || isOnHoldForThisType}
          />
        </form>
      </FormProvider>
    </>
  );
};
