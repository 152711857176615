import { useCallback, useReducer } from 'react';
import { useGetCurrentUserQuery } from 'api/profile/profile';
import { useGetAdminTasksQuery } from 'api/task';
import { ESortDirection } from 'enums/ESortDirection';
import { ETeam } from 'enums/ETeam';
import {
  ECmsTaskStatus,
  ETaskListConfigType,
  IAdminTaskListFilters,
  IAdminTaskListItem,
  IAdminTaskListPaginatedQuery,
} from 'types/tasks/CmsTaskTypes';
import { nameof } from 'util/formUtils';

const getDateForDateRange = (dateString: string) =>
  dateString ? new Date(dateString) : null;

export const mapGridStateToFilterModalState = (
  gridState: IAdminTaskListPaginatedQuery
): IAdminTaskListFilters => {
  return {
    assigneeId: gridState.assigneeId,
    dateRange: [
      getDateForDateRange(gridState.startDate),
      getDateForDateRange(gridState.endDate),
    ],
    status: gridState.status,
    taskType: gridState.taskType,
  };
};

interface IAdminTaskListState {
  gridState: IAdminTaskListPaginatedQuery;
}

const useInitialState = (): IAdminTaskListState => {
  const { data } = useGetCurrentUserQuery();

  return {
    gridState: {
      skip: 0,
      take: 25,
      searchValue: null,
      sortBy: nameof<IAdminTaskListItem>('dueDate'),
      sortDirection: ESortDirection.Asc,
      assigneeId: [],
      status: [ECmsTaskStatus.InProgress, ECmsTaskStatus.NotStarted],
      taskType: [],
      startDate: '',
      endDate: '',
      team: data?.teamId
        ? [data.teamId]
        : [ETeam.DaviesOps, ETeam.HomesEnglandOperations],
    },
  };
};

type ActionType = {
  type: 'gridStateChanged';
  payload: Partial<IAdminTaskListPaginatedQuery>;
};

const reducer = (
  state: ReturnType<typeof useInitialState>,
  action: ActionType
): IAdminTaskListState => {
  switch (action.type) {
    case 'gridStateChanged':
      return {
        gridState: {
          ...state.gridState,
          ...action.payload,
        },
      };

    default:
      throw new Error('invalid action.type');
  }
};

export const useAdminTaskListGridState = () => {
  const initialState = useInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridStateChanged = useCallback(
    (newGridState: ActionType['payload']) => {
      dispatch({ type: 'gridStateChanged', payload: newGridState });
    },
    []
  );

  return { state, dispatch, gridStateChanged };
};

export const useGridQueryAndState = () => {
  const { state, gridStateChanged } = useAdminTaskListGridState();
  const query = useGetAdminTasksQuery({
    type: ETaskListConfigType.AdminTasks,
    query: state.gridState,
  });

  return { query, state, gridStateChanged };
};
