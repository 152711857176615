import { useRef, useState } from 'react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { EQuestionType } from 'enums/EQuestionType';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EApplicationDetailsQuestionMenuType } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsTypes';
import {
  ApplicationDetailsEditDialogs,
  IApplicationDetailsEditDialogs,
} from 'pages/applicationPage/content/applicationDetails/dialogs/ApplicationDetailsEditDialogs';
import { ViewQuestionAuditHistoryDialog } from 'pages/applicationPage/content/applicationDetails/dialogs/ViewQuestionAuditHistoryDialog';

interface IApplicationInfoPanelContextMenuProps {
  questionType: EQuestionType;
  currentValue?: string;
  readOnly?: boolean;
}

export function ApplicationInfoPanelContextMenu({
  questionType,
  currentValue,
  readOnly = false,
}: IApplicationInfoPanelContextMenuProps) {
  const theme = useTheme();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationGovDataEdit = doesUserHaveSinglePermission(
    'admin.application.govdata.edit'
  );

  const { closed } = useApplicationContext();

  const applicationDetailsEditDialogsRef =
    useRef<IApplicationDetailsEditDialogs>();

  const {
    isShowing: isViewQuestionAuditHistoryDialogShowing,
    showModal: showViewQuestionAuditHistoryDialog,
    hideModal: hideViewQuestionAuditHistoryDialog,
  } = useModalState();

  const [contextMenuAnchorEl, setContextMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleShowContextMenu = (anchorEl: HTMLElement | null) => {
    setContextMenuAnchorEl(anchorEl);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchorEl(null);
  };

  const handleContextMenuButtonClick = (
    menuType: EApplicationDetailsQuestionMenuType
  ) => {
    switch (menuType) {
      case EApplicationDetailsQuestionMenuType.EditItem:
        switch (questionType) {
          case EQuestionType.UkBankAccountDetails:
            applicationDetailsEditDialogsRef.current?.displayEditUkBankAccountDetailsDrawer();
            break;
          case EQuestionType.BankAccountVerificationContactDetails:
            applicationDetailsEditDialogsRef.current?.displayEditBankAccountVerificationContactDetailsDrawer();
            break;
          case EQuestionType.PtfsRequired:
            applicationDetailsEditDialogsRef.current?.displayEditPtfsRequiredDrawer();
            break;
          case EQuestionType.PtfsAmount:
            applicationDetailsEditDialogsRef.current?.displayEditPtfsAmountDrawer();
            break;
          case EQuestionType.VatNumber:
            applicationDetailsEditDialogsRef.current?.displayEditVatNumberDrawer();
            break;
          case EQuestionType.WhoseBankAccount:
            applicationDetailsEditDialogsRef.current?.displayEditWhoseBankAccountDrawer();
            break;
          case EQuestionType.BuildingAddress:
          case EQuestionType.DeveloperAddress:
            applicationDetailsEditDialogsRef.current?.displayEditAddressDrawer();
            break;
          case EQuestionType.BuildingHeight:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingHeightDrawer();
            break;
          case EQuestionType.TotalCladdingArea:
            applicationDetailsEditDialogsRef.current?.displayEditTotalCladdingAreaDrawer();
            break;
          case EQuestionType.FireRiskSummary:
          case EQuestionType.FireRiskReport:
            applicationDetailsEditDialogsRef.current?.displayEditApplicationFileDrawer();
            break;
          case EQuestionType.BsrRegistrationCode:
            applicationDetailsEditDialogsRef.current?.displayEditBsrRegistrationCodeDrawer();
            break;
          case EQuestionType.BuildingName:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNameDrawer();
            break;
          case EQuestionType.FraewSurveyCompany:
            applicationDetailsEditDialogsRef.current?.displayEditFraewSurveyCompanyDrawer();
            break;
          case EQuestionType.FraewSurveyDate:
            applicationDetailsEditDialogsRef.current?.displayEditFraewSurveyDateDrawer();
            break;
          case EQuestionType.FraewInstructionDate:
            applicationDetailsEditDialogsRef.current?.displayEditFraewInstructionDateDrawer();
            break;
          case EQuestionType.FraewReportAuthor:
            applicationDetailsEditDialogsRef.current?.displayEditFraewReportAuthorDrawer();
            break;
          case EQuestionType.FraewPeerReviewer:
            applicationDetailsEditDialogsRef.current?.displayEditFraewPeerReviewerDrawer();
            break;
          case EQuestionType.FraewCost:
            applicationDetailsEditDialogsRef.current?.displayEditFraewCostDrawer();
            break;
          case EQuestionType.ProjectMilestones:
            applicationDetailsEditDialogsRef.current?.displayEditProjectMilestonesDrawer();
            break;
          case EQuestionType.FraewInterimMeasures:
            applicationDetailsEditDialogsRef.current?.displayEditFraewInterimMeasuresDrawer();
            break;
          case EQuestionType.FraewCurrentEvacuationStrategy:
            applicationDetailsEditDialogsRef.current?.displayEditFraewCurrentEvacuationStrategyDrawer();
            break;
          case EQuestionType.FraewNumberOfStairwells:
            applicationDetailsEditDialogsRef.current?.displayEditFraewNumberOfStairwellsDrawer();
            break;
          case EQuestionType.FraewExternalManagementPolicies:
            applicationDetailsEditDialogsRef.current?.displayEditFraewExternalManagementPoliciesDrawer();
            break;
          case EQuestionType.FraewFireRescueServiceAccessRestrictions:
            applicationDetailsEditDialogsRef.current?.displayEditFraewFireRescueServiceAccessRestrictionsDrawer();
            break;
          case EQuestionType.HasBsrCode:
            applicationDetailsEditDialogsRef.current?.displayEditBsrCodeDrawer();
            break;
          case EQuestionType.OriginalDeveloper:
            applicationDetailsEditDialogsRef.current?.displayEditOriginalDeveloperDrawer();
            break;
          case EQuestionType.NumberOfResidentialUnits:
            applicationDetailsEditDialogsRef.current?.displayEditNumberOfResidentialUnitsDrawer();
            break;
          case EQuestionType.AnyNonResidentialUnits:
            applicationDetailsEditDialogsRef.current?.displayEditAnyNonResidentialUnitsDrawer();
            break;
          case EQuestionType.PartOfDevelopment:
            applicationDetailsEditDialogsRef.current?.displayEditPartOfDevelopmentDrawer();
            break;
          case EQuestionType.BuildingNumberOfStoreys:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.AlternateFundingRoutes:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.RiskSafetyMitigation:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.CostEffectiveCourseOfAction:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.OtherOptionsConsidered:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.CaveatsOrLimitations:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.RecommendedInterimMeasures:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.LeaseholderEngagement:
            applicationDetailsEditDialogsRef.current?.displayEditBuildingNumberOfStoreysDrawer();
            break;
          case EQuestionType.FraewInternalWorks:
            applicationDetailsEditDialogsRef.current?.displayFraewInternalWorksDrawer();
            break;
          case EQuestionType.FraewExternalWorks:
            applicationDetailsEditDialogsRef.current?.displayFraewExternalWorksDrawer();
            break;
          case EQuestionType.FraewCladdingSystemTypes:
            applicationDetailsEditDialogsRef.current?.displayFraewCladdingSystemTypesDrawer();
            break;
          case EQuestionType.EvidenceForAuthorisation:
            applicationDetailsEditDialogsRef.current?.displayEditEvidenceForAuthoristionDrawer();
            break;
          case EQuestionType.EvidenceForChiefExecutive:
            applicationDetailsEditDialogsRef.current?.displayEditEvidenceForChiefExecutiveDrawer();
            break;
          case EQuestionType.EvidenceForS151Officer:
            applicationDetailsEditDialogsRef.current?.displayEditEvidenceForS151OfficerDrawer();
            break;
          default:
            throw new Error('EQuestionType not supported');
        }
        break;
      case EApplicationDetailsQuestionMenuType.ViewHistory:
        showViewQuestionAuditHistoryDialog();
        break;
      default:
        throw new Error('ApplicationDetailsQuestionMenuType not supported');
    }

    setContextMenuAnchorEl(null);
  };

  return (
    <>
      <Box flexGrow={1}>
        <IconButton
          onClick={e => {
            handleShowContextMenu(e.currentTarget);
          }}
          sx={{
            borderRadius: 0,
            background: theme.palette.grey[100],
            p: 1,
            m: 0,
          }}
          aria-label="Details menu"
          name="Details menu"
        >
          <FontAwesomeIcon icon={faEllipsis} size="xs" fixedWidth />
        </IconButton>
      </Box>
      {Boolean(contextMenuAnchorEl) ? (
        <Menu
          anchorEl={contextMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(contextMenuAnchorEl)}
          onClose={() => handleCloseContextMenu()}
        >
          {hasAdminApplicationGovDataEdit && !readOnly && !closed && (
            <MenuItem
              onClick={() => {
                handleCloseContextMenu();
                handleContextMenuButtonClick(
                  EApplicationDetailsQuestionMenuType.EditItem
                );
              }}
              aria-label="Edit"
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleCloseContextMenu();
              handleContextMenuButtonClick(
                EApplicationDetailsQuestionMenuType.ViewHistory
              );
            }}
            aria-label="Edit"
          >
            History
          </MenuItem>
        </Menu>
      ) : null}
      <ApplicationDetailsEditDialogs
        ref={applicationDetailsEditDialogsRef}
        currentValue={currentValue}
      />
      {isViewQuestionAuditHistoryDialogShowing && (
        <ViewQuestionAuditHistoryDialog
          questionType={questionType}
          onClose={hideViewQuestionAuditHistoryDialog}
        />
      )}
    </>
  );
}
