import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditVeriphyChecksMutation,
  useGetVeriphyChecksQuery,
  useRunVeriphyReportMutation,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EVeriphyStatus, EVeriphyStatusLanguage } from 'enums/EVeriphyStatus';
import { useLocalSnackbar } from 'hooks';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { VeriphyStatusBanner } from 'pages/applicationPage/content/applicationEligibility/components/Veriphy/VeriphyStatusBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { IApplicationVeriphyChecks } from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationVeriphyChecks) =>
  nameof<IApplicationVeriphyChecks>(fieldName);

export const VeriphyChecksForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetVeriphyChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
      referrals: data.referrals?.map(r => ({ ...r })) ?? [],
    };

    return <VeriphyChecksFormMain initialData={initialData} />;
  }

  return null;
};

const VeriphyChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationVeriphyChecks;
}) => {
  const { applicationId, readOnly } = useApplicationEligibilityContext();

  const [runVeriphyReport] = useRunVeriphyReportMutation();

  const [editVeriphyChecks, editVeriphyChecksStatus] =
    useEditVeriphyChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationVeriphyChecks>({
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const handleRunVeriphyReport = async () => {
    try {
      await runVeriphyReport(applicationId)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar('A Veriphy Report Request Has Been Queued.');
          form.setValue(`isVeriphyRequired`, true);
        })
        .catch(error => {
          createErrorSnackbar(
            `An unexpected error occured while requesting a veriphy report: ${error.data.generalError.errorMessage}`
          );
        });
    } catch (err) {
      createErrorSnackbar(`Failed to queue Veriphy report`);
    }
  };

  const onSubmit = async (formData: IApplicationVeriphyChecks) => {
    try {
      formData.id = applicationId;
      await editVeriphyChecks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Veriphy Checks updated successfully`);
          reset({}, { keepValues: true });
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Veriphy checks`);
    }
  };

  return (
    <>
      {isOnHoldForThisType && (
        <OnHoldBanner holdType={EOnHoldType.Eligibility} />
      )}

      <Box
        sx={{
          padding: 2,
          borderRadius: 1,
          bgcolor: 'grey.100',
        }}
      >
        <Button
          variant="contained"
          onClick={handleRunVeriphyReport}
          disabled={
            initialData.veriphyStatus === EVeriphyStatus.InProgress ||
            isOnHoldForThisType
          }
        >
          Run Veriphy Report
        </Button>

        <VeriphyStatusBanner
          title={EVeriphyStatusLanguage[initialData.veriphyStatus]}
        />
      </Box>

      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Veriphy Checks will be lost."
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editVeriphyChecksStatus.isError}
            error={editVeriphyChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box p={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Veriphy Required?
                    </Typography>
                    <Controller
                      defaultValue={''}
                      name={getName('isVeriphyRequired')}
                      render={({
                        fieldState,
                        field: { onChange, ...fieldProps },
                      }) => (
                        <FormControl>
                          <ToggleButtonGroup
                            {...fieldProps}
                            onChange={(_, val) => {
                              if (val !== null) {
                                onChange(val);
                              }
                            }}
                            exclusive
                            disabled={readOnly || isOnHoldForThisType}
                          >
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          {fieldState.error?.message ? (
                            <FormHelperText error>
                              {fieldState.error?.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                  bgcolor="grey.100"
                >
                  <ReferApplicationButton
                    sectionType={EEligibilitySection.Veriphy}
                    disabled={isOnHoldForThisType}
                  />
                  <OnOffHold holdType={EOnHoldType.Eligibility} />
                  <Box gap={2} display="flex">
                    <Button
                      variant="outlined"
                      disabled={isOnHoldForThisType}
                      onClick={() => {
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                    >
                      Confirm Details
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>
    </>
  );
};
