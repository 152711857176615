import { useCallback, useMemo } from 'react';
import { useGetUserListQuery } from 'api/administration';
import {
  useGetTaskCompletionOptionsQuery,
  useGetTaskTypesQuery,
} from 'api/task';
import { ECmsTaskGroup } from 'types/tasks/CmsTaskTypes';
import { sortByValueAsc } from 'util/AppUtils';

export const useTaskTypeUtils = (
  selectedTypeId: number | '',
  selectedSubTypeId: number | '',
  selectedTopicId: number | ''
) => {
  const taskTypesQuery = useGetTaskTypesQuery();

  const taskTypeData = useMemo(
    () => taskTypesQuery.data?.taskTypes ?? [],
    [taskTypesQuery.data?.taskTypes]
  );

  const taskTypeOptions = taskTypeData.map(getOptions);
  const getTaskTypeName = (taskTypeId: number) => {
    return (
      taskTypeData.find(taskTypeOption => taskTypeOption.id === taskTypeId)
        ?.name ?? ''
    );
  };

  const taskSubtypeOptions = useMemo(
    () =>
      taskTypeData
        .find(taskType => taskType.id === selectedTypeId)
        ?.subtypes.map(getOptions) ?? [],
    [selectedTypeId, taskTypeData]
  );

  const getSubTaskTypeName = (selectedSubtypeId: number) => {
    return taskSubtypeOptions.find(
      subTypeOption => subTypeOption.value === selectedSubtypeId.toString()
    )?.label;
  };

  const isSubTaskTypeDueDateEditable = (selectedTypeId: number, selectedSubTypeId: number) => {
    return taskTypeData
    .find(taskType => taskType.id === selectedTypeId)
    ?.subtypes.find(subType => subType.id === selectedSubTypeId)?.isDueDateEditable ?? false
  };

  const topics = useMemo(
    () =>
      taskTypeData
        .find(taskType => taskType.id === selectedTypeId)
        ?.subtypes.find(subType => subType.id === selectedSubTypeId)?.topics ??
      [],
    [selectedSubTypeId, selectedTypeId, taskTypeData]
  );

  const getTopicSla = useCallback(
    (topicId: number) =>
      topics.find(topic => topic.id === topicId)?.slaDays ?? 0,
    [topics]
  );

  const topicSla = useMemo(
    () =>
      typeof selectedTopicId === 'number' ? getTopicSla(selectedTopicId) : 0,
    [getTopicSla, selectedTopicId]
  );

  const taskTopicOptions = useMemo(
    () => topics.map(getOptions) ?? [],
    [topics]
  );

  return {
    taskTypesQuery,
    taskTypeData,
    getTaskTypeName,
    getSubTaskTypeName,
    taskTypeOptions,
    taskSubtypeOptions,
    taskTopicOptions,
    topicSla,
    getTopicSla,
    isSubTaskTypeDueDateEditable,
  };
};

export const useTaskCompletionOptions = () => {
  const query = useGetTaskCompletionOptionsQuery();
  const data = query.data?.outcomes ?? [];
  const taskCompletionOptions = data.map(getOptions);
  return { taskCompletionOptions };
};

export const getOptions = (item: {
  name: string;
  id: number | string;
  taskGroup?: ECmsTaskGroup;
  active?: boolean;
}) => ({
  label: item.name,
  value: item.id.toString(),
  taskGroup: item.taskGroup,
  ...(item.active !== undefined ? { active: item.active } : {}),
});

export const useAssigneeOptions = () => {
  const userQuery = useGetUserListQuery();
  const assigneeOptions =
    userQuery.data
      ?.map(item => ({
        value: item.name,
        id: item.id,
        teamId: item.teamId,
      }))
      .sort(sortByValueAsc) ?? [];

  const notAssignedUser = {
    value: 'Not Assigned',
    id: '00000000-0000-0000-0000-000000000000',
    teamId: 0,
  };

  assigneeOptions.push(notAssignedUser);

  return { assigneeOptionsQuery: userQuery, assigneeOptions };
};
