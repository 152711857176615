import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateVariationThirdPartyContributionMutation } from 'api/application/variationApi';
import { EFundingStillPursuing } from 'enums/EFundingStillPursuing';
import { useLocalSnackbar } from 'hooks';
import { IEditVariationThirdPartyContributionForm } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const _formId = 'edit-thirdpartycontribution-form';

const getName = (fieldName: keyof IEditVariationThirdPartyContributionForm) =>
  nameof<IEditVariationThirdPartyContributionForm>(fieldName);

const contributionTypeOptions: {
  id: EFundingStillPursuing;
  name: string;
}[] = [
  {
    id: EFundingStillPursuing.NotSignedUpDevelopersPledge,
    name: 'Not Signed Up Developers Pledge',
  },
  {
    id: EFundingStillPursuing.SignedUpDevelopersPledge,
    name: 'Signed Up Developers Pledge',
  },

  {
    id: EFundingStillPursuing.ClaimAgainstContracter,
    name: 'Claim Against Contractor',
  },

  {
    id: EFundingStillPursuing.Other,
    name: 'Other',
  },
];

interface IEditThirdPartyContributionDrawerModalProps {
  initialData: IEditVariationThirdPartyContributionForm;
  onConfirm: () => void;
  onCancel: () => void;
}

export function EditThirdPartyContributionDrawer({
  initialData,
  onConfirm,
  onCancel,
}: IEditThirdPartyContributionDrawerModalProps) {
  const queryParams = useApplicationAndVariationIdOrNull();

  const form = useForm<IEditVariationThirdPartyContributionForm>({
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = form;

  const { createErrorSnackbar, createSuccessSnackbar, createWarningSnackbar } = useLocalSnackbar();

  const [update, updateStatus] =
    useUpdateVariationThirdPartyContributionMutation();

  const onSubmit = async (
    formData: IEditVariationThirdPartyContributionForm
  ) => {
    if (!queryParams) {
      createErrorSnackbar(
        'Could not get variation data. Please try again later.'
      );
      return;
    }

    update({
      ...queryParams,
      formData,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(
          'The third party contribution changes have been successfully edited'
        );
        onConfirm();
        reset(formData);
      })
      .catch(error => {
        if (error?.data?.generalError) {
          createErrorSnackbar(error?.data?.generalError.errorMessage);
        } else if (error.data.propertyErrors) {
          setServerSideFormErrors(form, error.data);
          createWarningSnackbar(
            'Please correct any form validation errors shown, and then try again.'
          );
        } else {
          createErrorSnackbar(error);
        }
      });
  };

  return (
    <>
      <StyledDrawer anchor="right" open>
        <Box>
          <DialogTitle component="div">
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h1" component="span">
                Edit Third party contribution
              </Typography>
              <IconButton
                onClick={onCancel}
                aria-label="Close Drawer"
                name="Close Drawer"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
              <Grid container mt={1} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={getName('contributionAmount')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Amount"
                        helperText={fieldState.error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          inputProps: {
                            min: 1,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={getName('contributionPursuingTypes')}
                    render={({
                      fieldState,
                      field: { onChange, value, ...fieldProps },
                    }) => (
                      <Autocomplete
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                        value={Array.isArray(value) ? value : []}
                        options={contributionTypeOptions.map(({ id }) => id)}
                        getOptionLabel={opt =>
                          contributionTypeOptions.find(o => o.id === opt)
                            ?.name || ''
                        }
                        multiple
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              error={!!fieldState.error}
                              label="Contribution Type"
                              helperText={fieldState.error?.message}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={getName('contributionNotes')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Details"
                        helperText={fieldState.error?.message}
                        multiline
                        autoComplete="off"
                        inputProps={{ maxLength: 1000 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>

              <LoadingButton
                variant="contained"
                type="submit"
                form={_formId}
                loading={updateStatus.isLoading}
                disabled={!isDirty}
              >
                Update
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </Box>
      </StyledDrawer>
    </>
  );
}