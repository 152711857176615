import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useGetTaskHistoryQuery } from 'api/task';
import { Loading } from 'common/components/loading';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateTimeStringFromJsonDateString } from 'util/AppUtils';

interface IViewTaskAuditHistoryDialogProps {
  taskId: string;
  onClose: () => void;
}

export function ViewTaskAuditHistoryDialog({
  taskId,
  onClose,
}: IViewTaskAuditHistoryDialogProps) {
  const { data, isLoading } = useGetTaskHistoryQuery(taskId);

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open fullWidth maxWidth="lg">
      <StyledDialogTitle sx={{ textAlign: 'left' }}>History</StyledDialogTitle>
      <StyledDialogContent>
        {data && data.length > 0 ? (
          <StyledGenericTable>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Original Value</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Date/Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(item => {
                return (
                  <TableRow key={uuidv4()}>
                    <TableCell>{item.field}</TableCell>
                    <TableCell>{item.oldValue}</TableCell>
                    <TableCell>{item.newValue}</TableCell>
                    <TableCell>{item.user}</TableCell>
                    <TableCell>
                      {getUkDateTimeStringFromJsonDateString(item.changedDate)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledGenericTable>
        ) : (
          <NoAuditHistoryComponent />
        )}
      </StyledDialogContent>
      <StyledDialogActions
        sx={{ justifyContent: 'right', paddingRight: '2rem' }}
      >
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export function NoAuditHistoryComponent() {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={2}
      pl={0}
      borderColor={theme.palette.divider}
    >
      <Box padding="5px">
        <Typography variant="h1" component="span">
          There has been no changes made to this task
        </Typography>
      </Box>
    </Grid>
  );
}
