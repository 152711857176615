import { faClock } from '@fortawesome/free-solid-svg-icons';
import { LabelText } from 'common/components/listView/LabelText';
import { ListRow } from 'common/components/listView/ListRow';
import { ListViewIcon } from 'common/components/listView/ListViewIcon';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

const _labelColor = 'grey.700';

export const ListViewDateStack = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <ListRow>
      <LabelText value={label} isBold />
      <ListViewIcon icon={faClock} color={_labelColor} />
      <LabelText value={value === '-' ? '-' : getPrettyDateStringFromJsonDateString(value)} />
    </ListRow>
  );
};
