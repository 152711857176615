import { Box, Chip, Typography } from '@mui/material';
import { useGetWorksPackageOverviewQuery } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ReferralsGrid } from 'common/components/referrals';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ETaskStatusLanguage } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { CostVsBenchmarkSummary } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/components';
import {
  BuildingDetailsCard,
  ContentCard,
  KeyDatesCard,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/WorkPackageOverviewSection';
import { BuildingControlCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/WorkPackageOverviewSection/BuildingControlCard';
import { ThirdPartyContributionsCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/WorkPackageOverviewSection/ThirdPartyContributionsCard';
import { EReferralRecordType } from 'types/applications/ReferralTypes';
import { formatDateDayMonthYear } from 'util/DateUtils';

export const WorksPackageOverviewSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetWorksPackageOverviewQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <Box>
        <CostVsBenchmarkSummary applicationId={applicationId} data={data} />

        <RoundBorderBox sx={{ p: 0, mt: 2 }}>
          <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
            <Typography variant="h3" fontWeight={800} fontSize="1.3em">
              Overview
            </Typography>
          </Box>

          <ReferralsGrid
            referralRecordType={EReferralRecordType.WorksPackage}
            sx={{ m: 3, mb: 0 }}
          />

          <Box p={3}>
            <Box
              mb={2}
              bgcolor={'grey.100'}
              sx={{
                width: '100%',
                p: 2,
                pt: 3,
                borderRadius: 1,
              }}
            >
              <Typography
                variant="h3"
                marginBottom={2}
                fontWeight={900}
                fontSize={'1.05em'}
              >
                Duty of Care deed
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                bgcolor={'white'}
                sx={{
                  width: '100%',
                  padding: 2,
                  paddingTop: 3,
                  borderRadius: 2,
                }}
              >
                <Box>
                  <Typography variant="h3" fontWeight={900} fontSize={'0.9em'}>
                    Sent to grant certifying officer -{' '}
                    {data.grantCertifyingOfficer?.name}
                  </Typography>
                  <Typography variant="h3" fontWeight={600} fontSize={'0.8em'}>
                    {data.grantCertifyingOfficer?.companyName}
                  </Typography>
                </Box>

                <Typography variant="h3" fontSize={'0.8em'}>
                  Signed on{' '}
                  {data.dutyOfCareDeed?.dateSignedByGrantCertifyingOfficer
                    ? formatDateDayMonthYear(
                        data.dutyOfCareDeed.dateSignedByGrantCertifyingOfficer
                      )
                    : ''}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="center"
                >
                  {data.dutyOfCareDeed?.status && (
                    <Chip
                      size="small"
                      label={ETaskStatusLanguage[data.dutyOfCareDeed.status]}
                      color={'default'}
                      variant={'filled'}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <KeyDatesCard data={data.keyDates} title="Key dates (expected)" />
              <BuildingDetailsCard
                data={data.buildingDetails}
                title="Building details"
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <ThirdPartyContributionsCard
                data={data.thirdPartyContributions}
                title="Third Party Contributions"
              />
              <BuildingControlCard
                data={data?.buildingControl}
                title="Building control"
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <ContentCard
                reason={data.competitiveBids?.reasonCompetitiveBidsNotRequired}
                title="Competitive bids"
                chipText={
                  data.competitiveBids?.competitiveBids
                    ? 'Obtained'
                    : 'Not obtained'
                }
              />
              <ContentCard
                reason={
                  data.planningPermission?.reasonPlanningPermissionNotRequired
                }
                title="Planning permission"
                chipText={
                  data.planningPermission.requirePlanningPermission
                    ? 'Required'
                    : 'Not required'
                }
              />
            </Box>
          </Box>
        </RoundBorderBox>
      </Box>
    );
  }

  return null;
};
