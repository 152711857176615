import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useGetConfirmEligibilityChecksQuery } from 'api/application';
import {
  useEditAddressMutation,
  useGetAddressQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { EEligiblityCheckStatus } from 'enums/EEligiblityCheckStatus';
import { EQuestionType } from 'enums/EQuestionType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EligibilityApproved } from 'pages/applicationPage/content/applicationDetails/dialogs/EditCompanyDetailsDrawer';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zAddressSchema } from 'types/applications/ApplicationEdit.zod';
import {
  IAddress,
  IGetAddressRequest,
} from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddress) => nameof<IAddress>(fieldName);

interface IEditAddressDrawerProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditAddressDrawer({
  questionType,
  onClose,
  onSuccess,
}: IEditAddressDrawerProps) {
  const { applicationId } = useApplicationContext();
  const eligibilityQuery = useGetConfirmEligibilityChecksQuery(applicationId);
  const isEligibilityApproved =
    eligibilityQuery.data?.status === EEligiblityCheckStatus.Approved;

  const getDrawerTitle = () => {
    switch (questionType) {
      case EQuestionType.BuildingAddress:
        return 'Edit building address';
      case EQuestionType.DeveloperAddress:
        return 'Edit developer address';
      case EQuestionType.ResponsibleEntityAddress:
        return 'Edit responsible entity company address';
      case EQuestionType.RepresentativeAddress:
        return 'Edit representative company address';
      case EQuestionType.FreeholderAddress:
        return 'Edit freeholder address';
      default:
        return 'Edit Address';
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {getDrawerTitle()}
          </Typography>
        </DialogTitle>

        {isEligibilityApproved &&
        questionType === EQuestionType.BuildingAddress ? (
          <EligibilityApproved onClose={onClose} />
        ) : (
          <EditAddressForm
            onSuccess={onSuccess}
            onClose={onClose}
            questionType={questionType}
          />
        )}
      </Box>
    </StyledDrawer>
  );
}

interface IEditAddressFormProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditAddressForm = ({
  questionType,
  onClose,
  onSuccess,
}: IEditAddressFormProps) => {
  const { applicationId } = useApplicationContext();
  const addressRequest: IGetAddressRequest = {
    applicationId: applicationId,
    questionType: questionType,
  };

  const { data: address, isLoading: formLoading } =
    useGetAddressQuery(addressRequest);
  const [editAddress, editAddressResult] = useEditAddressMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAddress>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zAddressSchema),
  });
  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    const initialFormData = { ...address, changeReason: '' };
    reset(initialFormData);
  }, [address, reset]);

  const onSubmit = async (formData: IAddress) => {
    try {
      formData.applicationId = applicationId;
      formData.questionType = questionType;

      await editAddress(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Address updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit address`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('nameNumber')}
                  control={control}
                  defaultValue={address?.nameNumber ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Name / Number"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('addressLine1')}
                  control={control}
                  defaultValue={address?.addressLine1 ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Address Line 1"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('addressLine2')}
                  control={control}
                  defaultValue={address?.addressLine2 ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="Address Line 2"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('city')}
                  control={control}
                  defaultValue={address?.city ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="City"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('county')}
                  control={control}
                  defaultValue={address?.county ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="County"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('postcode')}
                  control={control}
                  defaultValue={address?.postcode ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Postcode"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editAddressResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
