import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetReferralListQuery } from 'api/application/referralApi';
import {
  useGetVariationCostsScheduleQuery,
  useGetVariationOverviewQuery,
} from 'api/application/variationApi';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useCurrentUser, useCurrentUserPermissions } from 'hooks';
import { ISelectedLiveProjectItem } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useVariationContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationContext';
import {
  CostTypeVariation,
  EVariationStatus,
  EVariationType,
  IVariationOverview,
  IVariationReferralListItem,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import {
  EReferralRecordType,
  EReferralStatus,
  EReferralType,
} from 'types/applications/ReferralTypes';
import { isOfType } from 'util/AppUtils';

export const useGetVariationPercentage = (
  originalTotal?: number,
  newTotal?: number
) => {
  if (originalTotal === undefined || newTotal === undefined) {
    return;
  }

  return Number(
    (((newTotal - originalTotal) / originalTotal) * 100).toFixed(2)
  );
};

export const allowedVariationVariancePercentage = 20;

export const useIsVariancePercentageGreaterThanThreshold = (
  variationOverview: IVariationOverview | null,
  variation: CostTypeVariation | null
) => {
  const variationPercentage = useGetVariationPercentage(
    variationOverview?.worksPackageTotalOriginal,
    variation?.workPackageTotalNew
  );

  if (
    variationOverview === null ||
    variation === null ||
    variationPercentage === undefined
  ) {
    return false;
  }

  return variationPercentage > allowedVariationVariancePercentage;
};

export const useGetVariationAmount = (
  worksPackageTotalOriginal?: number,
  workPackageTotalNew?: number
) => {
  if (
    workPackageTotalNew === undefined ||
    worksPackageTotalOriginal === undefined
  ) {
    return 0;
  }
  return workPackageTotalNew - worksPackageTotalOriginal;
};

export const useApplicationAndVariationIdOrNull = () => {
  const { applicationId, variation } = useVariationContext();
  return !variation
    ? null
    : {
        applicationId,
        variationId: variation.id,
      };
};

export const useApplicationAndVariationIdOrSkipToken = () => {
  const { applicationId, variation } = useVariationContext();
  return getApplicationAndVariationIdOrSkipToken(applicationId, variation);
};

export const getApplicationAndVariationIdOrSkipToken = (
  applicationId: string,
  variation: ISelectedLiveProjectItem | null
) => {
  const skipTokenOrQueryParams = !variation
    ? skipToken
    : {
        applicationId,
        variationId: variation.id,
      };

  return skipTokenOrQueryParams;
};

export const useApplicationAndVariationIdOrSkipTokenWithReferralType = () => {
  const { applicationId, variation } = useVariationContext();

  const skipTokenOrQueryParams = !variation
    ? skipToken
    : {
        type: EReferralRecordType.Variation,
        applicationId,
        variationId: variation.id,
      };

  return skipTokenOrQueryParams;
};

export const useVariationCostsSchedule = () => {
  const queryParams = useApplicationAndVariationIdOrSkipToken();
  const { isSuccess, data } = useGetVariationCostsScheduleQuery(queryParams);
  const hasAmended = Boolean(data?.amended);
  return { isSuccess, data, hasAmended };
};

const hasType = (
  variations: IVariationOverview['variations'],
  type: EVariationType
) => {
  return Boolean(variations.find(isOfType(type)));
};

export const useVariationOverview = () => {
  const queryParams = useApplicationAndVariationIdOrSkipToken();
  return useVariationOverviewWithParams(queryParams);
};

export const useVariationOverviewWithParams = (
  queryParams: ReturnType<typeof getApplicationAndVariationIdOrSkipToken>
) => {
  const query = useGetVariationOverviewQuery(queryParams);
  const { data } = query;
  const variations = query.data?.variations ?? [];
  const hasTimescaleType = hasType(variations, EVariationType.Timescale);
  const hasCostType = hasType(variations, EVariationType.Cost);
  const hasScopeType = hasType(variations, EVariationType.Scope);
  const hasThirdPartyContributionType = hasType(variations, EVariationType.ThirdPartyContribution);
  const isApprovedOrRejected =
    data?.status === EVariationStatus.Approved ||
    data?.status === EVariationStatus.Rejected;
  const costVariation = variations.find(isOfType(EVariationType.Cost));
  const isVariancePercentageGreaterThanThreshold =
    useIsVariancePercentageGreaterThanThreshold(
      data ?? null,
      costVariation ?? null
    );
  const variationPercentage = useGetVariationPercentage(
    data?.worksPackageTotalOriginal,
    costVariation?.workPackageTotalNew
  );
  const variationAmount = useGetVariationAmount(
    data?.worksPackageTotalOriginal,
    costVariation?.workPackageTotalNew
  );
  return {
    query,
    hasTimescaleType,
    hasCostType,
    hasScopeType,
    hasThirdPartyContributionType,
    isApprovedOrRejected,
    costVariation,
    isVariancePercentageGreaterThanThreshold,
    variationPercentage,
    variationAmount,
  };
};

export const useVariationReferrals = () => {
  const queryParams = useApplicationAndVariationIdOrSkipTokenWithReferralType();
  const query = useGetReferralListQuery(queryParams);
  const referrals = (query.data ?? []) as IVariationReferralListItem[];
  const hasOpenThirdPartyReferral = referrals.some(
    referral =>
      referral.type === EReferralType.ThirdParty &&
      referral.status !== EReferralStatus.Complete
  );
  const hasHeReferral = referrals.some(
    referral => referral.type === EReferralType.HomesEngland
  );
  const openHeReferral = referrals.find(
    referral =>
      referral.type === EReferralType.HomesEngland &&
      referral.status !== EReferralStatus.Complete
  );

  return {
    query,
    hasOpenThirdPartyReferral,
    hasHeReferral,
    openHeReferral,
  };
};

export const useIsVariationReadOnly = () => {
  const { isHeUser } = useCurrentUser();
  const { hasHeReferral } = useVariationReferrals();
  const { applicationId, variation } = useVariationContext();
  const { isApprovedOrRejected } = useVariationOverviewWithParams(
    getApplicationAndVariationIdOrSkipToken(applicationId, variation)
  );
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );
  
  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Variation,
  });

  const isVariationReadOnly = () => {
    if (isApprovedOrRejected) {
      return true;
    }

    if (isOnHoldForThisType) {
      return true;
    }

    // If the user is Davies and there is a HE referral, then the variation is read only
    if (!isHeUser && hasHeReferral) {
      return true;
    }

    if (!hasAdminApplicationEdit) {
      return true;
    }

    return false;
  };

  return { isVariationReadOnly: isVariationReadOnly() };
};
