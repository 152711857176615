import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GetPaymentRequestReferralsQueryResult } from 'api/application/applicationApi';
import {
  useGetPaymentRequestOverviewQuery,
  useGetPaymentRequestReferralsQuery,
} from 'api/application/paymentRequestApi';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import {
  EClosingPaymentRequestSection,
  EPaymentRecommendationStatus,
  IPaymentRequestReferral,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

interface IClosingPaymentRequestContext {
  applicationId: string;
  selectedItemId: EClosingPaymentRequestSection;
  setSelectedItemId: (itemId: EClosingPaymentRequestSection) => void;
  readOnly: boolean;
  currentStatus?: EPaymentRecommendationStatus;
  paymentRequestReferralsQuery: GetPaymentRequestReferralsQueryResult;
  referrals: IPaymentRequestReferral[];
  showReferApplicationDialog: boolean;
  handleShowReferApplicationDialog: (show: boolean) => void;
  showReferralsSection: () => void;
  isReferralsSectionShowing: boolean;
  paymentRequestId: string;
}

const ClosingPaymentRequestContext = createContext<
  IClosingPaymentRequestContext | undefined
>(undefined);

interface IClosingPaymentRequestContextProviderProps {
  children: ReactNode;
}

export const ClosingPaymentRequestContextProvider = ({
  children,
}: IClosingPaymentRequestContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();
  const { selectedItem } = useApplicationLiveProjectContext();

  const [selectedItemId, setSelectedItemId] =
    useState<EClosingPaymentRequestSection>(
      EClosingPaymentRequestSection.Overview
    );

  const handleSetSelectedItemId = (itemId: EClosingPaymentRequestSection) => {
    setIsReferralsSectionShowing(false);
    setSelectedItemId(itemId);
  };

  const paymentRequestId = selectedItem?.id ?? '';

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const paymentRequestReferralsQuery =
    useGetPaymentRequestReferralsQuery(request);

  const paymentRequestOverviewQuery =
    useGetPaymentRequestOverviewQuery(request);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );

  const readOnly =
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Approved ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Rejected ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Expired ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Paid ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Sent ||
    closed ||
    !hasAdminApplicationEdit;

  const currentStatus = paymentRequestOverviewQuery.data?.paymentRecommendation;

  useEffect(() => {
    if (paymentRequestReferralsQuery.data?.results) {
      const newReferrals = paymentRequestReferralsQuery.data.results.map(d => ({
        ...d,
      }));

      setReferrals(newReferrals);
    }
  }, [paymentRequestReferralsQuery.data?.results]);

  const [referrals, setReferrals] = useState<IPaymentRequestReferral[]>([]);

  const [isReferralsSectionShowing, setIsReferralsSectionShowing] =
    useState(false);

  const [showReferApplicationDialog, setShowReferApplicationDialog] =
    useState<boolean>(false);

  const handleShowReferApplicationDialog = useCallback((show: boolean) => {
    setShowReferApplicationDialog(show);
  }, []);

  const showReferralsSection = () => {
    setIsReferralsSectionShowing(true);
  };

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
      currentStatus,
      referrals,
      paymentRequestReferralsQuery,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      showReferralsSection,
      isReferralsSectionShowing,
      paymentRequestId,
    }),
    [
      paymentRequestReferralsQuery,
      applicationId,
      handleShowReferApplicationDialog,
      isReferralsSectionShowing,
      readOnly,
      currentStatus,
      referrals,
      selectedItemId,
      showReferApplicationDialog,
      paymentRequestId,
    ]
  );

  return (
    <ClosingPaymentRequestContext.Provider value={value}>
      {children}
    </ClosingPaymentRequestContext.Provider>
  );
};

export const useClosingPaymentRequestContext = () => {
  const context = useContext(ClosingPaymentRequestContext);
  if (context === undefined) {
    throw new Error(
      'useClosingPaymentRequestContext must be used within an ClosingPaymentRequestContextProvider'
    );
  }
  return context;
};
