import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add, parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddDirectorMutation,
  useEditDirectorMutation,
  useLazyGetDirectorQuery,
} from 'api/application';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IAddEditDirector } from 'types/applications/ApplicationEligibilityTypes';
import { getDateOnlyIsoString } from 'util/DateUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddEditDirector) =>
  nameof<IAddEditDirector>(fieldName);

interface IAddEditDirectorDrawerProps {
  directorId?: string | null;
  onClose: () => void;
  onSuccess: () => void;
}

export function AddEditDirectorDrawer({
  directorId,
  onClose,
  onSuccess,
}: IAddEditDirectorDrawerProps) {
  const { applicationId } = useApplicationContext();
  const [getDirector] = useLazyGetDirectorQuery();
  const [addDirector] = useAddDirectorMutation();
  const [editDirector] = useEditDirectorMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [director, setDirector] = useState<IAddEditDirector>();
  const [formLoading, setFormLoading] = useState(true);

  const form = useForm<IAddEditDirector>({ defaultValues: director });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(director);
  }, [director, reset]);

  useEffect(() => {
    if (directorId) {
      getDirector({ applicationId, directorId }).then(result => {
        if (result && result.data) {
          setDirector(result.data);
          setFormLoading(false);
        }
      });
    } else {
      setFormLoading(false);
    }
  }, [applicationId, directorId, getDirector, reset]);

  const onSubmit = async (formData: IAddEditDirector) => {
    formData.applicationId = applicationId;
    if (directorId === null) {
      try {
        await addDirector(formData as IAddEditDirector)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`New Director added`);
            onSuccess();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add director`);
      }
    } else
      try {
        formData.id = directorId;
        await editDirector(formData as IAddEditDirector)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`Director updated successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit director`);
      }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {directorId ? 'Edit' : 'Add'} Director:
            {directorId ? director?.firstName + ' ' + director?.lastName : ''}
          </Typography>
        </DialogTitle>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <DialogContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} paddingBottom={2}>
                  <Typography variant="h3">Personal Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={getName('firstName')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="First Name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={getName('lastName')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Last Name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('dateOfBirth')}
                    render={({
                      field: { onChange, value, ...field },
                      formState: { errors },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          format="dd/MM/yyyy"
                          maxDate={add(new Date(), { days: -1 })}
                          label="Date Of Birth"
                          value={parseISO(value)}
                          onChange={val =>
                            val ? onChange(getDateOnlyIsoString(val)) : null
                          }
                          slotProps={{
                            textField: {
                              sx: { mr: '1rem' },
                              error: !!errors.dateOfBirth,
                              helperText: errors.dateOfBirth?.message,
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} paddingBottom={2}>
                  <Typography variant="h3">Address Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.addressLine1"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Address Line 1"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.addressLine2"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="Address Line 2"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.townCity"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Town / City"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.county"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="County"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.postcode"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Postcode"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  {directorId ? 'Update' : 'Add'} Director
                </Button>
              </Grid>
            </StyledDrawerActions>
          </form>
        </FormProvider>
      </Box>
    </StyledDrawer>
  );
}
