import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  useGetEvidenceForChiefExecutiveQuery,
  useEditEvidenceForChiefExecutiveMutation,
} from 'api/application/applicationEditApi';
import { FileRow } from 'common/components/fileRow';
import { useLocalSnackbar, useFileUpload } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zEvidenceForChiefExecutiveSchema } from 'types/applications/ApplicationEdit.zod';
import { ILeaseholderEngagementForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditEvidenceForChiefExecutiveDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditEvidenceForChiefExecutiveDrawer({
  onClose,
  onSuccess,
}: IEditEvidenceForChiefExecutiveDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit Evidence for Chief Executive
          </Typography>
        </DialogTitle>
        <EditEvidenceForChiefExecutiveForm
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IEditEvidenceForChiefExecutiveFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditEvidenceForChiefExecutiveForm = ({
  onClose,
  onSuccess,
}: IEditEvidenceForChiefExecutiveFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data, isLoading } =
    useGetEvidenceForChiefExecutiveQuery(applicationId);

  const [editEvidenceForChiefExecutive, editEvidenceForChiefExecutiveResult] =
    useEditEvidenceForChiefExecutiveMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const { uploadFile } = useFileUpload();

  const form = useForm<ILeaseholderEngagementForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zEvidenceForChiefExecutiveSchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  const fileOne = watch('files')?.[0] ?? {};
  const fileTwo = watch('files')?.[1] ?? {};
  const fileThree = watch('files')?.[2] ?? {};
  const fileFour = watch('files')?.[3] ?? {};
  const fileFive = watch('files')?.[4] ?? {};

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onSubmit = async (formData: ILeaseholderEngagementForm) => {
    try {
      formData.applicationId = applicationId;
      await editEvidenceForChiefExecutive(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(
            `Evidence for Chief Executive documents updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(
        `Failed to edit evidence for Chief Executive documents`
      );
    }
  };

  const handleOnDeleteFileUpload = async (deletedFileId: string) => {
    const filtered = watch('files').filter(({ id }) => id !== deletedFileId);
    setValue(`files`, filtered);
  };

  const handleOnFileUpload = (file: File) => {
    uploadFile({
      file,
      uploadSection: 'EvidenceForChiefExecutive',
      onSuccess: payload => {
        const currentFiles = watch('files');
        setValue('files', [...currentFiles, payload]);
      },
    });
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FileRow
                name="evidenceForChiefExecutiveFileOne"
                file={fileOne}
                onDelete={handleOnDeleteFileUpload}
                onUpload={handleOnFileUpload}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FileRow
                name="evidenceForChiefExecutiveFileTwo"
                file={fileTwo}
                onDelete={handleOnDeleteFileUpload}
                onUpload={handleOnFileUpload}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FileRow
                name="evidenceForChiefExecutiveFileThree"
                file={fileThree}
                onDelete={handleOnDeleteFileUpload}
                onUpload={handleOnFileUpload}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FileRow
                name="evidenceForChiefExecutiveFileFour"
                file={fileFour}
                onDelete={handleOnDeleteFileUpload}
                onUpload={handleOnFileUpload}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FileRow
                name="evidenceForChiefExecutiveFileFive"
                file={fileFive}
                onDelete={handleOnDeleteFileUpload}
                onUpload={handleOnFileUpload}
              />
            </Grid>
            {form.formState.errors?.files && (
              <FormHelperText sx={{ ml: 2, mb: 3 }} error>
                {form.formState.errors.files?.message}
              </FormHelperText>
            )}
            <Grid item xs={12} mb={2}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editEvidenceForChiefExecutiveResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
