import { Button } from '@mui/material';
import { useRecommendPaymentRequestMutation } from 'api/application/paymentRequestApi';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IRecommendPaymentRequestModalProps {
  applicationId: string;
  paymentRequestId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function RecommendPaymentRequestModal({
  applicationId,
  paymentRequestId,
  onConfirm,
  onCancel,
}: IRecommendPaymentRequestModalProps) {
  const [recommendPaymentRequest] = useRecommendPaymentRequestMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const handleApprovePaymentRequest = async () => {
    await recommendPaymentRequest({
      applicationId,
      paymentRequestId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Payment Request Recommended');
        onConfirm();
      })
      .catch(error => {
        if (error?.data?.generalError?.errorMessage) {
          createErrorSnackbar(error.data.generalError.errorMessage);
        } else {
          createErrorSnackbar('An error occured');
        }
      });
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to recommend approval of this payment request?
      </StyledDialogTitle>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleApprovePaymentRequest}>
          Recommend Payment
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
