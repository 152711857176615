import { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditGrantFundingDetailsChecksMutation,
  useGetGrantFundingDetailsChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { ReferApplicationHomesEnglandNotificationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components';
import { SendDeedOfTrustInformationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components/SendDeedOfTrustInformationBanner';
import { SendGrantFundingAgreementInformationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components/SendGrantFundingAgreementInformationBanner';
import { GrantFundingDetailsChecksInfoPanel } from 'pages/applicationPage/content/applicationGrantFunding/components/details/GrantFundingDetailsChecksInfoPanel';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';
import { IApplicationGrantFundingDetailsChecks } from 'types/applications/ApplicationGrantFundingTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

type FileInputPropName = keyof Pick<
  IApplicationGrantFundingDetailsChecks,
  'gfa' | 'deedOfTrust'
>;

type FileIdInputName = keyof Pick<
  IApplicationGrantFundingDetailsChecks,
  'gfaFileId' | 'deedOfTrustFileId'
>;

const getName = (fieldName: keyof IApplicationGrantFundingDetailsChecks) =>
  nameof<IApplicationGrantFundingDetailsChecks>(fieldName);

export const GrantFundingDetailsChecksForm = () => {
  const { applicationId } = useApplicationGrantFundingContext();
  const query = useGetGrantFundingDetailsChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationGrantFundingDetailsChecks = {
      ...data,
      gfaFileId: data.gfa?.id ?? '',
      deedOfTrustFileId: data.deedOfTrust?.id ?? '',
    };

    return <GrantFundingDetailsChecksFormMain initialData={initialData} />;
  }

  return null;
};

const GrantFundingDetailsChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationGrantFundingDetailsChecks;
}) => {
  const { applicationId, readOnly: isGfaReadOnly } =
    useApplicationGrantFundingContext();

  const [editGrantFundingDetailsChecks, editGrantFundingDetailsChecksStatus] =
    useEditGrantFundingDetailsChecksMutation();
  const { uploadFile } = useFileUpload();

  const [data] = useState<IApplicationGrantFundingDetailsChecks | undefined>(
    initialData
  );

  const [gfaFileInvalid, setGfaFileInvalid] = useState<boolean>(false);
  const [deedOfTrustFileInvalid, setDeedOfTrustFileInvalid] =
    useState<boolean>(false);

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationGrantFundingDetailsChecks>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.GrantFundingAgreement,
  });

  const watchSignatoryDetailsAreSatisfactory = watch(
    'signatoryDetailsAreSatisfactory'
  );
  const watchGfaSent = watch('gfaSent');
  const watchGfa = watch('gfa');
  const watchGfaFileId = watch('gfaFileId');
  const watchDeedOfTrustSent = watch('deedOfTrustSent');
  const watchDeedOfTrust = watch('deedOfTrust');
  const watchDeedOfTrustFileId = watch('deedOfTrustFileId');
  const watchValidReasonForIssuesWithSignatoryDetails = watch(
    'validReasonForIssuesWithSignatoryDetails'
  );

  const validForm = (formData: IApplicationGrantFundingDetailsChecks) => {
    let valid = true;
    setGfaFileInvalid(false);
    setDeedOfTrustFileInvalid(false);

    if (
      formData.gfaSent &&
      (formData.gfaFileId === undefined || formData.gfaFileId === '')
    ) {
      setGfaFileInvalid(true);
      valid = false;
    }

    if (
      formData.deedOfTrustRequired &&
      formData.deedOfTrustSent &&
      (formData.deedOfTrustFileId === undefined ||
        formData.deedOfTrustFileId === '')
    ) {
      setDeedOfTrustFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const onSubmit = async () => {
    const formData = getValues();
    formData.deedOfTrustRequired = initialData.deedOfTrustRequired;
    if (validForm(formData)) {
      try {
        formData.id = applicationId;
        await editGrantFundingDetailsChecks(
          formData as IApplicationGrantFundingDetailsChecks
        )
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(
              `Grant Funding Details Checks updated successfully`
            );
            reset({}, { keepValues: true });
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit Grant Funding Details checks`);
      }
    }
  };

  const handleOnFileUpload = async (
    file: File,
    fileInputPropName: FileInputPropName,
    fileIdInputName: FileIdInputName
  ) => {
    await uploadFile({
      file,
      uploadSection: 'GrantFundingDetails',
      onSuccess: payload => {
        form.setValue(fileInputPropName, payload);
        form.setValue(fileIdInputName, payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async (
    fileInputPropName: FileInputPropName,
    fileInputName: FileIdInputName
  ) => {
    form.setValue(fileInputPropName, null);
    form.setValue(fileInputName, '');
  };

  const isReadOnly = isGfaReadOnly;

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Land Registry Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.GrantFundingAgreement} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editGrantFundingDetailsChecksStatus.isError}
            error={editGrantFundingDetailsChecksStatus.error}
          />
          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box p={3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Check if the signatories details are satisfactory?
                        </Typography>
                        <Controller
                          defaultValue={undefined}
                          name={getName('signatoryDetailsAreSatisfactory')}
                          render={({
                            fieldState,
                            field: { onChange, ...fieldProps },
                          }) => (
                            <FormControl>
                              <ToggleButtonGroup
                                {...fieldProps}
                                onChange={(_, val) => {
                                  if (val !== null) {
                                    onChange(val);
                                  }
                                }}
                                exclusive
                                disabled={isReadOnly || isOnHoldForThisType}
                              >
                                <ToggleButton value={true}>Pass</ToggleButton>
                                <ToggleButton value={false}>Fail</ToggleButton>
                              </ToggleButtonGroup>
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {watchSignatoryDetailsAreSatisfactory && (
                        <>
                          <SendGrantFundingAgreementInformationBanner />
                          <hr />
                          <SendDeedOfTrustInformationBanner />
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Confirm that the Grant Funding Agreement has been
                              sent to be signed
                            </Typography>
                            <Controller
                              defaultValue={false}
                              name={getName('gfaSent')}
                              render={({
                                fieldState,
                                field: { onChange, value, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...fieldProps}
                                        checked={value}
                                        onChange={(_, val) => {
                                          if (val !== null) onChange(val);
                                        }}
                                        disabled={
                                          isReadOnly || isOnHoldForThisType
                                        }
                                      />
                                    }
                                    label="I have sent the Grant Funding Agreement"
                                  />
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                          </Grid>
                          {watchGfaSent && (
                            <Grid item mb={2}>
                              <Typography
                                variant="h3"
                                fontWeight={600}
                                fontSize="1em"
                              >
                                Upload Grant Funding Agreement evidence
                              </Typography>
                              {(watchGfaFileId !== undefined &&
                                watchGfaFileId === data?.gfa?.id) ||
                              isReadOnly ? (
                                <Box display="flex" flexDirection="row">
                                  <ApplicationFileDownloadWrapper
                                    fileName={watchGfa?.name ?? 'Download'}
                                    fileId={watchGfaFileId}
                                  />
                                  {!isReadOnly ? (
                                    <IconButton
                                      size="small"
                                      disabled={isOnHoldForThisType}
                                      onClick={() =>
                                        handleOnDeleteFileUpload(
                                          'gfa',
                                          'gfaFileId'
                                        )
                                      }
                                      aria-label="Delete File"
                                      name="Delete File"
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                  ) : null}
                                </Box>
                              ) : (
                                <>
                                  <FileUploadInputSelect
                                    id="gfaFile"
                                    accept=".pdf"
                                    inputName="gfaFileId"
                                    onFileUpload={file =>
                                      handleOnFileUpload(
                                        file,
                                        'gfa',
                                        'gfaFileId'
                                      )
                                    }
                                    onFileDelete={() =>
                                      handleOnDeleteFileUpload(
                                        'gfa',
                                        'gfaFileId'
                                      )
                                    }
                                    disabled={isOnHoldForThisType}
                                  />
                                  {gfaFileInvalid && (
                                    <FormHelperText error>
                                      Grant Funding Agreement document is
                                      required.
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                              {data?.gfaUploadDate && (
                                <Typography align="right" paddingRight={5}>
                                  {`GFA Uploaded Date: ${new Date(
                                    data?.gfaUploadDate
                                  )?.toLocaleString()}`}
                                </Typography>
                              )}
                            </Grid>
                          )}
                          {initialData.deedOfTrustRequired && (
                            <>
                              <Grid item mb={2}>
                                <Typography
                                  variant="h3"
                                  fontWeight={600}
                                  fontSize="1em"
                                >
                                  Confirm that the Deed Of Trust has been sent
                                  to be signed
                                </Typography>
                                <Controller
                                  defaultValue={false}
                                  name={getName('deedOfTrustSent')}
                                  render={({
                                    fieldState,
                                    field: { onChange, value, ...fieldProps },
                                  }) => (
                                    <FormControl>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            {...fieldProps}
                                            checked={value}
                                            onChange={(_, val) => {
                                              if (val !== null) onChange(val);
                                            }}
                                            disabled={
                                              isReadOnly || isOnHoldForThisType
                                            }
                                          />
                                        }
                                        label="I have sent the Deed Of Trust"
                                      />
                                      {fieldState.error?.message ? (
                                        <FormHelperText error>
                                          {fieldState.error?.message}
                                        </FormHelperText>
                                      ) : null}
                                    </FormControl>
                                  )}
                                />
                              </Grid>
                              {watchDeedOfTrustSent && (
                                <Grid item mb={2}>
                                  <Typography
                                    variant="h3"
                                    fontWeight={600}
                                    fontSize="1em"
                                  >
                                    Upload Deed of Trust evidence
                                  </Typography>
                                  {(watchDeedOfTrustFileId !== undefined &&
                                    watchDeedOfTrustFileId ===
                                      data?.deedOfTrust?.id) ||
                                  isReadOnly ? (
                                    <Box display="flex" flexDirection="row">
                                      <ApplicationFileDownloadWrapper
                                        fileName={
                                          watchDeedOfTrust?.name ?? 'Download'
                                        }
                                        fileId={watchDeedOfTrustFileId}
                                      />
                                      {!isReadOnly ? (
                                        <IconButton
                                          size="small"
                                          disabled={isOnHoldForThisType}
                                          onClick={() =>
                                            handleOnDeleteFileUpload(
                                              'deedOfTrust',
                                              'deedOfTrustFileId'
                                            )
                                          }
                                          aria-label="Delete File"
                                          name="Delete File"
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </IconButton>
                                      ) : null}
                                    </Box>
                                  ) : (
                                    <>
                                      <FileUploadInputSelect
                                        id="deedOfTrustFile"
                                        accept=".pdf"
                                        inputName="deedOfTrustFileId"
                                        onFileUpload={file =>
                                          handleOnFileUpload(
                                            file,
                                            'deedOfTrust',
                                            'deedOfTrustFileId'
                                          )
                                        }
                                        onFileDelete={() =>
                                          handleOnDeleteFileUpload(
                                            'deedOfTrust',
                                            'deedOfTrustFileId'
                                          )
                                        }
                                        disabled={isOnHoldForThisType}
                                      />
                                      {deedOfTrustFileInvalid && (
                                        <FormHelperText error>
                                          Deed of Trust document is required.
                                        </FormHelperText>
                                      )}
                                    </>
                                  )}
                                  {data?.deedOfTrustUploadDate && (
                                    <Typography align="right" paddingRight={5}>
                                      {`Deed Of Trust Uploaded Date: ${new Date(
                                        data?.deedOfTrustUploadDate
                                      )?.toLocaleString('en-GB')}`}
                                    </Typography>
                                  )}
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {watchSignatoryDetailsAreSatisfactory === false && (
                        <>
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Has a valid reason for issues with signatory
                              details?
                            </Typography>
                            <Controller
                              defaultValue={undefined}
                              name={getName(
                                'validReasonForIssuesWithSignatoryDetails'
                              )}
                              render={({
                                fieldState,
                                field: { onChange, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <ToggleButtonGroup
                                    {...fieldProps}
                                    onChange={(_, val) => {
                                      if (val !== null) {
                                        onChange(val);
                                      }
                                    }}
                                    exclusive
                                    disabled={isReadOnly || isOnHoldForThisType}
                                  >
                                    <ToggleButton value={true}>
                                      Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                      No
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                          </Grid>
                          {watchValidReasonForIssuesWithSignatoryDetails && (
                            <Grid item mb={2}>
                              <Typography
                                variant="h3"
                                fontWeight={600}
                                fontSize="1em"
                              >
                                Provide Reason
                              </Typography>
                              <Controller
                                name={getName(
                                  'validReasonForIssuesWithSignatoryDetailsReason'
                                )}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error}
                                    required
                                    label="Provide Reason"
                                    helperText={fieldState.error?.message}
                                    multiline
                                    disabled={isReadOnly || isOnHoldForThisType}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <ReferApplicationHomesEnglandNotificationBanner />
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={3} item>
              <Box p={3}>
                <GrantFundingDetailsChecksInfoPanel />
              </Box>
            </Grid>
            {!isReadOnly && (
              <Grid container p={3} bgcolor="grey.100">
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right">
                    <OnOffHold holdType={EOnHoldType.GrantFundingAgreement} />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right" gap={2}>
                    <Button
                      variant="outlined"
                      onClick={() => reset()}
                      disabled={isOnHoldForThisType}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                      loading={editGrantFundingDetailsChecksStatus.isLoading}
                    >
                      {watchSignatoryDetailsAreSatisfactory === false
                        ? 'Save and Refer Application'
                        : 'Save'}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
