/* eslint-disable no-restricted-imports */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { administrationApi } from 'api/administration';
import { applicationApi } from 'api/application';
import { lookUpApi } from 'api/application/lookUpApi';
import { countriesApi } from 'api/common/countriesApi';
import { holdReasonsApi } from 'api/common/holdReasonsApi';
import { fileApi } from 'api/file';
import { profileApi } from 'api/profile';
import {
  buildingRecordsAddressApi,
  pullInProcessesApi,
} from 'api/pullInProcess';
import { supportApi } from 'api/support';
import { taskApi } from 'api/task';
import { administrationReducer } from 'state/slices/administration';
import { applicationsReducer } from 'state/slices/applications';
import { navigationBarReducer } from 'state/slices/navigationBarReducer';
import { pullInProcessReducer } from 'state/slices/pullInProcess';
import { taskDrawerReducer } from 'state/slices/task';

export const reducer = {
  navigationBar: navigationBarReducer,
  taskDrawer: taskDrawerReducer,
  administration: administrationReducer,
  applications: applicationsReducer,
  pullInProcesses: pullInProcessReducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [administrationApi.reducerPath]: administrationApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [pullInProcessesApi.reducerPath]: pullInProcessesApi.reducer,
  [buildingRecordsAddressApi.reducerPath]: buildingRecordsAddressApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [holdReasonsApi.reducerPath]: holdReasonsApi.reducer,
  [lookUpApi.reducerPath]: lookUpApi.reducer,
};

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat([
      profileApi.middleware,
      fileApi.middleware,
      administrationApi.middleware,
      applicationApi.middleware,
      taskApi.middleware,
      supportApi.middleware,
      pullInProcessesApi.middleware,
      buildingRecordsAddressApi.middleware,
      countriesApi.middleware,
      holdReasonsApi.middleware,
      lookUpApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
