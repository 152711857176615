import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useCurrentUser, useCurrentUserPermissions } from 'hooks';
import { CostScheduleCSVButton } from 'pages/applicationPage/content/liveProject/common/CostSchedulingCSVButton';
import { useVariationReferrals } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import {
  DaviesUserButtonsProps,
  _formId,
  useCanFormBeApproved,
  useHomesEnglandReferralLogic,
  useRejectLogic,
  useSaveLogic,
  useThirdPartyReferralLogic,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection/utils';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

const useDisplayLogic = () => {
  const { hasOpenThirdPartyReferral, hasHeReferral, openHeReferral } =
    useVariationReferrals();
  const { isHeUser } = useCurrentUser();
  const { isOnHoldForThisType: isOnHold } = useOnOffHold({
    holdType: EOnHoldType.Variation,
  });

  const isDaviesUserAndNoHeReferral = !isHeUser && !openHeReferral;
  const isHEUserAndNoHeReferral = isHeUser && !openHeReferral;
  const isHeUserAndHasHeReferral = isHeUser && hasHeReferral;

  const showReferToHeButton = isDaviesUserAndNoHeReferral && !isOnHold;

  const showReferToThirdPartyButton =
    isHeUserAndHasHeReferral && !isOnHold && !hasOpenThirdPartyReferral;

  const showPutOnHoldButton =
    isDaviesUserAndNoHeReferral && !isOnHold
      ? true
      : isHeUserAndHasHeReferral && !isOnHold
      ? true
      : false;

  const showTakeOffHoldButton =
    isDaviesUserAndNoHeReferral && isOnHold
      ? true
      : isHeUserAndHasHeReferral && isOnHold
      ? true
      : false;

  const showRejectButton =
    isHeUserAndHasHeReferral && !isOnHold && !hasOpenThirdPartyReferral
      ? true
      : false;

  const showSaveButton =
    isDaviesUserAndNoHeReferral && !isOnHold
      ? true
      : isHeUserAndHasHeReferral && !isOnHold
      ? true
      : false;

  const showApproveButton =
    (isDaviesUserAndNoHeReferral || isHEUserAndNoHeReferral) && !isOnHold
      ? true
      : isHeUserAndHasHeReferral && !isOnHold && !hasOpenThirdPartyReferral
      ? true
      : false;

  return {
    showReferToHeButton,
    showReferToThirdPartyButton,
    showPutOnHoldButton,
    showTakeOffHoldButton,
    showRejectButton,
    showSaveButton,
    showApproveButton,
    areAnyButtonsShowing:
      showReferToHeButton ||
      showReferToThirdPartyButton ||
      showPutOnHoldButton ||
      showTakeOffHoldButton ||
      showRejectButton ||
      showSaveButton ||
      showApproveButton,
  };
};

export const ActionButtons = ({
  handleSave,
  saveStatus,
}: DaviesUserButtonsProps) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );


  const displayLogic = useDisplayLogic();
  const canFormBeApproved = useCanFormBeApproved();
  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Variation,
  });
  const thirdPartyReferral = useThirdPartyReferralLogic();
  const homesEnglandReferral = useHomesEnglandReferralLogic();
  const saveLogic = useSaveLogic(handleSave);
  const rejectLogic = useRejectLogic();

  if (!displayLogic.areAnyButtonsShowing) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          bgcolor="grey.100"
        >
          {displayLogic.showReferToThirdPartyButton ? (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={thirdPartyReferral.handleReferToThirdPartyClick}
                disabled={saveStatus.isLoading || isOnHoldForThisType}
              >
                Refer
              </Button>

              {thirdPartyReferral.thirdPartyReferralDrawer.renderReferDrawer()}
            </>
          ) : null}

          {displayLogic.showReferToHeButton ? (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={homesEnglandReferral.handleReferToHeClick}
                disabled={saveStatus.isLoading || isOnHoldForThisType || !hasAdminApplicationEdit}
              >
                Refer to HE
              </Button>

              {homesEnglandReferral.referToHeModal.renderReferToHomesEnglandModal(
                {
                  handleConfirmClick:
                    homesEnglandReferral.handleReferToHeConfirmClick,
                  referStatus: {
                    isLoading: homesEnglandReferral.referToHeStatus.isLoading,
                    isError: homesEnglandReferral.referToHeStatus.isError,
                    error: homesEnglandReferral.referToHeStatus.error,
                  },
                }
              )}
            </>
          ) : null}

          <CostScheduleCSVButton />

          {(displayLogic.showPutOnHoldButton ||
          displayLogic.showTakeOffHoldButton) && hasAdminApplicationEdit ? (
            <OnOffHold holdType={EOnHoldType.Variation} />
          ) : null}
          <Box gap={2} display="flex">
            {displayLogic.showRejectButton ? (
              <>
                <LoadingButton
                  variant="outlined"
                  size="small"
                  onClick={rejectLogic.handleRejectClick}
                  loading={saveStatus.isLoading}
                  disabled={isOnHoldForThisType || !hasAdminApplicationEdit}
                >
                  Reject
                </LoadingButton>

                {rejectLogic.rejectModal.renderRejectModal()}
              </>
            ) : null}

            {displayLogic.showSaveButton ? (
              <LoadingButton
                variant="outlined"
                size="small"
                onClick={saveLogic.handleSaveClick}
                loading={saveStatus.isLoading}
                disabled={isOnHoldForThisType || !hasAdminApplicationEdit}
              >
                Save
              </LoadingButton>
            ) : null}

            {displayLogic.showApproveButton ? (
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                form={_formId}
                disabled={!canFormBeApproved || isOnHoldForThisType || !hasAdminApplicationEdit}
                loading={saveStatus.isLoading}
              >
                Approve Variation
              </LoadingButton>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
