import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditUkBankAccountDetailsMutation,
  useLazyGetUkBankAccountDetailsQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zUkBankAccountDetailsSchema } from 'types/applications/ApplicationEdit.zod';
import { IUkBankAccountDetails } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IUkBankAccountDetails) =>
  nameof<IUkBankAccountDetails>(fieldName);

interface IEditUkBankAccountDetailsDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditUkBankAccountDetailsDrawer({
  onClose,
  onSuccess,
}: IEditUkBankAccountDetailsDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            Edit UK Bank Account Details
          </Typography>
        </DialogTitle>
        <EditUkBankAccountDetailsForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditUkBankAccountDetailsFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditUkBankAccountDetailsForm = ({
  onClose,
  onSuccess,
}: IEditUkBankAccountDetailsFormProps) => {
  const { applicationId } = useApplicationContext();

  const [getUkBankAccountDetails] = useLazyGetUkBankAccountDetailsQuery();
  const [editUkBankAccountDetails, editUkBankAccountDetailsResult] =
    useEditUkBankAccountDetailsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [ukBankAccountDetails, setUkBankAccountDetails] =
    useState<IUkBankAccountDetails>();
  const [formLoading, setFormLoading] = useState(true);

  const form = useForm<IUkBankAccountDetails>({
    defaultValues: ukBankAccountDetails,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zUkBankAccountDetailsSchema),
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(ukBankAccountDetails);
  }, [ukBankAccountDetails, reset]);

  useEffect(() => {
    if (formLoading) {
      getUkBankAccountDetails(applicationId).then(result => {
        if (result && result.data) {
          setUkBankAccountDetails(result.data);
          setFormLoading(false);
        }
      });
    } else {
      setFormLoading(false);
    }
  }, [applicationId, formLoading, getUkBankAccountDetails, reset]);

  const onSubmit = async (formData: IUkBankAccountDetails) => {
    try {
      formData.applicationId = applicationId;
      await editUkBankAccountDetails(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`UK bank account details updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit UK bank account details`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('accountName')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Name on the account"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('bankName')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Bank name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('branchName')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Branch name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('accountNumber')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Account number"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('sortCode')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Sort code"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editUkBankAccountDetailsResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
