import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add, parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditProjectMilestonesMutation,
  useGetProjectMilestonesQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IProjectMilestones } from 'types/applications/ApplicationEditTypes';
import { getDateOnlyIsoString } from 'util/DateUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IProjectMilestones) =>
  nameof<IProjectMilestones>(fieldName);

interface IEditProjectMilestonesDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditProjectMilestonesDrawer({
  onClose,
  onSuccess,
}: IEditProjectMilestonesDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            Edit Project Milestones
          </Typography>
        </DialogTitle>

        <EditProjectMilestonesForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditProjectMilestonesFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditProjectMilestonesForm = ({
  onClose,
  onSuccess,
}: IEditProjectMilestonesFormProps) => {
  const { applicationId } = useApplicationContext();
  const { data: projectMilestones, isLoading: formLoading } =
    useGetProjectMilestonesQuery(applicationId);
  const [editProjectMilestones, editProjectMilestonesResult] =
    useEditProjectMilestonesMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IProjectMilestones>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    const initialFormData = { ...projectMilestones, changeReason: '' };
    reset(initialFormData);
  }, [projectMilestones, reset]);

  const onSubmit = async (formData: IProjectMilestones) => {
    try {
      formData.applicationId = applicationId;

      await editProjectMilestones(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Project milestones updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit project milestones`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('startOnSiteDate')}
                  render={({
                    field: { onChange, value, ...field },
                    formState: { errors },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        format="dd/MM/yyyy"
                        maxDate={add(new Date(), { days: -1 })}
                        label="Start on site date"
                        value={parseISO(value)}
                        onChange={val =>
                          void onChange(val ? getDateOnlyIsoString(val) : val)
                        }
                        slotProps={{
                          textField: {
                            sx: { mr: '1rem' },
                            error: !!errors.startOnSiteDate,
                            helperText: errors.startOnSiteDate?.message,
                            fullWidth: true,
                          },
                          field: { clearable: true },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('practicalCompletionDate')}
                  render={({
                    field: { onChange, value, ...field },
                    formState: { errors },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        format="dd/MM/yyyy"
                        maxDate={add(new Date(), { days: -1 })}
                        label="Practical completion date"
                        value={parseISO(value)}
                        onChange={val =>
                          void onChange(val ? getDateOnlyIsoString(val) : val)
                        }
                        slotProps={{
                          textField: {
                            sx: { mr: '1rem' },
                            error: !!errors.practicalCompletionDate,
                            helperText: errors.practicalCompletionDate?.message,
                            fullWidth: true,
                          },
                          field: { clearable: true },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editProjectMilestonesResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
