import { Chip, Stack } from '@mui/material';
import { useGetBuildingDetailsQuery } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { IGetWorksPackageBuildingDetails } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { SummaryField } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { getGbpAmount } from 'util/AppUtils';

export const BuildingDetailsSection = () => {
  const { applicationId } = useWorksPackageContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetBuildingDetailsQuery(applicationId);

  return (
    <RoundBorderBox sx={{ p: 2, pt: 3 }}>
      <CardTitle title="Building details" sx={{ mb: 2 }} />
      {isLoading ? <FormSkeleton /> : null}
      {isError ? (
        <AlertForQueryErrorOrNull isError={isError} error={error} />
      ) : null}
      {isSuccess ? <Data data={data} /> : null}
    </RoundBorderBox>
  );
};

const Data = ({ data }: { data: IGetWorksPackageBuildingDetails }) => {
  return (
    <Stack gap={3}>
      <SummaryField
        label="Total number of units in building"
        value={`${data.totalUnitCount}`}
      />

      <SummaryField
        label="Total number of eligible units"
        value={`${data?.eligibleUnitCount}`}
      />

      <SummaryField
        label="Work package cost"
        value={getGbpAmount({ value: data.workPackageCosts })}
      />

      <SummaryField
        label="Third party contributions"
        value={getGbpAmount({ value: data.thirdPartyContribution })}
      />

      <SummaryField
        label="Percentage of costs"
        value={`${data.percentageOfCosts}%`}
      />

      <SummaryField
        label="Recommended to pay"
        value={getGbpAmount({ value: data.recommendedPayAmount })}
      />

      <SummaryField
        label="Applied under hardship"
        value={
          <Chip
            size="small"
            variant="filled"
            label={data.appliedUnderHardship ? 'Yes' : 'No'}
            sx={{ fontSize: '0.9em' }}
          />
        }
        showDivider={false}
      />
    </Stack>
  );
};
