import { ETeamRole } from 'enums/ETeamRole';
import { ICostsSchedule } from 'types/applications/ApplicationCostScheduleTypes';
import { IReferToThirdPartyForm } from 'types/applications/ReferralTypes';

export enum ECladdingRemediationType {
  Full = 1,
  Partial = 2,
  None = 3,
}

export const EReplacementCladdingTypeLanguage: {
  [key in ECladdingRemediationType]: string;
} = {
  [ECladdingRemediationType.Full]: 'Full',
  [ECladdingRemediationType.Partial]: 'Partial',
  [ECladdingRemediationType.None]: 'None',
} as const;

export enum ERiskSafetyMitigationType {
  CompleteCladdingReplacement = 1,
  PartialCladdingReplacement = 2,
  EncapsulationOfCombustibles = 3,
  FillingCavaties = 4,
  WorksToFireBarriers = 5,
  InstallationOfFireAndSmokeAlarms = 6,
  WorksToImproveAccess = 7,
  WorksToMeansOfEscape = 8,
  WorksToSmokeExtraction = 9,
  WorksToInternalCompartmentation = 10,
  DontKnow = 11,
  Other = 12,
}

export const ERiskSafetyMitigationTypeLanguage: {
  [key in ERiskSafetyMitigationType]: string;
} = {
  [ERiskSafetyMitigationType.CompleteCladdingReplacement]:
    'Complete removal and replacement of the unique cladding system or systems',
  [ERiskSafetyMitigationType.PartialCladdingReplacement]:
    'Partial removal and replacement of the unique cladding system or systems',
  [ERiskSafetyMitigationType.EncapsulationOfCombustibles]:
    'Encapsulation of the combustible materials present in the cladding system',
  [ERiskSafetyMitigationType.FillingCavaties]:
    'Filling cavities with non-combustible insulation',
  [ERiskSafetyMitigationType.WorksToFireBarriers]: 'Works to Fire Barriers',
  [ERiskSafetyMitigationType.InstallationOfFireAndSmokeAlarms]:
    'Installation or upgrades to fire alarms and or smoke detection',
  [ERiskSafetyMitigationType.WorksToImproveAccess]:
    'Works to improve access and facilities for the fire service',
  [ERiskSafetyMitigationType.WorksToMeansOfEscape]: 'Works to means of escape',
  [ERiskSafetyMitigationType.WorksToSmokeExtraction]:
    'Works to smoke extraction',
  [ERiskSafetyMitigationType.WorksToInternalCompartmentation]:
    'Works to internal compartmentation',
  [ERiskSafetyMitigationType.DontKnow]: `Don't know`,
  [ERiskSafetyMitigationType.Other]: 'Other',
} as const;

interface SafetyRiskMitigationAction {
  safetyRiskMitigationAction: ERiskSafetyMitigationType;
}

export interface IGetCladdingSectionResponse {
  replacementType: ECladdingRemediationType;
  safetyRiskMitigationActions: SafetyRiskMitigationAction[];
  safetyRiskOtherText?: string;
  claddingSystemReplacementDetails: ICladdingSystem[];
}

export interface ICladdingSystem {
  workPackageCostsScheduleCladdingSystemsId: string;
  remediationType: ECladdingRemediationType;
  currentCladdingSystemType: string;
  currentCladdingManufacturer: string;
  currentInsulationType: string;
  currentInsulationManufacturer: string;
  replacingCladdingSystemType: string;
  replacingCladdingManufacturer: string;
  replacingInsulationType: string;
  replacingInsulationManufacturer: string;
  isReplacementCladdingAcm: boolean;
}

export enum ETaskStatus {
  CannotStartYet = 0,
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
}

export const ETaskStatusLanguage: {
  [key in ETaskStatus]: string;
} = {
  [ETaskStatus.CannotStartYet]: 'Cannot Start',
  [ETaskStatus.NotStarted]: 'Not Started',
  [ETaskStatus.InProgress]: 'In Progress',
  [ETaskStatus.Completed]: 'Completed',
} as const;

export interface IWorksPackageOverview {
  worksPackageCost: number;
  benchmark: number;
  varianceTolerance: number;
  applicationWorkPackageApprovalStatus?: EWorksPackageApprovalStatus;
  approvalRejectionReason?: string;
  approvalRejectionDate?: string;
  approvalRejectionUser?: string;
  isDutyOfCareComplete: boolean;
  dateSubmitted?: string;
  status?: EWorksPackageStatus;
  grantCertifyingOfficer: {
    name: string;
    companyName: string;
  };
  dutyOfCareDeed: {
    name: string;
    companyName: string;
    dateSentToGrantCertifyingOfficer?: Date;
    dateSignedByGrantCertifyingOfficer?: Date;
    status: ETaskStatus;
  };
  buildingDetails: {
    buildingHeightMetres: number;
    responsibleEntityType: string;
    representationType?: string;
    replacementType: string;
  };
  buildingControl: {
    buildingControlRequired?: boolean;
    buildingControlForecastSubmissionDate?: Date;
    buildingControlActualSubmissionDate?: Date;
    buildingControlValidationDate?: Date;
    buildingControlDecisionDate?: Date;
    buildingControlDecision?: boolean;
  };
  thirdPartyContributions: {
    pursuingThirdPartyContribution?: boolean;
    contributionTypes: Array<string>;
    contributionAmount?: number;
    contributionNotes: string;
  };
  keyDates: {
    startDate?: Date;
    unsafeCladdingRemovalDate?: Date;
    expectedDateForCompletion?: Date;
  };
  planningPermission: {
    requirePlanningPermission: boolean;
    reasonPlanningPermissionNotRequired: string;
  };
  competitiveBids: {
    competitiveBids: boolean;
    reasonCompetitiveBidsNotRequired: string;
  };
}

export interface IGrantCertifyingOfficerAuthorisedSignatories {
  authorisedSignatory1: string;
  authorisedSignatory1EmailAddress: string;
  companiesDateOfAppointment?: Date;
}

export interface IRegulatoryCompliance {
  regulatoryCompliancePerson: string,
  role: string
}

export interface ISubContractor {
  id: string;
  companyName: string;
  companyRegistration: string;
}

export interface IProjectTeam {
  id: string;
  name: string;
  role: string;
  teamRole: ETeamRole;
  companyName: string;
  companyRegistration: string;
  primaryContactNumber: string;
  contractSigned: boolean;
  emailAddress: string;
  appropriateLevelsOfProfessionalIndemnity: boolean;
  fireRiskSafetyEngineerInvolved: boolean;
  isGrantCertifyingOfficer: boolean;
  hasChasCertification?: boolean;
  isNew: boolean;
}

export interface IGetCostsScheduleResponse {
  isAvailable: boolean;
  currentVersion?: number;
  isDraft?: boolean;
  current: ICostsSchedule;
  previous?: ICostsSchedule;
}

export enum EWorksPackageApprovalStatus {
  Approved = 1,
  Rejected = 2,
}

export const EWorkPackageApprovalStatusLanguage: {
  [key in EWorksPackageApprovalStatus]: string;
} = {
  [EWorksPackageApprovalStatus.Approved]: 'Approved',
  [EWorksPackageApprovalStatus.Rejected]: 'Rejected',
} as const;

export enum EWorksPackageStatus {
  InProgress = 1,
  Submitted = 2,
  Approved = 3,
  Rejected = 4,
}

export const EWorksPackageStatusLanguage: {
  [key in EWorksPackageStatus]: string;
} = {
  [EWorksPackageStatus.InProgress]: 'In Progress',
  [EWorksPackageStatus.Submitted]: 'Submitted',
  [EWorksPackageStatus.Approved]: 'Approved',
  [EWorksPackageStatus.Rejected]: 'Rejected',
} as const;

export type IApproveWorksPackageParams = {
  applicationId: string;
} & (
  | {
      status: EWorksPackageApprovalStatus.Approved;
    }
  | {
      status: EWorksPackageApprovalStatus.Rejected;
      reason: string;
    }
);

export enum EWorkPackageChecksAnswer {
  Yes = 1,
  No = 2,
  NA = 3,
}

export interface IWorksPackageChecksForm {
  varianceToBenchmarkAcceptable: boolean;
  varianceToBenchmarkAcceptableComments: string;
  worksInlineWithFraew: boolean;
  worksInlineWithFraewComments: string;
  standardCostsBreakdown: boolean;
  standardCostsBreakdownComments: string;
  acmCladdingBeingRemoved: EWorkPackageChecksAnswer;
  acmCladdingBeingRemovedComments: string;
  competitiveBidsReasonAcceptable: EWorkPackageChecksAnswer;
  competitiveBidsReasonAcceptableComments: string;
  projectTeamMembersInvolvedInOriginalCladding: boolean;
  projectTeamMembersInvolvedInOriginalCladdingComments: string;
  grantPercentage: number;
};

export interface ISaveWorksPackageChecksParams {
  applicationId: string;
  formData: Partial<Omit<IWorksPackageChecksForm, 'grantPercentage'>>;
}

export interface ISaveGrantPercentageParams {
  applicationId: string;
  formData: Pick<IWorksPackageChecksForm, 'grantPercentage'>;
}

export interface IReferWorksPackageToHomesEnglandParams {
  applicationId: string;
  referralComments: string;
}

export interface IReferToThirdPartyMutationParams {
  applicationId: string;
  formData: IReferToThirdPartyForm;
}

export interface IUpdateThirdPartyReferralMutationParams
  extends IReferToThirdPartyMutationParams {
  thirdPartyReferralId: string;
}

export interface IGenericOption {
  id: number;
  name: string;
}

export interface IGetCladdingSystemsResult {
  claddingSystems: IGenericOption[];
}

export interface IGetCladdingManufacturersResult {
  claddingManufacturers: IGenericOption[];
}

export interface IGetCladdingInsulationTypesResult {
  insulationTypes: IGenericOption[];
}

export interface EditCladdingForm {
  replacementCladdingSystemTypeId: string;
  replacementCladdingManufacturerId: string;
  replacementInsulationTypeId: string;
  replacementInsulationManufacturerId: string;
}

export type UpdateCladdingParams = {
  applicationId: string;
  fireRiskCladdingSystemId: string;
} & EditCladdingForm;

export interface IGetWorksPackageBuildingDetails {
  totalUnitCount: number;
  eligibleUnitCount: number;
  percentageOfCosts: number;
  recommendedPayAmount: number;
  workPackageCosts: number;
  thirdPartyContribution: number;
  appliedUnderHardship: boolean;
  responsibleEntityOrgansiationType: EResponsibleEntityOrganisationType;
}

export enum EResponsibleEntityOrganisationType {
  PrivateCompany = 1,
  RightToManageCompany = 2,
  ResidentLedOrganisation = 3,
  RegisteredProvider = 4,
  LocalAuthority = 5,
  Other = 6,
}
