import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useCurrentUserPermissions } from 'hooks';

interface INoSignatoryProps {
  showAddSignatoryOnClick?: () => void;
}

export function NoSignatoriesComponent({
  showAddSignatoryOnClick,
}: INoSignatoryProps) {
  const theme = useTheme();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={2}
      pl={0}
      borderColor={theme.palette.divider}
    >
      <Box padding="5px">
        <Typography variant="h1" component="span">
          There are no signatories available for this application
        </Typography>
      </Box>
      <Box padding="5px">
        <Button
          variant="contained"
          onClick={showAddSignatoryOnClick}
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          disabled={!hasAdminApplicationEdit}
        >
          Add signatory
        </Button>
      </Box>
    </Grid>
  );
}
