import { EAccessRestriction } from 'enums/EAccessRestriction';
import { EBuildingInterimMeasures } from 'enums/EBuildingInterimMeasures';
import { EDeveloperInBusinessType } from 'enums/EDeveloperInBusinessType';
import { EEvacuationStrategyType } from 'enums/EEvacuationStrategyType';
import { EFundingStillPursuing } from 'enums/EFundingStillPursuing';
import { EIsInInterimMeasures } from 'enums/EIsInInterimMeasures';
import { EPursuedSourcesFundingType } from 'enums/EPursuedSourcesFundingType';
import { ERiskSafetyMitigationType } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  GetApplicationQuestionHistoryResponse,
  IAddress,
  IAlternativeFundingRoutes,
  IAnyNonResidentialUnits,
  IBankAccountVerificationContactDetails,
  ICompanyDetails,
  IContactDetails,
  IFraewCladdingSystemType,
  IFraewCurrentEvacuationStrategy,
  IFraewExternalWorks,
  IFraewFireRescueServiceAccessRestrictions,
  IFraewInterimMeasures,
  IFraewInternalWorks,
  IFraewNumberOfStairwells,
  IGetFraewCladdingSystems,
  ILeaseholderEngagement,
  INumberedCladdingSystem,
  IOriginalDeveloper,
  IPartOfDevelopment,
  IProjectMilestones,
  IRecommendedInterimMeasures,
  IRiskSafetyMitigation,
  IUkBankAccountDetails,
} from 'types/applications/ApplicationEditTypes';

export const applicationQuestionHistory: GetApplicationQuestionHistoryResponse =
  [
    {
      fieldName: 'Account Number',
      isFile: false,
      oldValue: '12345678',
      newValue: '87654321',
      dateChanged: '2024-02-01 10:00:00',
      changedByUser: 'Joe Bloggs',
      changeReason: 'Incorrect bank account details entered by the applicant',
    },
    {
      fieldName: 'Sort Code',
      isFile: false,
      oldValue: '123456',
      newValue: '654321',
      dateChanged: '2024-02-01 10:00:00',
      changedByUser: 'Joe Bloggs',
      changeReason: 'Incorrect bank account details entered by the applicant',
    },
  ];

export const applicationEditBankAccount: IUkBankAccountDetails = {
  accountName: 'Account Name',
  bankName: 'Bank Name',
  branchName: 'Branch Name',
  accountNumber: 'Account Number',
  sortCode: 'Sort Code',
};

export const applicationEditBankAccountVerificationContact: IBankAccountVerificationContactDetails =
  {
    contactName: 'Joe Bloggs',
    contactNumber: '01234123456',
  };

export const applicationAddress: IAddress = {
  nameNumber: '23',
  addressLine1: 'Mock Street Name',
  addressLine2: '',
  city: 'Oxford',
  county: 'Oxfordshire',
  postcode: 'OX33 1ER',
  changeReason: 'Incorrect address entered by the applicant',
};

export const applicationCompanyDetails: ICompanyDetails = {
  companyName: 'Davies Group Ltd.',
  companyRegistrationNumber: '1234567890',
  changeReason: 'Incorrect company details entered by the applicant',
};

export const applicationContactDetails: IContactDetails = {
  firstName: 'Joe',
  lastName: 'Bloggs',
  emailAddress: 'Joe.Bloggs@email.com',
  contactNumber: '012345678',
  changeReason: 'Incorrect company details entered by the applicant',
};

export const applicationProjectMilestones: IProjectMilestones = {
  startOnSiteDate: new Date('2024-01-01'),
  practicalCompletionDate: new Date('2024-12-31'),
  changeReason: 'Incorrect project milestones entered by the applicant',
};

export const applicationFraewInterimMeasures: IFraewInterimMeasures = {
  interimMeasures: [EBuildingInterimMeasures.Other],
  isInInterimMeasures: EIsInInterimMeasures.Yes,
  otherInterimMeasures: 'Example other interim measures',
};

export const applicationFraewCurrerntEvacuationStrategy: IFraewCurrentEvacuationStrategy =
  {
    evacuationStrategy: EEvacuationStrategyType.Other,
    otherStrategy: 'Example other strategy',
  };

export const applicationFraewNumberOfStairwells: IFraewNumberOfStairwells = {
  numberOfStairwellsKnown: true,
  numberOfStairwells: 14,
};

export const applicationFraewFireRescueServiceAccessRestrictions: IFraewFireRescueServiceAccessRestrictions =
  {
    accessRestrictions: 'Example access restrictions',
    doesHaveAccessRestrictions: EAccessRestriction.Yes,
  };

export const applicationOriginalDeveloper: IOriginalDeveloper = {
  developerCompanyName: 'Test Developer Company Name',
  hasContactedDeveloper: false,
  isOriginalDeveloperKnown: false,
  isStillInBusiness: EDeveloperInBusinessType.Yes,
};

export const applicationAnyNonResidentialUnits: IAnyNonResidentialUnits = {
  anyNonResidentialUnits: true,
  numberOfUnits: 78,
};

export const applicationPartOfDevelopment: IPartOfDevelopment = {
  isPartOfDevelopment: true,
  nameOfDevelopment: 'Test name of development',
};

export const applicationAlternateFundingRoutes: IAlternativeFundingRoutes = {
  pursuedOtherFundingSources: EPursuedSourcesFundingType.PursuingOtherRoutes,
  whichFundingRoutes: [EFundingStillPursuing.SignedUpDevelopersPledge],
};

export const applicationRiskSafetyMitigation: IRiskSafetyMitigation = {
  riskSafetyMitigation: [ERiskSafetyMitigationType.Other],
  otherRiskSafteyMitigation: 'Other type of mitigation',
};

export const applicationRecommendedInterimMeasures: IRecommendedInterimMeasures =
  {
    hasRecommendedMeasures: true,
    recommendedMeasures: [EBuildingInterimMeasures.Other],
    otherRecommendedMeasure: 'Other measure',
  };

export const applicationLeaseholderEngagement: ILeaseholderEngagement = {
  files: [{ id: '1', name: 'File One', extension: 'pdf' }],
};

export const applicationEditFraewInternalWorks: IFraewInternalWorks = {
  hasWorks: false,
  works: [
    {
      id: '1',
      description: 'Internal Element 1',
    },
    {
      id: '2',
      description: 'Internal Element 2',
    },
  ],
};

export const applicationEditFraewExternalWorks: IFraewExternalWorks = {
  hasWorks: false,
  works: [
    {
      id: '1',
      description: 'External Element 1',
    },
    {
      id: '2',
      description: 'External Element 2',
    },
  ],
};

const claddingSystemGenerator = (id: number): INumberedCladdingSystem => {
  return {
    id: `${id}`,
    claddingNumber: id,
    claddingTypeId: id,
    claddingManufacturerId: id,
    insulationManufacturerId: id,
    insulationTypeId: id,
    otherCladdingManufacturer: 'Other details',
    otherCladdingType: 'Other details',
    otherInsulationManufacturer: 'Other details',
    otherInsulationType: 'Other details',
  };
};

export const applicationEditFraewCladdingSystemTypes: IGetFraewCladdingSystems =
  {
    claddingSystems: [claddingSystemGenerator(1)],
  };

export const insulationTypeData: IFraewCladdingSystemType[] = [
  { id: 1, isOther: false, name: 'Insulation Type 1' },
  { id: 2, isOther: true, name: 'Other' },
  { id: 3, isOther: false, name: 'Insulation Type 2' },
];

export const manufacturerData: IFraewCladdingSystemType[] = [
  { id: 1, isOther: false, name: 'Manufacturer 1' },
  { id: 2, isOther: true, name: 'Other' },
  { id: 3, isOther: false, name: 'Manufacturer 2' },
];

export const claddingTypeData: IFraewCladdingSystemType[] = [
  { id: 1, isOther: false, name: 'Cladding Type 1' },
  { id: 2, isOther: true, name: 'Other' },
  { id: 3, isOther: false, name: 'Cladding Type 2' },
];
