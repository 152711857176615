import { rest } from 'msw';
import {
  taskListResponseAdmin,
  noteData,
  notesData,
  taskOutcomesResponse,
  taskResponse,
  taskStats,
  taskTypesResponse,
  taskListResponseDefault,
  taskHistory,
} from 'mocks/data/task/taskData';

export const taskHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId/history`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskHistory));
      }
      return req.passthrough();
    }
  ),

  rest.post(`${process.env.REACT_APP_API_URL}/api/task`, (req, res, ctx) => {
    if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
      return res(ctx.status(200));
    }
    return req.passthrough();
  }),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.post(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId/subtask`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId/subtask`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(notesData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId/subtask/:subTaskId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(noteData));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId/subtask/:subTaskId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/type`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskTypesResponse));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/outcome`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskOutcomesResponse));
      }
      return req.passthrough();
    }
  ),

  rest.get(`${process.env.REACT_APP_API_URL}/api/task`, (req, res, ctx) => {
    if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
      return res(ctx.status(200), ctx.json(taskListResponseAdmin));
    }
    return req.passthrough();
  }),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/profile/task`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskListResponseDefault));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/task`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskListResponseDefault));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/stats`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskStats));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/task/:taskId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(taskResponse));
      }
      return req.passthrough();
    }
  ),
];
