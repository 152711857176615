import { applicationApi } from 'api/application/applicationApi';
import {
  IApproveWorksPackageParams,
  IGetCladdingInsulationTypesResult,
  IGetCladdingManufacturersResult,
  IGetCladdingSectionResponse,
  IGetCladdingSystemsResult,
  IGetCostsScheduleResponse,
  IGetWorksPackageBuildingDetails,
  IProjectTeam,
  IReferWorksPackageToHomesEnglandParams,
  ISaveGrantPercentageParams,
  ISaveWorksPackageChecksParams,
  ISubContractor,
  IWorksPackageOverview,
  UpdateCladdingParams,
  IWorksPackageChecksForm,
  IGrantCertifyingOfficerAuthorisedSignatories,
  IRegulatoryCompliance,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  IIneligibleForm,
  IInstallationForm,
  IOtherForm,
  IPreliminariesForm,
  IRemovalForm,
  UpdateWorksPackageValuesParams,
} from 'types/applications/ApplicationCostScheduleTypes';
import { QueryTags } from 'util/ApiUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';

export const worksPackageEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getWorksPackageOverview: builder.query<IWorksPackageOverview, string>({
      providesTags: [QueryTags.ApplicationWorksPackageOverview],
      query: applicationId => `${applicationId}/workpackage/overview`,
      keepUnusedDataFor: 0,
    }),
    getGrantCertifyingOfficerAuthorisedSignatories: builder.query<
      IGrantCertifyingOfficerAuthorisedSignatories,
      string
    >({
      query: applicationId =>
        `${applicationId}/workpackage/authorisedSignatories`,
    }),
    getRegulatoryCompliance: builder.query<IRegulatoryCompliance, string>({
      query: applicationId =>
        `${applicationId}/workpackage/regulatoryCompliance`,
    }),
    getSubContractor: builder.query<ISubContractor[], string>({
      providesTags: [QueryTags.ApplicationWorksPackageOverview],
      query: applicationId => `${applicationId}/workpackage/subcontractor`,
    }),
    getProjectTeam: builder.query<IProjectTeam[], string>({
      providesTags: [QueryTags.ApplicationWorksPackageOverview],
      query: applicationId => `${applicationId}/workpackage/projectteam`,
    }),
    getWorksPackageCladding: builder.query<IGetCladdingSectionResponse, string>(
      {
        providesTags: [QueryTags.ApplicationWorksPackageCladding],
        query: applicationId => `${applicationId}/workpackage/cladding`,
      }
    ),
    getWorksPackageCostsSchedule: builder.query<
      IGetCostsScheduleResponse,
      string
    >({
      providesTags: [QueryTags.ApplicationWorksPackageCostsSchedule],
      query: applicationId => `${applicationId}/workpackage/costscheduling`,
    }),

    saveUnsafeCladdingRemovalValues: builder.mutation<
      void,
      UpdateWorksPackageValuesParams<IRemovalForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/costSchedule/unsafeCladdingRemoval`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveInstallationValues: builder.mutation<
      void,
      UpdateWorksPackageValuesParams<IInstallationForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/costSchedule/newCladdingInstallation`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    savePreliminariesValues: builder.mutation<
      void,
      UpdateWorksPackageValuesParams<IPreliminariesForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/costSchedule/preliminariesAndOverheads`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveOtherValues: builder.mutation<
      void,
      UpdateWorksPackageValuesParams<IOtherForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/costSchedule/otherCosts`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveIneligibleValues: builder.mutation<
      void,
      UpdateWorksPackageValuesParams<IIneligibleForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/costSchedule/ineligibleCosts`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    approveOrReject: builder.mutation<void, IApproveWorksPackageParams>({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageBuildingDetails,
        QueryTags.ApplicationWorksPackageCostsSchedule,
        QueryTags.ApplicationWorksPackageOverview,
        QueryTags.ApplicationWorksPackageChecks,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, ...params }) => {
        return {
          url: `${applicationId}/workpackage/approvalRejection`,
          method: 'PUT',
          body: params,
        };
      },
    }),

    saveChecksFields: builder.mutation<void, ISaveWorksPackageChecksParams>({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageChecks,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/checks`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveGrantPercentage: builder.mutation<void, ISaveGrantPercentageParams>({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageChecks,
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationWorksPackageBuildingDetails,
      ],
      query: ({ formData, applicationId }) => {
        return {
          url: `${applicationId}/workpackage/grant`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    getChecks: builder.query<Partial<IWorksPackageChecksForm>, string>({
      providesTags: [QueryTags.ApplicationWorksPackageChecks],
      query: applicationId => `${applicationId}/workpackage/checks`,
    }),

    referToHomesEngland: builder.mutation<
      void,
      IReferWorksPackageToHomesEnglandParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageReferralList,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, ...data }) => {
        return {
          url: `${applicationId}/workpackage/referToHomesEngland`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    getCladdingSystemTypes: builder.query<IGetCladdingSystemsResult, void>({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/cladding/claddingsystems`,
    }),

    getManufacturers: builder.query<IGetCladdingManufacturersResult, void>({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/cladding/manufacturers`,
    }),

    getInsulationtypes: builder.query<IGetCladdingInsulationTypesResult, void>({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/cladding/insulationtypes`,
    }),

    updateCladding: builder.mutation<void, UpdateCladdingParams>({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageCladding,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, fireRiskCladdingSystemId, ...formData }) => {
        return {
          url: `${applicationId}/workpackage/fireriskcladdingsystem/${fireRiskCladdingSystemId}`,
          method: 'PUT',
          body: formData,
        };
      },
    }),
    getBuildingDetails: builder.query<IGetWorksPackageBuildingDetails, string>({
      providesTags: [QueryTags.ApplicationWorksPackageBuildingDetails],
      query: applicationId => `${applicationId}/workpackage/buildingDetails`,
    }),
    getWorksPackageCostsScheduleCsv: builder.query<
      null,
      { applicationId: string; fileName: string }
    >({
      queryFn: async (
        { applicationId, fileName },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `${applicationId}/workpackage/costscheduling/csv`,
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          fileName,
          result.data as Blob
        );
      },
    }),
  }),
});

export const {
  useGetWorksPackageOverviewQuery,
  useGetGrantCertifyingOfficerAuthorisedSignatoriesQuery,
  useGetRegulatoryComplianceQuery,
  useGetWorksPackageCladdingQuery,
  useGetWorksPackageCostsScheduleQuery,
  useGetSubContractorQuery,
  useGetProjectTeamQuery,
  useSaveUnsafeCladdingRemovalValuesMutation,
  useSaveInstallationValuesMutation,
  useSavePreliminariesValuesMutation,
  useSaveOtherValuesMutation,
  useSaveIneligibleValuesMutation,
  useApproveOrRejectMutation,
  useSaveChecksFieldsMutation,
  useGetChecksQuery,
  useReferToHomesEnglandMutation,
  useGetCladdingSystemTypesQuery,
  useGetManufacturersQuery,
  useGetInsulationtypesQuery,
  useUpdateCladdingMutation,
  useGetBuildingDetailsQuery,
  useSaveGrantPercentageMutation,
  useLazyGetWorksPackageCostsScheduleCsvQuery,
} = worksPackageEndpoints;
