import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetPermissionsQuery } from 'api/administration/permissions';
import {
  useAddRoleMutation,
  useEditRoleMutation,
  useLazyGetRoleQuery,
} from 'api/administration/roles';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { EPermissionGroupLanguage } from 'enums/EPermissionGroup';
import { useLocalSnackbar } from 'hooks';
import { EAdministrationTabType } from 'pages/administrationPage/types/EAdministrationTabType';
import { IPermission } from 'types/administration/AdministrationPermissionsTypes';
import { IAddEditRole } from 'types/administration/AdministrationRolesTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddEditRole) =>
  nameof<IAddEditRole>(fieldName);

export const AddEditRolePage = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();

  const [getPermissions] = useLazyGetPermissionsQuery();
  const [getRole] = useLazyGetRoleQuery();
  const [addRole] = useAddRoleMutation();
  const [editRole] = useEditRoleMutation();
  const [role, setRole] = useState<IAddEditRole>();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAddEditRole>({
    defaultValues: {
      permissions: new Array<IPermission>(),
    },
  });
  const { handleSubmit, control, reset } = form;

  const rolePermissions = useFieldArray({
    control,
    name: 'permissions',
  }).fields;

  useEffect(() => {
    getPermissions().then(data => {
      const role: IAddEditRole = {
        name: '',
        paymentApprovalLevel: 0,
        permissions: data?.data ?? new Array<IPermission>(),
      };

      setRole(role);

      reset(role);

      if (roleId) {
        getRole(roleId).then(result => {
          if (result && result.data) {
            form.setValue(`name`, result.data.name);
            form.setValue(
              `paymentApprovalLevel`,
              result.data.paymentApprovalLevel
            );

            result?.data.permissions.forEach((permission: IPermission) => {
              const index = role.permissions.findIndex(
                rolePermission => rolePermission.name === permission.name
              );
              if (permission.granted) {
                form.setValue(`permissions.${index}.granted`, true);
              } else {
                form.setValue(`permissions.${index}.granted`, false);
              }
            });
          }
        });
      } else {
        role.permissions.forEach((permission: IPermission) => {
          const index = role.permissions.findIndex(
            rolePermission => rolePermission.name === permission.name
          );
          form.setValue(`permissions.${index}.granted`, false);
        });
      }
    });
  }, [form, getPermissions, getRole, reset, roleId]);

  const onSubmit = async (formData: IAddEditRole) => {
    if (roleId === undefined) {
      try {
        await addRole(formData as IAddEditRole)
          .unwrap()
          .then(payload => {
            navigate('/admin/' + EAdministrationTabType.Roles);
            createSuccessSnackbar(`New Role added`);
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add role`);
      }
    } else
      try {
        formData.id = roleId;
        await editRole(formData as IAddEditRole)
          .unwrap()
          .then(payload => {
            navigate('/admin/' + EAdministrationTabType.Roles);
            createSuccessSnackbar(`Role updated successfully`);
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit role`);
      }
  };

  const GrantAllPermissions = () => {
    role?.permissions.forEach((permission: IPermission) => {
      const index = role?.permissions.findIndex(
        rolePermission => rolePermission.name === permission.name
      );
      form.setValue(`permissions.${index}.granted`, true);
    });
  };

  const BlockAllPermissions = () => {
    role?.permissions.forEach((permission: IPermission) => {
      const index = role?.permissions.findIndex(
        rolePermission => rolePermission.name === permission.name
      );
      form.setValue(`permissions.${index}.granted`, false);
    });
  };

  const hasDecimal = (numberToCheck: number) =>
    numberToCheck - Math.floor(numberToCheck) !== 0;

  return (
    <MainContentContainer>
      <Breadcrumbs
        crumbs={
          [
            { text: 'Admin', url: '/admin' },
            {
              text: roleId ? 'Edit Role' : 'Add Role',
              url: undefined,
            },
          ] as IBreadcrumbItem[]
        }
      />

      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item>
          <IconButton
            sx={{ marginLeft: 0 }}
            aria-label="Back"
            name="Back"
            color="primary"
            onClick={() => {
              navigate('/admin/' + EAdministrationTabType.Roles);
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h1"> {roleId ? 'Edit' : 'Add'} Role</Typography>
        </Grid>
      </Grid>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid container sx={{ paddingTop: '2rem' }} columnSpacing={8}>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h2" mb={3}>
                    Role Name
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('name')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Role Name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" mb={3}>
                    Payment Approval Level
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('paymentApprovalLevel')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Payment Approval Level"
                        helperText={fieldState.error?.message}
                        onChange={e => {
                          if (hasDecimal(Number(e.target.value))) return;
                          return field.onChange(e);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            max: 20000000,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h2" mb={3}>
                Permissions
              </Typography>
              <Grid container mb={1} pr={15} justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => GrantAllPermissions()}
                >
                  Grant All
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => BlockAllPermissions()}
                >
                  Block All
                </Button>
              </Grid>
              {rolePermissions?.map(function (
                rolePermission: IPermission,
                index
              ) {
                return (
                  <div key={index}>
                    {(index === 0 ||
                      rolePermissions[index].permissionGroup !==
                        rolePermissions[index - 1].permissionGroup) && (
                      <Typography variant="h4" fontWeight={700} mb={2}>
                        {
                          EPermissionGroupLanguage[
                            rolePermission.permissionGroup
                          ]
                        }
                      </Typography>
                    )}
                    <Grid container mb={1}>
                      <Grid item xs={9}>
                        {rolePermission.description}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-start"
                      >
                        <Controller
                          name={`permissions.${index}.granted`}
                          render={({ field: { onChange, ...fieldProps } }) => (
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) onChange(val);
                              }}
                              exclusive
                            >
                              <ToggleButton value={true}>Granted</ToggleButton>
                              <ToggleButton value={false}>Blocked</ToggleButton>
                            </ToggleButtonGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Grid>
          </Grid>

          <Divider sx={{ my: 3, mx: -5 }} />

          <Grid
            container
            justifyContent="flex-end"
            sx={{ paddingRight: '2rem' }}
            gap={1}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/admin/' + EAdministrationTabType.Roles);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {roleId ? 'Update' : 'Add'} Role
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </MainContentContainer>
  );
};
