import { ReactNode } from 'react';
import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Stack, SxProps, Typography } from '@mui/material';
import { useGetProjectPrepReportListQuery } from 'api/application/projectPrepApi';
import {
  HandleLoadingOrError,
  LabelText,
  ListRow,
  ListViewBoxContainer,
  ListViewDateStack,
  ListViewIcon,
} from 'common/components/listView';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EProgressReportItemType,
  IProjectPrepReportItem,
} from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import { ProgressReportChip } from 'pages/applicationPage/content/projectPrep/common';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const ListView = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetProjectPrepReportListQuery(applicationId);

  return (
    <>
      <HandleLoadingOrError
        isError={isError}
        isLoading={isLoading}
        error={error}
      />
      {isSuccess ? <ListViewMain data={data.results} /> : null}
    </>
  );
};

const ListViewMain = ({ data }: { data: IProjectPrepReportItem[] }) => {
  return (
    <Stack rowGap={2}>
      {data.map(item => (
        <ListViewCard key={item.id} item={item} />
      ))}
    </Stack>
  );
};

const ListViewCard = ({ item }: { item: IProjectPrepReportItem }) => {
  return item.type === EProgressReportItemType.WorkPackage ? (
    <WorkPackageCard item={item} />
  ) : (
    <DetailedCard item={item} />
  );
};

const WorkPackageCard = ({ item }: { item: IProjectPrepReportItem }) => {
  return (
    <ListViewBox
      itemId={item.id}
      itemType={item.type}
      sx={{ p: 2, bgcolor: 'grey.100' }}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography variant="h2" fontSize={'1.1em'} fontWeight={700} mb={3}>
            Work Package
          </Typography>
          <Typography variant="h3" fontSize={'1em'} fontWeight={600}>
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <Box>
            <Box mb={1} textAlign={'right'}>
              <ProgressReportChip status={item.status} />
            </Box>
            <Box mb={1} textAlign={'left'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <LabelText value={'Submitted'} isBold />
                <ListViewIcon icon={faClock} />
                <LabelText
                  value={
                    item?.submittedDate
                      ? getPrettyDateStringFromJsonDateString(
                          item.submittedDate
                        )
                      : '-'
                  }
                />
              </Stack>
            </Box>
            <Box mb={1} textAlign={'left'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <LabelText value={'Completed'} isBold />
                <ListViewIcon icon={faClock} />
                <LabelText
                  value={
                    item?.completedDate
                      ? getPrettyDateStringFromJsonDateString(
                          item.completedDate
                        )
                      : '-'
                  }
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ListViewBox>
  );
};

const headerFooterDefaultProps: SxProps = {
  py: 0.5,
  px: 3,
  display: 'flex',
  borderColor: 'grey.400',
};

const _labelColor = 'grey.700';

const DetailedCard = ({ item }: { item: IProjectPrepReportItem }) => {
  return (
    <ListViewBox itemId={item.id} itemType={item.type}>
      {item.title || item.dueDate ? (
        <Box
          sx={headerFooterDefaultProps}
          bgcolor={'grey.300'}
          justifyContent={
            !item.title && item.dueDate ? 'flex-end' : 'space-between'
          }
          borderBottom={'1px solid'}
        >
          {item.title ? <Typography>{item.title}</Typography> : null}
        </Box>
      ) : null}

      <Box p={2}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={6}>
            <Typography variant="h2" fontSize={'1.1em'} fontWeight={700} mb={3}>
              {item.subTitle}
            </Typography>
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
            <Box mb={2} textAlign={'right'}>
              <ProgressReportChip status={item.status} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={headerFooterDefaultProps}
        justifyContent={'space-between'}
        borderTop={'1px solid'}
      >
        <Box>
          <ListViewDateStack
            label="Start Date"
            value={item.startDate ? item.startDate : '-'}
          />
        </Box>
        <Box>
          <ListViewDateStack
            label="Submitted Date"
            value={item.submittedDate ? item.submittedDate : '-'}
          />
        </Box>
        <Box>
          <ListViewDateStack
            label="Completed Date"
            value={item.completedDate ? item.completedDate : '-'}
          />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <ListRow>
            {item.lastModifiedDate ? (
              <ListViewDateStack
                label="Last Modified"
                value={item.lastModifiedDate}
              />
            ) : null}
            {item.lastModifiedBy ? (
              <ListRow>
                <ListViewIcon icon={faUser} color={_labelColor} />
                <LabelText value={item.lastModifiedBy} />
              </ListRow>
            ) : null}
          </ListRow>
        </Box>
      </Box>
    </ListViewBox>
  );
};

const ListViewBox = ({
  itemId,
  itemType,
  children,
  sx,
}: {
  itemId: string;
  itemType: EProgressReportItemType;
  children: ReactNode;
  sx?: SxProps;
}) => {
  const { setSelectedItem } = useApplicationProjectPrepContext();

  return (
    <ListViewBoxContainer
      sx={{
        ...sx,
      }}
      onClick={() => {
        switch (itemType) {
          case EProgressReportItemType.WorkPackage:
          case EProgressReportItemType.MonthlyReport:
            // Go to works package section
            setSelectedItem({ id: itemId, type: itemType });
            break;
          case EProgressReportItemType.DutyOfCare:
          case EProgressReportItemType.WorkPackageDeed:
            setSelectedItem({ id: itemId, type: itemType });
            break;

          default:
            break;
        }
      }}
    >
      {children}
    </ListViewBoxContainer>
  );
};
