import { Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useUnlockPaymentRequestMutation } from 'api/application/paymentRequestApi';
import { useLocalSnackbar } from 'hooks';
import { IPaymentRequestExpireForm } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IRejectPaymentRequestModalProps {
  applicationId: string;
  paymentRequestId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function UnlockRequestModal({
  applicationId,
  paymentRequestId,
  onConfirm,
  onCancel,
}: IRejectPaymentRequestModalProps) {
  const [unlockPaymentRequest] = useUnlockPaymentRequestMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<IPaymentRequestExpireForm>();
  const { handleSubmit, reset } = form;

  const handleRejectPaymentRequest = async (
    formData: IPaymentRequestExpireForm
  ) => {
    formData.applicationId = applicationId;
    formData.paymentRequestId = paymentRequestId;

    try {
      await unlockPaymentRequest(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar('Payment Request Unlocked');
          onConfirm();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else {
            createErrorSnackbar('An error occured');
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to unlock payment request`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to unlock this expired payment request?
      </StyledDialogTitle>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleRejectPaymentRequest)}>
          <StyledDialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Yes, Unlock
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
