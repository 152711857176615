import { TasksGrid } from 'common/components/taskGrid';
import {
  AdminTasksDataGridNavigation,
  AdminTasksGridContextProvider,
  useAdminTasksGridContext,
} from 'pages/adminTasksPage';

export const AdminTasksDataGrid = () => {
  return (
    <AdminTasksGridContextProvider>
      <TasksGridMain />
    </AdminTasksGridContextProvider>
  );
};

const TasksGridMain = () => {
  const {
    query,
    state: {
      gridState: { take, skip, ...gridStateProps },
    },
    gridStateChanged,
  } = useAdminTasksGridContext();
  return (
    <TasksGrid
      config={{
        type: 'AdminTasks',
        query,
        navigationComponent: <AdminTasksDataGridNavigation />,
        pagination: {
          gridStateChanged,
          take,
          skip,
        },
        filters: { skip, take, ...gridStateProps },
      }}
    />
  );
};
