import { ComponentProps, ReactNode } from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, SxProps, Typography } from '@mui/material';
import { ListViewBoxContainer } from 'common/components/listView';
import { ELiveProjectItemType } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const headerFooterDefaultProps: SxProps = {
  py: 0.5,
  px: 3,
  display: 'flex',
  borderColor: 'grey.400',
};

export const labelColor = 'grey.700';

export const ListViewBox = ({
  itemId,
  itemType,
  children,
  sx,
}: {
  itemId: string;
  itemType: ELiveProjectItemType;
  children: ReactNode;
  sx?: SxProps;
}) => {
  const { setSelectedItem } = useApplicationLiveProjectContext();

  return (
    <ListViewBoxContainer
      sx={{
        ...sx,
      }}
      onClick={() => {
        switch (itemType) {
          case ELiveProjectItemType.ScheduleOfWorks:
          case ELiveProjectItemType.PaymentRequest:
          case ELiveProjectItemType.Variation:
          case ELiveProjectItemType.ClosingPaymentRequest:
            setSelectedItem({ id: itemId, type: itemType });
            break;
        }
      }}
    >
      {children}
    </ListViewBoxContainer>
  );
};

export const Row = ({ children }: { children: ReactNode }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      {children}
    </Stack>
  );
};

export const DateStack = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <Row>
      <LabelText value={label} isBold   />
      <Icon icon={faClock} color={labelColor} />
      <LabelText
        value={
          value === '-' ? '-' : getPrettyDateStringFromJsonDateString(value)
        }
      />
    </Row>
  );
};

export const LabelText = ({
  value,
  isBold = false,
}: {
  value: string;
  isBold?: boolean;
}) => {
  return (
    <Typography
      variant="body1"
      color={labelColor}
      fontSize={'0.95em'}
      width={'8em'}
      sx={isBold ? { fontWeight: 600 } : null}
    >
      {value}
    </Typography>
  );
};

export const Icon = (props: ComponentProps<typeof FontAwesomeIcon>) => {
  return <FontAwesomeIcon {...props} color={'#999'} />;
};
