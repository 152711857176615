import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, FormHelperText } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { IFile } from 'common/types/File';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';

export const FileRow = ({
  name,
  file,
  onUpload,
  onDelete,
  error = undefined,
}: {
  name: string;
  file: IFile;
  onUpload: (file: File) => void;
  onDelete: (fileId: string) => void;
  error?: FieldError;
}) => {
  return (
    <>
      {file?.id ? (
        <Box display="flex" flexDirection="row">
          <ApplicationFileDownloadWrapper
            fileName={file.name ?? ''}
            fileId={file.id ?? ''}
          />
          <IconButton
            size="small"
            onClick={() => onDelete(file.id)}
            aria-label="Delete File"
            name="Delete File"
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Box>
      ) : (
        <>
          <FileUploadInputSelect
            id={name}
            accept=".pdf"
            inputName={name}
            onFileUpload={onUpload}
            onFileDelete={id => (id ? onDelete(id) : null)}
          />
        </>
      )}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  );
};
