import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditBenchmarkMutation } from 'api/administration/ptfs';
import { useLocalSnackbar } from 'hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  IViewEditBenchmark,
  IPTFSGridListItem,
} from 'types/administration/AdministrationPTFSTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IViewEditBenchmark) =>
  nameof<IViewEditBenchmark>(fieldName);

interface IEditBenchmarkDrawerProps {
  currentBenchmark: IPTFSGridListItem | null;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditBenchmarkDrawer({
  currentBenchmark,
  onClose,
  onSuccess,
}: IEditBenchmarkDrawerProps) {
  const [editBenchmark] = useEditBenchmarkMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [benchmark, setBenchmark] = useState<IViewEditBenchmark>();

  const form = useForm<IViewEditBenchmark>({ defaultValues: benchmark });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(benchmark);
  }, [benchmark, reset]);

  useEffect(() => {
    if (currentBenchmark) {
      setBenchmark(currentBenchmark);
      reset(benchmark);
    }
  }, [benchmark, currentBenchmark, setBenchmark, reset]);

  const onSubmit = async (formData: IViewEditBenchmark) => {
    try {
      await editBenchmark(formData as IViewEditBenchmark)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`New benchmark figure added`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            error.data.propertyErrors.forEach(
              (item: { errorMessage: string }) => {
                setServerSideFormErrors(form, error.data);
                createWarningSnackbar(item.errorMessage);
              }
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to add benchmark figure`);
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            Add benchmark figure
          </Typography>
        </DialogTitle>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <DialogContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name={getName('amount')}
                    render={({ field, fieldState }) => (
                      <TextField
                        type="number"
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Benchmark Figure Amount (£)"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('percentage')}
                    render={({ field, fieldState }) => (
                      <TextField
                        type="number"
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Benchmark Figure Percentage (%)"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('startDate')}
                    render={({
                      field: { onChange, value, ...field },
                      formState: { errors },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          format="dd/MM/yyyy"
                          label="Start Date"
                          value={parseISO(value)}
                          onChange={val =>
                            val ? onChange(val.toJSON()) : null
                          }
                          slotProps={{
                            textField: {
                              sx: { mr: '1rem' },
                              error: !!errors.startDate,
                              helperText: errors.startDate?.message,
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('changeReason')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        fullWidth
                        error={!!fieldState.error}
                        label="Reason for change"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Update benchmark figure
                </Button>
              </Grid>
            </StyledDrawerActions>
          </form>
        </FormProvider>
      </Box>
    </StyledDrawer>
  );
}
