import {
  EVariationStatus,
  EVariationType,
  IGetVariationCostsScheduleResponse,
  IVariationOverview,
  IVariationReferralListItem,
  VariationkChecksForm,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  EReferralStatus,
  EReferralType,
  EThirdPartyReferrerType,
  IThirdPartyReferral,
} from 'types/applications/ReferralTypes';
import { getGuid } from 'util/AppUtils';

export const variationOverviewData: IVariationOverview = {
  status: EVariationStatus.Submitted,
  approvalRejectionDate: '2023-10-20T15:00:00',
  approvalRejectionReason: 'This reason innit',
  approvalRejectionUser: 'Ray Purchase',
  dateSubmitted: '2023-09-01',
  variationNumber: 1,
  variationReason: 'This is a test variation reason',
  variations: [
    {
      type: EVariationType.Scope,
      scopeChangeDescription:
        "We didn't require remaining allocation of budget",
    },
    {
      type: EVariationType.Timescale,
      completionDateCurrent: '2023-08-01',
      completionDateVaried: '2024-11-01',
      projectDurationMonthsVaried: 15,
      projectDurationMonthsVariation: 3,
    },
    {
      type: EVariationType.Cost,
      workPackageTotalCurrent: 1_000_000,
      workPackageVariationAmount: 425_000,
      amendedVariationAmount: 455_000,
      workPackageTotalNew: 1_425_000,
      costVariancePercentage: 4.25,
    },
    {
      type: EVariationType.ThirdPartyContribution,
      pursuingTypes: [
        { id: 1, description: 'A' },
        { id: 2, description: 'B' },
        { id: 3, description: 'C' },
      ],
      amount: 425_000,
      previousAmount: 0,
      notes: 'Pot of gold',
    },
  ],
  worksPackageTotalCurrent: 1_000_000,
  worksPackageTotalNew: 1_425_000,
  worksPackageTotalOriginal: 1_000_000,
  worksPackageVariationAmount: 425_000,
  variationEvidenceFiles: [
    {
      extension: 'pdf',
      id: getGuid(),
      name: 'Cost report',
    },
  ],
};

export const variationCostScheduleData: IGetVariationCostsScheduleResponse = {
  currentVersion: 1,
  original: {
    accessAmount: 35000,
    accessDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    contractorContingenciesAmount: 25000,
    contractorContingenciesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    feasibilityStageAmount: 10000,
    feasibilityStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    installationTotal: 220000,
    irrecoverableVatAmount: 20000,
    irrecoverableVatDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorOverheadAmount: 50000,
    mainContractorOverheadDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorPreliminariesAmount: 10000,
    mainContractorPreliminariesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    otherCostsTotal: 440000,
    overallTotal: 750000,
    postTenderStageAmount: 20000,
    postTenderStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    preliminariesTotal: 150000,
    propertyManagerAmount: 10000,
    propertyManagerDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingTotal: 350000,
    externalWorksAmount: 100000,
    externalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    ineligibleAmount: 50000,
    ineligibleDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    internalWorksAmount: 35000,
    internalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    newCladdingAmount: 46000,
    newCladdingDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingRemovalAmount: 98000,
    unsafeCladdingRemovalDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  },
  variation: {
    accessAmount: 37000,
    accessDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    contractorContingenciesAmount: 35000,
    contractorContingenciesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    feasibilityStageAmount: 20000,
    feasibilityStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    installationTotal: 240000,
    irrecoverableVatAmount: 20000,
    irrecoverableVatDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorOverheadAmount: 50000,
    mainContractorOverheadDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorPreliminariesAmount: 10000,
    mainContractorPreliminariesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    otherCostsTotal: 450000,
    overallTotal: 760000,
    postTenderStageAmount: 200000,
    postTenderStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    preliminariesTotal: 150000,
    propertyManagerAmount: 10000,
    propertyManagerDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingTotal: 350000,
    externalWorksAmount: 100000,
    externalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    ineligibleAmount: 50000,
    ineligibleDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    internalWorksAmount: 35000,
    internalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    newCladdingAmount: 46000,
    newCladdingDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingRemovalAmount: 99000,
    unsafeCladdingRemovalDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  },
  amended: {
    accessAmount: 39000,
    accessDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    contractorContingenciesAmount: 35000,
    contractorContingenciesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    feasibilityStageAmount: 20000,
    feasibilityStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    installationTotal: 240000,
    irrecoverableVatAmount: 20000,
    irrecoverableVatDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorOverheadAmount: 50000,
    mainContractorOverheadDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorPreliminariesAmount: 10000,
    mainContractorPreliminariesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    otherCostsTotal: 490000,
    overallTotal: 760000,
    postTenderStageAmount: 200000,
    postTenderStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    preliminariesTotal: 150000,
    propertyManagerAmount: 10000,
    propertyManagerDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingTotal: 350000,
    externalWorksAmount: 100000,
    externalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    ineligibleAmount: 55000,
    ineligibleDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    internalWorksAmount: 35000,
    internalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    newCladdingAmount: 41000,
    newCladdingDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingRemovalAmount: 150000,
    unsafeCladdingRemovalDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  },
};

export const variationChecksData: Partial<VariationkChecksForm> = {
  variationEvidenceReasonable: true,
  variationEvidenceReasonableComments: 'some comments here',
};

export const variationReferralsData: IVariationReferralListItem[] = [
  {
    id: getGuid(),
    referredBy: 'Steven Toast',
    referredDateTime: '2023-10-21T10:05:01.000Z',
    type: EReferralType.HomesEngland,
    status: EReferralStatus.InProgress,
  },
  {
    id: getGuid(),
    referredBy: 'Steven Toast',
    referredDateTime: '2023-10-21T10:05:01.000Z',
    type: EReferralType.ThirdParty,
    status: EReferralStatus.InProgress,
    companyName: 'SOHO Studios',
    cost: 1_234_567,
    startDate: '2023-10-01T10:00:00',
    endDate: '2023-10-30T10:00:00',
    thirdPartyReferrerType: EThirdPartyReferrerType.CostConsultant,
  },
];

export const singleReferral: IThirdPartyReferral = {
  id: getGuid(),
  status: EReferralStatus.InProgress,
  companyEmail: 'email@company1.com',
  companyName: 'Company 1',
  companyPhone: '071717171718',
  cost: 123000,
  createdByUser: 'Steven Toast',
  dateCreated: '2023-09-15',
  description:
    'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  endDate: '2023-09-01',
  startDate: '2023-08-01',
  thirdPartyType: EThirdPartyReferrerType.CostConsultant,
};
