import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, Grid, Stack } from '@mui/material';
import { ListViewIcon } from 'common/components/listView';
import { ILiveProjectReportItem } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { ProgressReportChip } from 'pages/applicationPage/content/liveProject/common/ProgressReportChip';
import {
  LabelText,
  ListViewBox,
  headerFooterDefaultProps,
  DateStack,
  Icon,
  labelColor,
  Row,
} from 'pages/applicationPage/content/liveProject/sections/OverviewSection/common';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const ClosingPaymentRequestCard = ({
  item,
}: {
  item: ILiveProjectReportItem;
}) => {
  return (
    <ListViewBox itemId={item.id} itemType={item.type}>
      {item.title ? (
        <Box
          sx={headerFooterDefaultProps}
          bgcolor={'grey.300'}
          justifyContent={
            !item.title && item.dueDate ? 'flex-end' : 'space-between'
          }
          borderBottom={'1px solid'}
        >
          {item.title ? <Typography>{item.title}</Typography> : null}
        </Box>
      ) : null}

      <Box p={2}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={6}>
            <Typography variant="h2" fontSize={'1.1em'} fontWeight={700} mb={3}>
              {item.subTitle}
            </Typography>
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
            <Box>
              <Box mb={1} textAlign={'right'}>
                <ProgressReportChip status={item.status} />
              </Box>
              <Box mb={1} textAlign={'left'}>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <LabelText value={'Submitted'} isBold />
                  <ListViewIcon icon={faClock} />
                  <LabelText
                    value={
                      item?.submittedDate
                        ? getPrettyDateStringFromJsonDateString(
                            item.submittedDate
                          )
                        : '-'
                    }
                  />
                </Stack>
              </Box>
              <Box mb={1} textAlign={'left'}>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <LabelText value={'Completed'} isBold />
                  <ListViewIcon icon={faClock} />
                  <LabelText
                    value={
                      item?.completedDate
                        ? getPrettyDateStringFromJsonDateString(
                            item.completedDate
                          )
                        : '-'
                    }
                  />
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={headerFooterDefaultProps}
        justifyContent={'space-between'}
        borderTop={'1px solid'}
      >
        <Box>
          <DateStack
            label="Due Date"
            value={item.dueDate ? item.dueDate : '-'}
          />
        </Box>
        <Box>
          <DateStack
            label="Submitted Date"
            value={item.submittedDate ? item.submittedDate : '-'}
          />
        </Box>
        <Box>
          <DateStack
            label="Completed Date"
            value={item.completedDate ? item.completedDate : '-'}
          />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Row>
            {item.lastModifiedDate ? (
              <DateStack label="Last Modified" value={item.lastModifiedDate} />
            ) : null}
            {item.lastModifiedBy ? (
              <Row>
                <Icon icon={faUser} color={labelColor} />
                <LabelText value={item.lastModifiedBy} />
              </Row>
            ) : null}
          </Row>
        </Box>
      </Box>
    </ListViewBox>
  );
};
