import { applicationApi } from 'api/application/applicationApi';
import { EQuestionType } from 'enums/EQuestionType';
import {
  GetApplicationQuestionHistoryResponse,
  IAddress,
  IAlternativeFundingRoutes,
  IAlternativeFundingRoutesForm,
  IAnyNonResidentialUnits,
  IAnyNonResidentialUnitsForm,
  IAssessmentType,
  IBankAccountVerificationContactDetails,
  IBsrCodeForm,
  IBsrRegistrationCode,
  IBuildingHeight,
  IBuildingName,
  IBuildingNumberOfStoreysForm,
  ICaveatsOrLimitationsForm,
  ICompanyDetails,
  IContactDetails,
  ICostEffectiveCourseOfActionForm,
  IEditApplicationFile,
  IFraewCost,
  IFraewCurrentEvacuationStrategy,
  IFraewCurrentEvacuationStrategyForm,
  IFraewExternalManagementPoliciesForm,
  IFraewExternalWorks,
  IFraewFireRescueServiceAccessRestrictions,
  IFraewFireRescueServiceAccessRestrictionsForm,
  IFraewInstructionDate,
  IFraewInterimMeasures,
  IFraewInterimMeasuresForm,
  IFraewInternalWorks,
  IFraewNumberOfStairwells,
  IFraewNumberOfStairwellsForm,
  IFraewPeerReviewer,
  IFraewReportAuthor,
  IFraewSurveyCompany,
  IFraewSurveyDate,
  IGetAddressRequest,
  IGetApplicationQuestionHistoryRequest,
  IGetCompanyDetailsRequest,
  IGetContactDetailsRequest,
  ILeaseholderEngagement,
  ILeaseholderEngagementForm,
  ILifeSafetyRiskAssessment,
  INumberOfResidentialUnitsForm,
  INumberOfStoreys,
  IOriginalDeveloper,
  IOriginalDeveloperForm,
  IOtherOptionsConsideredForm,
  IPartOfDevelopment,
  IPartOfDevelopmentForm,
  IProjectMilestones,
  IPtfsAmount,
  IPtfsRequired,
  IRecommendCladding,
  IRecommendedInterimMeasures,
  IRecommendedInterimMeasuresForm,
  IRiskSafetyMitigation,
  IRiskSafetyMitigationForm,
  ITotalCladdingArea,
  IUkBankAccountDetails,
  IVatNumber,
  IWhoseBankAccount,
  IGetFraewCladdingSystems,
  IFraewCladdingSystemsForm,
  IEvidenceForAuthorisation,
  IEvidenceForAuthorisationForm,
  IEvidenceForChiefExecutive,
  IEvidenceForChiefExecutiveForm,
  IEvidenceForS151Officer,
  IEvidenceForS151OfficerForm,
} from 'types/applications/ApplicationEditTypes';
import { QueryTags } from 'util/ApiUtils';

export const applicationEditEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getApplicationQuestionHistory: builder.query<
      GetApplicationQuestionHistoryResponse,
      IGetApplicationQuestionHistoryRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: params =>
        `${params.applicationId}/edit/history/${params.questionType}`,
    }),
    getUkBankAccountDetails: builder.query<IUkBankAccountDetails, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/bankAccount`,
    }),
    editUkBankAccountDetails: builder.mutation<any, IUkBankAccountDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
        QueryTags.ApplicationVendorDetails,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bankAccount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getBankAccountVerificationContactDetails: builder.query<
      IBankAccountVerificationContactDetails,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId =>
        `${applicationId}/edit/bankAccountVerificationContact`,
    }),
    editBankAccountVerificationContactDetails: builder.mutation<
      void,
      IBankAccountVerificationContactDetails
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,

        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bankAccountVerificationContact`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editPtfsRequired: builder.mutation<any, IPtfsRequired>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/ptfsRequired`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editPtfsAmount: builder.mutation<any, IPtfsAmount>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/ptfsamount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editVatNumber: builder.mutation<any, IVatNumber>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationVendorDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/vatnumber`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editWhoseBankAccount: builder.mutation<any, IWhoseBankAccount>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
        QueryTags.ApplicationVendorDetails,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/whosebankaccount`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getBuildingAddress: builder.query<IAddress, IGetAddressRequest>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload => `${payload.applicationId}/edit/buildingAddress`,
    }),
    editBuildingAddress: builder.mutation<any, IAddress>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingAddress`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getAddress: builder.query<IAddress, IGetAddressRequest>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.BuildingAddress:
          case EQuestionType.DeveloperAddress:
            return `${payload.applicationId}/edit/buildingDetailsAddress/${payload.questionType}`;
          case EQuestionType.ResponsibleEntityAddress:
          case EQuestionType.RepresentativeAddress:
          default:
            return `${payload.applicationId}/edit/address/${payload.questionType}`;
        }
      },
    }),
    editAddress: builder.mutation<any, IAddress>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.BuildingAddress:
          case EQuestionType.DeveloperAddress:
            return {
              url: `/${payload.applicationId}/edit/buildingDetailsAddress/${payload.questionType}`,
              method: 'PUT',
              body: payload,
            };

          case EQuestionType.ResponsibleEntityAddress:
          case EQuestionType.RepresentativeAddress:
          default:
            return {
              url: `/${payload.applicationId}/edit/address/${payload.questionType}`,
              method: 'PUT',
              body: payload,
            };
        }
      },
    }),
    getCompanyDetails: builder.query<
      ICompanyDetails,
      IGetCompanyDetailsRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload =>
        `${payload.applicationId}/edit/companyDetails/${payload.questionType}`,
    }),
    editCompanyDetails: builder.mutation<any, ICompanyDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/companyDetails/${payload.questionType}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getContactDetails: builder.query<
      IContactDetails,
      IGetContactDetailsRequest
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: payload =>
        `${payload.applicationId}/edit/contactDetails/${payload.questionType}`,
    }),
    editContactDetails: builder.mutation<any, IContactDetails>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/contactDetails/${payload.questionType}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editBuildingHeight: builder.mutation<any, IBuildingHeight>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingHeight`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editNumberOfStoreys: builder.mutation<any, INumberOfStoreys>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/numberOfStoreys`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editTotalCladdingArea: builder.mutation<any, ITotalCladdingArea>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationGrantFunding,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/totalCladdingArea`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editLifeSafetyRiskAssessment: builder.mutation<
      any,
      ILifeSafetyRiskAssessment
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/lifeSafetyRiskAssessment`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editAssessmentType: builder.mutation<any, IAssessmentType>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/assessmentType`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editRecommendCladding: builder.mutation<any, IRecommendCladding>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/recommendCladding`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editApplicationFile: builder.mutation<any, IEditApplicationFile>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => {
        switch (payload.questionType) {
          case EQuestionType.FireRiskReport:
            return {
              url: `/${payload.applicationId}/edit/fireRiskReportFile`,
              method: 'PUT',
              body: payload,
            };
          case EQuestionType.FireRiskSummary:
          default:
            return {
              url: `/${payload.applicationId}/edit/fireRiskSummaryFile`,
              method: 'PUT',
              body: payload,
            };
        }
      },
    }),
    editBsrRegistrationCode: builder.mutation<any, IBsrRegistrationCode>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/bsrRegistrationCode`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editBuildingName: builder.mutation<any, IBuildingName>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/buildingName`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewSurveyCompany: builder.mutation<any, IFraewSurveyCompany>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewSurveyCompany`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewSurveyDate: builder.mutation<any, IFraewSurveyDate>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewSurveyDate`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewInstructionDate: builder.mutation<any, IFraewInstructionDate>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewInstructionDate`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewReportAuthor: builder.mutation<any, IFraewReportAuthor>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewReportAuthor`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewPeerReviewer: builder.mutation<any, IFraewPeerReviewer>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewPeerReviewer`,
        method: 'PUT',
        body: payload,
      }),
    }),
    editFraewCost: builder.mutation<any, IFraewCost>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewCost`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getProjectMilestones: builder.query<IProjectMilestones, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/projectMilestones`,
    }),
    editProjectMilestones: builder.mutation<any, IProjectMilestones>({
      invalidatesTags: [
        QueryTags.ApplicationDetailsEdit,
        QueryTags.ApplicationLiveProjectOverview,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/projectMilestones`,
        method: 'PUT',
        body: payload,
      }),
    }),
    // FRAEW INTERIM MEASURES
    getFraewInterimMeasures: builder.query<IFraewInterimMeasures, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/fraewInterimMeasures`,
    }),
    editFraewInterimMeasures: builder.mutation<void, IFraewInterimMeasuresForm>(
      {
        invalidatesTags: [
          QueryTags.ApplicationDetails,
          QueryTags.ApplicationDetailsEdit,
        ],
        query: ({ applicationId, ...formData }) => ({
          url: `/${applicationId}/edit/fraewInterimMeasures`,
          method: 'PUT',
          body: formData,
        }),
      }
    ),
    // FRAEW CURRENT EVACUATION STRATEGY
    getFraewCurrentEvacuationStrategy: builder.query<
      IFraewCurrentEvacuationStrategy,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId =>
        `${applicationId}/edit/fraewCurrentEvacuationStrategy`,
    }),
    editFraewCurrentEvacuationStrategy: builder.mutation<
      void,
      IFraewCurrentEvacuationStrategyForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/fraewCurrentEvacuationStrategy`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // FRAEW NUMBER OF STAIRWELLS
    getFraewNumberOfStairwells: builder.query<IFraewNumberOfStairwells, string>(
      {
        providesTags: [QueryTags.ApplicationDetailsEdit],
        query: applicationId => `${applicationId}/edit/fraewNumberOfStairwells`,
      }
    ),
    editFraewNumberOfStairwells: builder.mutation<
      void,
      IFraewNumberOfStairwellsForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/fraewNumberOfStairwells`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // FRAEW EXTERNAL MANAGEMENT POLICIES
    editFraewExternalManagementPolicies: builder.mutation<
      void,
      IFraewExternalManagementPoliciesForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/fraewExternalManagementPolicies`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // FRAEW FIRE AND RESCUE SERVICE ACCESS RESTRICTIONS
    getFraewFireRescueServiceAccessRestrictions: builder.query<
      IFraewFireRescueServiceAccessRestrictions,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId =>
        `${applicationId}/edit/fraewFireRescueServiceAccessRestrictions`,
    }),
    editFraewFireRescueServiceAccessRestrictions: builder.mutation<
      void,
      IFraewFireRescueServiceAccessRestrictionsForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/fraewFireRescueServiceAccessRestrictions`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // BSR CODE
    editBsrCode: builder.mutation<void, IBsrCodeForm>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/bsrCode`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // ORIGINAL DEVELOPER
    getOriginalDeveloper: builder.query<IOriginalDeveloper, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/originalDeveloper`,
    }),
    editOriginalDeveloper: builder.mutation<void, IOriginalDeveloperForm>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/originalDeveloper`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // NUMBER OF RESIDENTIAL UNITS
    editNumberOfResidentialUnits: builder.mutation<
      void,
      INumberOfResidentialUnitsForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/numberOfResidentialUnits`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // ANY NON RESIDENTIAL UNITS
    getAnyNonResidentialUnits: builder.query<IAnyNonResidentialUnits, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/anyNonResidentialUnits`,
    }),
    editAnyNonResidentialUnits: builder.mutation<
      void,
      IAnyNonResidentialUnitsForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/anyNonResidentialUnits`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // PART OF DEVELOPMENT
    getPartOfDevelopment: builder.query<IPartOfDevelopment, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/partOfDevelopment`,
    }),
    editPartOfDevelopment: builder.mutation<void, IPartOfDevelopmentForm>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/partOfDevelopment`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // BUILDING NUMBER OF STOREYS
    editBuildingNumberOfStoreys: builder.mutation<
      void,
      IBuildingNumberOfStoreysForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/buildingNumberOfStoreys`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // ALTERNATIVE FUNDING ROUTES
    getRiskSafetyMitigation: builder.query<IRiskSafetyMitigation, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/riskSafetyMitigation`,
    }),
    editRiskSafetyMitigation: builder.mutation<void, IRiskSafetyMitigationForm>(
      {
        invalidatesTags: [
          QueryTags.ApplicationDetails,
          QueryTags.ApplicationDetailsEdit,
        ],
        query: ({ applicationId, ...formData }) => ({
          url: `/${applicationId}/edit/riskSafetyMitigation`,
          method: 'PUT',
          body: formData,
        }),
      }
    ),
    // RISK SAFETY MITIGATION
    getAlternativeFundingRoutes: builder.query<
      IAlternativeFundingRoutes,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/alternativeFundingRoutes`,
    }),
    editAlternativeFundingRoutes: builder.mutation<
      void,
      IAlternativeFundingRoutesForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/alternativeFundingRoutes`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // COST EFFECTIVE COURSE OF ACTION
    editCostEffectiveCourseOfAction: builder.mutation<
      void,
      ICostEffectiveCourseOfActionForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/costEffectiveCourseOfAction`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // OTHER OPTIONS CONSIDERED
    editOtherOptionsConsidered: builder.mutation<
      void,
      IOtherOptionsConsideredForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/otherOptionsConsidered`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // CAVEATS OR LIMITATIONS
    editCaveatsOrLimitations: builder.mutation<void, ICaveatsOrLimitationsForm>(
      {
        invalidatesTags: [
          QueryTags.ApplicationDetails,
          QueryTags.ApplicationDetailsEdit,
        ],
        query: ({ applicationId, ...formData }) => ({
          url: `/${applicationId}/edit/caveatsOrLimitations`,
          method: 'PUT',
          body: formData,
        }),
      }
    ),
    // RECOMMENDED INTERIM MEASURES
    getRecommendedInterimMeasures: builder.query<
      IRecommendedInterimMeasures,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId =>
        `${applicationId}/edit/recommendedInterimMeasures`,
    }),
    editRecommendedInterimMeasures: builder.mutation<
      void,
      IRecommendedInterimMeasuresForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/recommendedInterimMeasures`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // LEASEHOLDER ENGAGEMENT
    getLeaseholderEngagement: builder.query<ILeaseholderEngagement, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/leaseholderEngagement`,
    }),
    editLeaseholderEngagement: builder.mutation<
      void,
      ILeaseholderEngagementForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/leaseholderEngagement`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // INTERNAL WORKS
    getFraewInternalWorks: builder.query<IFraewInternalWorks, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/fraewInternalWorks`,
    }),
    editFraewInternalWorks: builder.mutation<any, IFraewInternalWorks>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewInternalWorks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    // EXTERNAL WORKS
    getFraewExternalWorks: builder.query<IFraewExternalWorks, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/fraewExternalWorks`,
    }),
    editFraewExternalWorks: builder.mutation<any, IFraewExternalWorks>({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/edit/fraewExternalWorks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    // CLADDING SYSTEM TYPES
    getFraewCladdingSystemTypes: builder.query<
      IGetFraewCladdingSystems,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/fraewCladdingSystems`,
    }),
    editFraewCladdingSystemTypes: builder.mutation<
      void,
      IFraewCladdingSystemsForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/fraewCladdingSystems`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // EVIDENCE FOR AUTORISATION
    getEvidenceForAuthorisation: builder.query<
      IEvidenceForAuthorisation,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/evidenceForAuthorisation`,
    }),
    editEvidenceForAuthorisation: builder.mutation<
      void,
      IEvidenceForAuthorisationForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/evidenceForAuthorisation`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // EVIDENCE FOR CHIEF EXECUTIVE
    getEvidenceForChiefExecutive: builder.query<
      IEvidenceForChiefExecutive,
      string
    >({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/evidenceForChiefExecutive`,
    }),
    editEvidenceForChiefExecutive: builder.mutation<
      void,
      IEvidenceForChiefExecutiveForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/evidenceForChiefExecutive`,
        method: 'PUT',
        body: formData,
      }),
    }),
    // EVIDENCE FOR S151 OFFICER
    getEvidenceForS151Officer: builder.query<IEvidenceForS151Officer, string>({
      providesTags: [QueryTags.ApplicationDetailsEdit],
      query: applicationId => `${applicationId}/edit/evidenceForS151Officer`,
    }),
    editEvidenceForS151Officer: builder.mutation<
      void,
      IEvidenceForS151OfficerForm
    >({
      invalidatesTags: [
        QueryTags.ApplicationDetails,
        QueryTags.ApplicationDetailsEdit,
      ],
      query: ({ applicationId, ...formData }) => ({
        url: `/${applicationId}/edit/evidenceForS151Officer`,
        method: 'PUT',
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetApplicationQuestionHistoryQuery,
  useLazyGetUkBankAccountDetailsQuery,
  useEditUkBankAccountDetailsMutation,
  useLazyGetBankAccountVerificationContactDetailsQuery,
  useEditBankAccountVerificationContactDetailsMutation,
  useEditPtfsRequiredMutation,
  useEditPtfsAmountMutation,
  useEditVatNumberMutation,
  useEditWhoseBankAccountMutation,
  useGetAddressQuery,
  useEditAddressMutation,
  useGetBuildingAddressQuery,
  useEditBuildingAddressMutation,
  useGetCompanyDetailsQuery,
  useEditCompanyDetailsMutation,
  useGetContactDetailsQuery,
  useEditContactDetailsMutation,
  useEditBuildingHeightMutation,
  useEditNumberOfStoreysMutation,
  useEditTotalCladdingAreaMutation,
  useEditLifeSafetyRiskAssessmentMutation,
  useEditAssessmentTypeMutation,
  useEditRecommendCladdingMutation,
  useEditApplicationFileMutation,
  useEditBsrRegistrationCodeMutation,
  useEditBuildingNameMutation,
  useEditFraewSurveyCompanyMutation,
  useEditFraewSurveyDateMutation,
  useEditFraewInstructionDateMutation,
  useEditFraewReportAuthorMutation,
  useEditFraewPeerReviewerMutation,
  useEditFraewCostMutation,
  useGetProjectMilestonesQuery,
  useEditProjectMilestonesMutation,
  useGetFraewInterimMeasuresQuery,
  useEditFraewInterimMeasuresMutation,
  useGetFraewCurrentEvacuationStrategyQuery,
  useEditFraewCurrentEvacuationStrategyMutation,
  useGetFraewNumberOfStairwellsQuery,
  useEditFraewNumberOfStairwellsMutation,
  useEditFraewExternalManagementPoliciesMutation,
  useGetFraewFireRescueServiceAccessRestrictionsQuery,
  useEditFraewFireRescueServiceAccessRestrictionsMutation,
  useEditBsrCodeMutation,
  useGetOriginalDeveloperQuery,
  useEditOriginalDeveloperMutation,
  useEditNumberOfResidentialUnitsMutation,
  useGetAnyNonResidentialUnitsQuery,
  useEditAnyNonResidentialUnitsMutation,
  useGetPartOfDevelopmentQuery,
  useEditPartOfDevelopmentMutation,
  useEditBuildingNumberOfStoreysMutation,
  useGetAlternativeFundingRoutesQuery,
  useEditAlternativeFundingRoutesMutation,
  useGetRiskSafetyMitigationQuery,
  useEditRiskSafetyMitigationMutation,
  useEditCostEffectiveCourseOfActionMutation,
  useEditOtherOptionsConsideredMutation,
  useEditCaveatsOrLimitationsMutation,
  useGetRecommendedInterimMeasuresQuery,
  useEditRecommendedInterimMeasuresMutation,
  useGetLeaseholderEngagementQuery,
  useEditLeaseholderEngagementMutation,
  useGetFraewInternalWorksQuery,
  useEditFraewInternalWorksMutation,
  useGetFraewExternalWorksQuery,
  useEditFraewExternalWorksMutation,
  useGetFraewCladdingSystemTypesQuery,
  useEditFraewCladdingSystemTypesMutation,
  useGetEvidenceForAuthorisationQuery,
  useEditEvidenceForAuthorisationMutation,
  useGetEvidenceForChiefExecutiveQuery,
  useEditEvidenceForChiefExecutiveMutation,
  useGetEvidenceForS151OfficerQuery,
  useEditEvidenceForS151OfficerMutation,
} = applicationEditEndpoints;
