import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useGetConfirmEligibilityChecksQuery } from 'api/application';
import {
  useEditCompanyDetailsMutation,
  useGetCompanyDetailsQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { EEligiblityCheckStatus } from 'enums/EEligiblityCheckStatus';
import { EQuestionType } from 'enums/EQuestionType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zCompanyDetailsSchema } from 'types/applications/ApplicationEdit.zod';
import {
  ICompanyDetails,
  IGetCompanyDetailsRequest,
} from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof ICompanyDetails) =>
  nameof<ICompanyDetails>(fieldName);

interface IEditCompanyDetailsDrawerProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditCompanyDetailsDrawer({
  questionType,
  onClose,
  onSuccess,
}: IEditCompanyDetailsDrawerProps) {
  const { applicationId } = useApplicationContext();
  const eligibilityQuery = useGetConfirmEligibilityChecksQuery(applicationId);
  const isEligibilityApproved =
    eligibilityQuery.data?.status === EEligiblityCheckStatus.Approved;

  const getDrawerTitle = () => {
    switch (questionType) {
      case EQuestionType.ResponsibleEntityCompanyDetails:
        return 'Edit What are the company details of the Responsible Entity';
      case EQuestionType.RepresentativeCompanyDetails:
        return 'Edit What are the company details of the Representative';
      case EQuestionType.FreeholderCompanyDetails:
        return 'Edit What are the company details of the Freeholder';
      default:
        return 'Edit Company Details';
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {getDrawerTitle()}
          </Typography>
        </DialogTitle>

        {isEligibilityApproved &&
        questionType === EQuestionType.ResponsibleEntityCompanyDetails ? (
          <EligibilityApproved onClose={onClose} />
        ) : (
          <EditCompanyDetailsForm
            onSuccess={onSuccess}
            onClose={onClose}
            questionType={questionType}
          />
        )}
      </Box>
    </StyledDrawer>
  );
}

interface IEligibilityApprovedProps {
  onClose: () => void;
}

export const EligibilityApproved = ({ onClose }: IEligibilityApprovedProps) => {
  return (
    <>
      <DialogContent>
        <Typography variant="h2">
          The company details cannot be edited as the application eligibility
          has been approved.
        </Typography>
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </StyledDrawerActions>
    </>
  );
};

interface IEditCompanyDetailsFormProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditCompanyDetailsForm = ({
  questionType,
  onClose,
  onSuccess,
}: IEditCompanyDetailsFormProps) => {
  const { applicationId } = useApplicationContext();
  const companyDetailsRequest: IGetCompanyDetailsRequest = {
    applicationId: applicationId,
    questionType: questionType,
  };

  const { data: companyDetails, isLoading: formLoading } =
    useGetCompanyDetailsQuery(companyDetailsRequest);
  const [editCompanyDetails, editCompanyDetailsResult] =
    useEditCompanyDetailsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<ICompanyDetails>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zCompanyDetailsSchema),
  });
  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    const initialFormData = { ...companyDetails, changeReason: '' };
    reset(initialFormData);
  }, [companyDetails, reset]);

  const onSubmit = async (formData: ICompanyDetails) => {
    try {
      formData.applicationId = applicationId;
      formData.questionType = questionType;

      await editCompanyDetails(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Company details updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit company details`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('companyName')}
                  control={control}
                  defaultValue={companyDetails?.companyName ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Company name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('companyRegistrationNumber')}
                  control={control}
                  defaultValue={companyDetails?.companyRegistrationNumber ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Registration Number"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editCompanyDetailsResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
