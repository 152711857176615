import { ComponentProps, ReactNode } from 'react';
import {
  faAdd,
  faArrowDown,
  faArrowUp,
  faClock,
  faEnvelope,
  faFile,
  faPen,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  SxProps,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetApplicationCommunicationsQuery } from 'api/application/communicationsApi';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { AddCommunicationDrawer } from 'pages/applicationPage/content/communications/AddCommunicationDrawer';
import { CommunicationsFilters } from 'pages/applicationPage/content/communications/CommunicationsFilters';
import { CommunicationsInfoPanel } from 'pages/applicationPage/content/communications/CommunicationsInfoPanel';
import { useAppDispatch } from 'state';
import { selectApplicationCommunicationsGridState } from 'state/selectors/applications/applicationsSelectors';
import { communicationsGridStateChanged } from 'state/slices/applications';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  ECommunicationCategoryLanguage,
  ECommunicationType,
  ECommunicationTypeLanguage,
  IApplicationCommunication,
} from 'types/applications/ApplicationCommunicationTypes';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const Communications = () => {
  const dispatch = useAppDispatch();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );

  const applicationCommunicationsGridState = useSelector(
    selectApplicationCommunicationsGridState
  );

  const query = useGetApplicationCommunicationsQuery(
    applicationCommunicationsGridState
  );

  const noResultsFound =
    query.data?.count === 0 && !!applicationCommunicationsGridState.searchValue;

  const { skip, take } = applicationCommunicationsGridState;

  const currentPage = skip / take;

  const {
    isShowing: isAddCommunicationDrawerShowing,
    showModal: showAddCommunicationDrawer,
    hideModal: hideAddCommunicationDrawer,
  } = useModalState();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" fontWeight={900}>
            Communications ({query.data?.count})
          </Typography>
        </Grid>

        {hasAdminApplicationEdit && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showAddCommunicationDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add Communication
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid item xs={12}>
            <CommunicationsFilters />
          </Grid>
          <Grid container spacing={1}>
            <Grid xs={9} item>
              <NoResultsFoundText
                show={noResultsFound}
                text="No Communications."
              />
              <StyledGenericTable data-testid="Application-Communications-Table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Stack rowGap={2}>
                        {query.data?.results?.map(row => (
                          <CardRenderer
                            key={`parent-${row.communicationId}`}
                            item={row}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={query.data?.count || 0}
                      rowsPerPage={take}
                      rowsPerPageOptions={[take]}
                      page={currentPage}
                      onPageChange={(e, newPage) => {
                        const newSkip = newPage * take;
                        dispatch(
                          communicationsGridStateChanged({ skip: newSkip })
                        );
                      }}
                      onRowsPerPageChange={e => {
                        dispatch(
                          communicationsGridStateChanged({
                            take: +e.target.value,
                          })
                        );
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </StyledGenericTable>
            </Grid>
            <Grid xs={3} item sx={{ mt: '1.75rem' }}>
              <CommunicationsInfoPanel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isAddCommunicationDrawerShowing && (
        <AddCommunicationDrawer
          onSuccess={() => {
            hideAddCommunicationDrawer();
          }}
          onClose={() => {
            hideAddCommunicationDrawer();
          }}
        />
      )}
    </>
  );
};

const CardRenderer = ({ item }: { item: IApplicationCommunication }) => {
  return item.communicationTypeId === ECommunicationType.Note ? (
    <NoteCard key={item.communicationId} item={item} />
  ) : (
    <DetailedCard key={item.communicationId} item={item} />
  );
};

const DetailedCard = ({ item }: { item: IApplicationCommunication }) => {
  return (
    <ListViewBox>
      <Box p={2}>
        <Grid
          container
          justifyContent={'space-between'}
          gap={1.5}
          flexWrap="nowrap"
        >
          <Grid item xs={6}>
            <Box display={'flex'} alignItems={'center'}>
              {communicationsIcon(item.communicationTypeId)}
              <Typography
                variant="h2"
                fontSize={'0.9em'}
                fontWeight={700}
                mr={3}
                ml={1}
              >
                {ECommunicationTypeLanguage[item.communicationTypeId]}
              </Typography>
              <Chip
                label={item.isAutomated ? 'Automated' : 'Manual'}
                size="small"
                color={'default'}
              />
              {item?.isInbound ? (
                <Box display={'flex'} ml={3}>
                  <Icon icon={faArrowUp} color={_labelColor} />
                  <Typography
                    variant="body1"
                    fontSize={'0.7em'}
                    fontWeight={600}
                    mr={2}
                    ml={1}
                  >
                    INBOUND
                  </Typography>
                </Box>
              ) : (
                <Box display={'flex'} ml={2}>
                  <Icon icon={faArrowDown} color={_labelColor} />
                  <Typography
                    variant="body1"
                    fontSize={'0.7em'}
                    fontWeight={600}
                    mr={2}
                    ml={1}
                  >
                    OUTBOUND
                  </Typography>
                </Box>
              )}
            </Box>
            <Box mt={3} display={'flex'}>
              <Typography variant="body1" fontSize={'0.9em'} fontWeight={500}>
                {item?.notes}
              </Typography>
            </Box>
          </Grid>
          <Grid item display={'flex'} justifyContent={'flex-end'} gap={2}>
            {item.fileId && item.fileName && (
              <Box>
                <ApplicationFileDownloadWrapper
                  showIconOnly
                  fileName={item.fileName}
                  fileId={item.fileId}
                />
              </Box>
            )}
            <Box sx={{ width: '10rem' }}>
              <Typography
                variant="body1"
                fontSize={'0.9em'}
                fontWeight={600}
                mb={1}
                color={'grey.500'}
              >
                {item?.isAutomated ? 'Recipient' : 'Added by'}
              </Typography>
              <Typography variant="body1" fontSize={'0.9em'} fontWeight={600}>
                {item?.isAutomated ? item?.recipientName : item?.sentByName}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                fontSize={'0.9em'}
                fontWeight={600}
                color={'grey.500'}
                mb={1}
              >
                {item?.isAutomated ? 'Sent' : 'Date'}
              </Typography>
              <Typography variant="body1" fontSize={'0.9em'} fontWeight={600}>
                {item?.isAutomated && item?.sentOnDateTime
                  ? getPrettyDateStringFromJsonDateString(
                      item.sentOnDateTime.toString()
                    )
                  : getPrettyDateStringFromJsonDateString(
                      item.addedOnDateTime.toString()
                    )}
              </Typography>
            </Box>
            <Box textAlign={'right'} sx={{ width: '15rem' }}>
              <Chip
                label={ECommunicationCategoryLanguage[item.category]}
                size="small"
                color={'default'}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ListViewBox>
  );
};

const NoteCard = ({ item }: { item: IApplicationCommunication }) => {
  return (
    <ListViewBox>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        justifyContent={'space-between'}
        pr={2}
        pt={1}
        pb={1}
        pl={2}
        borderBottom={'1px solid rgba(0, 0, 0, 0.08)'}
      >
        <Box display={'flex'} alignItems={'center'}>
          {communicationsIcon(item.communicationTypeId)}
          <Typography
            variant="h2"
            fontSize={'0.9em'}
            fontWeight={700}
            mr={3}
            ml={1}
          >
            {ECommunicationTypeLanguage[item.communicationTypeId]}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Icon icon={faClock} color={_labelColor} />
          <Typography
            variant="body1"
            color={_labelColor}
            fontSize={'0.9em'}
            ml={2}
            sx={{ fontWeight: 600 }}
          >
            {item.addedOnDateTime &&
              getPrettyDateStringFromJsonDateString(
                item.addedOnDateTime.toString()
              )}
          </Typography>
        </Box>
      </Stack>
      <Box p={2}>
        <Typography
          variant="body1"
          fontSize={'0.9em'}
          ml={2}
          sx={{ fontWeight: 500 }}
        >
          {item.notes}
        </Typography>
      </Box>
    </ListViewBox>
  );
};

const ListViewBox = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => {
  return (
    <RoundBorderBox
      sx={{
        ...sx,
        transition: 'all 150ms linear',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '0 3px 15px rgba(0, 0, 0, 0.15)',
        },
      }}
      border={2}
      borderRadius={2}
      onClick={() => {}}
    >
      {children}
    </RoundBorderBox>
  );
};

const _labelColor = 'grey.700';

const Icon = (props: ComponentProps<typeof FontAwesomeIcon>) => {
  return <FontAwesomeIcon {...props} color={'#999'} />;
};

const communicationsIcon = (commsType: ECommunicationType) => {
  if (commsType === ECommunicationType.Phone) {
    return <Icon icon={faPhone} size="sm" color={_labelColor} />;
  } else if (commsType === ECommunicationType.Letter) {
    return <Icon icon={faFile} size="sm" color={_labelColor} />;
  } else if (commsType === ECommunicationType.Email) {
    return <Icon icon={faEnvelope} size="sm" color={_labelColor} />;
  } else {
    return <Icon icon={faPen} size="sm" color={_labelColor} />;
  }
};
