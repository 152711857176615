import { ZodType, z } from 'zod';
import { IFile } from 'common/types/File';
import { EAccessRestriction } from 'enums/EAccessRestriction';
import { EBuildingInterimMeasures } from 'enums/EBuildingInterimMeasures';
import { EDeveloperInBusinessType } from 'enums/EDeveloperInBusinessType';
import { EEvacuationStrategyType } from 'enums/EEvacuationStrategyType';
import { EExternalManagementPolicy } from 'enums/EExternalManagementPolicy';
import { EFundingStillPursuing } from 'enums/EFundingStillPursuing';
import { EIsInInterimMeasures } from 'enums/EIsInInterimMeasures';
import { EPursuedSourcesFundingType } from 'enums/EPursuedSourcesFundingType';
import { ERiskSafetyMitigationType } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  IAlternativeFundingRoutesForm,
  IAnyNonResidentialUnitsForm,
  IBsrCodeForm,
  IBuildingNumberOfStoreysForm,
  ICaveatsOrLimitationsForm,
  ICostEffectiveCourseOfActionForm,
  IEvidenceForAuthorisationForm,
  IEvidenceForChiefExecutiveForm,
  IEvidenceForS151OfficerForm,
  IFraewCladdingSystemType,
  IFraewCladdingSystemsForm,
  IFraewCurrentEvacuationStrategyForm,
  IFraewExternalManagementPoliciesForm,
  IFraewFireRescueServiceAccessRestrictionsForm,
  IFraewInterimMeasuresForm,
  IFraewNumberOfStairwellsForm,
  ILeaseholderEngagementForm,
  INumberOfResidentialUnitsForm,
  IOriginalDeveloperForm,
  IOtherOptionsConsideredForm,
  IPartOfDevelopmentForm,
  IRecommendedInterimMeasuresForm,
  IRiskSafetyMitigationForm,
} from 'types/applications/ApplicationEditTypes';

export const zUkBankAccountDetailsSchema = z.object({
  accountName: z.string().nonempty('Name on the account is required'),
  bankName: z.string().nonempty('Bank name is required'),
  branchName: z.string().nonempty('Branch name is required'),
  accountNumber: z
    .string()
    .nonempty('Account number is required')
    .transform(value => value.replace(/\D/gu, ''))
    .refine(
      value => value.length >= 6 && value.length <= 8,
      'Must be between 6 and 8 digits long'
    ),
  sortCode: z
    .string()
    .nonempty('Sort code is required')
    .transform(value => value.replace(/\D/gu, ''))
    .refine(value => value.length === 6, 'Must be 6 digits long'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBankAccountVerificationContactDetailsSchema = z.object({
  contactName: z.string().nonempty('Contact name is required'),
  contactNumber: z.string().nonempty('Contact number is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zPtfsRequiredSchema = z.object({
  ptfsRequired: z.boolean({
    required_error: 'Ptfs required is required',
    invalid_type_error: 'Ptfs required is required',
  }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zPtfsAmountSchema = z.object({
  ptfsAmount: z.coerce
    .number()
    .gt(0, { message: 'PTFS amount must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zVatNumberSchema = z.object({
  vatNumber: z
    .string()
    .nonempty('VAT number is required')
    .regex(
      /^[A-Za-z]{2}[0-9]{9}$/,
      'Must be 2 letters followed by 9 digits, e.g. GB123456789'
    ),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zWhoseBankAccountSchema = z.object({
  whoseBankAccount: z.number({
    required_error: 'Whose bank account is required',
  }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zAddressSchema = z.object({
  nameNumber: z.string().nonempty('Name / Number is required'),
  addressLine1: z.string().nonempty('Address line 1 is required'),
  addressLine2: z.string().optional(),
  city: z.string().nonempty('City is required'),
  county: z.string().optional(),
  postcode: z.string().nonempty('Postcode is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zCompanyDetailsSchema = z.object({
  companyName: z.string().nonempty('Company name is required'),
  companyRegistrationNumber: z
    .string()
    .nonempty('Registration number is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zContactDetailsSchema = z.object({
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string().nonempty('Last name is required'),
  emailAddress: z.string().nonempty('Email address is required'),
  contactNumber: z.string().nonempty('Contact number is required'),
  additionalContactNumber: z.string().optional(),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBuildingHeightSchema = z.object({
  buildingHeight: z.coerce
    .number()
    .gt(0, { message: 'Building height must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zNumberOfStoreysSchema = z.object({
  numberOfStoreys: z.coerce
    .number()
    .gt(0, { message: 'Number of storeys must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zTotalCladdingAreaSchema = z.object({
  totalCladdingArea: z.coerce
    .number()
    .gt(0, { message: 'Total cladding area must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zLifeSafetyRiskAssessmentSchema = z.object({
  lifeSafetyRiskAssessment: z.coerce
    .number()
    .gt(0, { message: 'Life Safety risk assessment must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zAssessmentTypeSchema = z.object({
  assessmentType: z.coerce
    .number()
    .gt(0, { message: 'Assessment type must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zRecommendCladdingSchema = z.object({
  recommendCladding: z.coerce
    .number()
    .gt(0, { message: 'Recommend cladding must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zApplicationFileSchema = z.object({
  fileId: z.string().nonempty('File Id is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBsrRegistrationCodeSchema = z.object({
  bsrRegistrationCode: z
    .string()
    .nonempty('BSR registration code is required')
    .regex(/^[A-ZA-Z0-9]{12}$/, 'BSR registration code must be 12 digits'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zBuildingNameSchema = z.object({
  buildingName: z
    .string()
    .nonempty('Building name is required')
    .max(200, '200 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewSurveyCompanySchema = z.object({
  surveyCompany: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: 'FRAEW survey company is required',
      invalid_type_error: 'FRAEW survey company is required',
    }
  ),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewSurveyDateSchema = z.object({
  surveyDate: z.string().nonempty('FRAEW survey date is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewInstructionDateSchema = z.object({
  instructionDate: z.string().nonempty('FRAEW instruction date is required'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewReportAuthorSchema = z.object({
  reportAuthor: z
    .string()
    .nonempty('FRAEW report author is required')
    .max(150, '150 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewPeerReviewerSchema = z.object({
  peerReviewer: z
    .string()
    .nonempty('FRAEW peer reviewer is required')
    .max(150, '150 characters is the maximum allowed.'),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

export const zFraewCostSchema = z.object({
  cost: z.coerce
    .number()
    .gt(0, { message: 'PTFS amount must be greater than 0' }),
  changeReason: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});

const changeReasonSchema = z
  .string()
  .nonempty('Comments is required')
  .max(1000, '1000 characters is the maximum allowed.');

export const zFraewInterimMeasuresSchema: ZodType<
  Omit<IFraewInterimMeasuresForm, 'applicationId'>
> = z
  .object({
    isInInterimMeasures: z.nativeEnum(EIsInInterimMeasures),
    interimMeasures: z.array(z.nativeEnum(EBuildingInterimMeasures)).optional(),
    otherInterimMeasures: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.isInInterimMeasures === EIsInInterimMeasures.Yes &&
        (schema.interimMeasures === undefined ||
          schema.interimMeasures.length === 0)
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Interim measures is required',
      path: ['interimMeasures'],
    }
  )
  .refine(
    schema => {
      if (
        schema.interimMeasures &&
        schema.interimMeasures.length > 0 &&
        schema.interimMeasures.includes(EBuildingInterimMeasures.Other) &&
        (schema.otherInterimMeasures === undefined ||
          schema.otherInterimMeasures.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Other interim measures is required',
      path: ['otherInterimMeasures'],
    }
  );

export const zFraewCurrentEvacuationStrategySchema: ZodType<
  Omit<IFraewCurrentEvacuationStrategyForm, 'applicationId'>
> = z
  .object({
    evacuationStrategy: z.nativeEnum(EEvacuationStrategyType),
    otherStrategy: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.evacuationStrategy === EEvacuationStrategyType.Other &&
        (!schema.otherStrategy || schema.otherStrategy.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Other strategy is required',
      path: ['otherStrategy'],
    }
  );

export const zFraewNumberOfStairwellsSchema: ZodType<
  Omit<IFraewNumberOfStairwellsForm, 'applicationId'>
> = z
  .object({
    numberOfStairwellsKnown: z.boolean({
      errorMap: () => ({ message: 'Known number of stairwells is required.' }),
    }),
    numberOfStairwells: z.coerce.number().nullable().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (schema.numberOfStairwellsKnown && !schema.numberOfStairwells) {
        return false;
      }
      return true;
    },
    {
      message: 'Number of stairwells is required',
      path: ['numberOfStairwells'],
    }
  );

export const zFraewExternalManagementPoliciesSchema: ZodType<
  Omit<IFraewExternalManagementPoliciesForm, 'applicationId'>
> = z.object({
  policy: z.nativeEnum(EExternalManagementPolicy),
  changeReason: changeReasonSchema,
});

export const zFraewFireRescueServiceAccessRestrictionsSchema: ZodType<
  Omit<IFraewFireRescueServiceAccessRestrictionsForm, 'applicationId'>
> = z
  .object({
    doesHaveAccessRestrictions: z.nativeEnum(EAccessRestriction),
    accessRestrictions: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.doesHaveAccessRestrictions === EAccessRestriction.Yes &&
        (!schema.accessRestrictions || schema.accessRestrictions.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Access restrictions is required',
      path: ['accessRestrictions'],
    }
  );

export const zBsrCodeSchema: ZodType<Omit<IBsrCodeForm, 'applicationId'>> =
  z.object({ hasBsrCode: z.boolean(), changeReason: changeReasonSchema });

export const zOriginalDeveloperSchema: ZodType<
  Omit<IOriginalDeveloperForm, 'applicationId'>
> = z
  .object({
    isOriginalDeveloperKnown: z.boolean(),
    developerCompanyName: z.string().optional(),
    isStillInBusiness: z
      .nativeEnum(EDeveloperInBusinessType)
      .nullable()
      .optional(),
    hasContactedDeveloper: z.boolean().nullable().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.isOriginalDeveloperKnown &&
        (!schema.developerCompanyName ||
          schema.developerCompanyName.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Developer company name is required',
      path: ['developerCompanyName'],
    }
  )
  .refine(
    schema => {
      if (
        schema.isOriginalDeveloperKnown &&
        schema.hasContactedDeveloper === null
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Has developer been contacted is required',
      path: ['hasContactedDeveloper'],
    }
  )
  .refine(
    schema => {
      if (schema.isOriginalDeveloperKnown && !schema.isStillInBusiness) {
        return false;
      }
      return true;
    },
    {
      message: 'Still in business is required',
      path: ['isStillInBusiness'],
    }
  );

export const zNumberOfResidentialUnitsSchema: ZodType<
  Omit<INumberOfResidentialUnitsForm, 'applicationId'>
> = z.object({
  units: z.coerce
    .number({
      errorMap: () => ({ message: 'Number of residential units is required' }),
    })
    .gt(0, { message: 'Number of residential units must be greater than 0.' }),
  changeReason: changeReasonSchema,
});

export const zAnyNonResidentialUnitsSchema: ZodType<
  Omit<IAnyNonResidentialUnitsForm, 'applicationId'>
> = z
  .object({
    anyNonResidentialUnits: z.boolean(),
    numberOfUnits: z.number().nullable().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (schema.anyNonResidentialUnits && !schema.numberOfUnits) {
        return false;
      }
      return true;
    },
    {
      message: 'Number of units is required',
      path: ['numberOfUnits'],
    }
  );

export const zPartOfDevelopmentSchema: ZodType<
  Omit<IPartOfDevelopmentForm, 'applicationId'>
> = z
  .object({
    isPartOfDevelopment: z.boolean(),
    nameOfDevelopment: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.isPartOfDevelopment &&
        (!schema.nameOfDevelopment || schema.nameOfDevelopment.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Name of development is required',
      path: ['nameOfDevelopment'],
    }
  );

export const zBuildingNumberOfStoreysSchema: ZodType<
  Omit<IBuildingNumberOfStoreysForm, 'applicationId'>
> = z.object({
  numberOfStoreys: z.coerce
    .number({
      errorMap: () => ({ message: 'Number of storeys is required' }),
    })
    .gt(0, { message: 'Number of  storeys must be greater than 0.' }),
  changeReason: changeReasonSchema,
});

export const zAlternateFundingRoutesSchema: ZodType<
  Omit<IAlternativeFundingRoutesForm, 'applicationId'>
> = z
  .object({
    pursuedOtherFundingSources: z.nativeEnum(EPursuedSourcesFundingType),
    whichFundingRoutes: z.array(z.nativeEnum(EFundingStillPursuing)).optional(),

    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.pursuedOtherFundingSources ===
          EPursuedSourcesFundingType.PursuingOtherRoutes &&
        (!schema.whichFundingRoutes || schema.whichFundingRoutes?.length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Which routes of funding are you still pursuing is required',
      path: ['whichFundingRoutes'],
    }
  );

export const zRiskSafetyMitigationSchema: ZodType<
  Omit<IRiskSafetyMitigationForm, 'applicationId'>
> = z
  .object({
    riskSafetyMitigation: z.array(z.nativeEnum(ERiskSafetyMitigationType)),
    otherRiskSafteyMitigation: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.riskSafetyMitigation.includes(ERiskSafetyMitigationType.Other) &&
        !schema.otherRiskSafteyMitigation
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Other potential courses of action is required',
      path: ['otherRiskSafteyMitigation'],
    }
  );

export const zCostEffectiveCourseOfActionSchema: ZodType<
  Omit<ICostEffectiveCourseOfActionForm, 'applicationId'>
> = z.object({
  summary: z.string({
    required_error: 'Most cost effective course of action is required',
  }),
  changeReason: changeReasonSchema,
});

export const zOtherOptionsConsideredSchema: ZodType<
  Omit<IOtherOptionsConsideredForm, 'applicationId'>
> = z.object({
  summary: z.string({
    required_error: 'Other options is required',
  }),
  changeReason: changeReasonSchema,
});

export const zCaveatsOrLimitationsSchema: ZodType<
  Omit<ICaveatsOrLimitationsForm, 'applicationId'>
> = z.object({
  summary: z.string({
    required_error: 'Caveats or limitations is required',
  }),
  changeReason: changeReasonSchema,
});

export const zRecommendedInterimMeasuresSchema: ZodType<
  Omit<IRecommendedInterimMeasuresForm, 'applicationId'>
> = z
  .object({
    hasRecommendedMeasures: z.boolean({
      required_error:
        'Do you recommend the building be put into interim measures is required',
    }),
    recommendedMeasures: z.array(z.nativeEnum(EBuildingInterimMeasures)),
    otherRecommendedMeasure: z.string().optional(),
    changeReason: changeReasonSchema,
  })
  .refine(
    schema => {
      if (
        schema.hasRecommendedMeasures === true &&
        (schema.recommendedMeasures === undefined ||
          schema.recommendedMeasures.length === 0)
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Recommended interim measures is required',
      path: ['recommendedMeasures'],
    }
  )
  .refine(
    schema => {
      if (
        schema.recommendedMeasures &&
        schema.recommendedMeasures.length > 0 &&
        schema.recommendedMeasures.includes(EBuildingInterimMeasures.Other) &&
        !schema.otherRecommendedMeasure
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Other recommended interim measures is required',
      path: ['otherRecommendedMeasure'],
    }
  );

const fileSchema: ZodType<IFile> = z.object({
  id: z.string(),
  name: z.string(),
  extension: z.string().nullable(),
});

export const zLeaseholderEngagementSchema: ZodType<
  Omit<ILeaseholderEngagementForm, 'applicationId'>
> = z.object({
  files: z
    .array(fileSchema)
    .min(1, 'At least one uploaded document is required'),
  changeReason: changeReasonSchema,
});

export const zFraewCladdingSystemsSchema = ({
  manufactruresList,
  claddingTypeList,
  insulationTypeList,
}: {
  manufactruresList: IFraewCladdingSystemType[];
  claddingTypeList: IFraewCladdingSystemType[];
  insulationTypeList: IFraewCladdingSystemType[];
}): ZodType<Omit<IFraewCladdingSystemsForm, 'applicationId'>> => {
  return z.object({
    claddingSystems: z.array(
      z
        .object({
          id: z.string().nullable().optional(),
          claddingTypeId: z.number().nullable().optional(),
          otherCladdingType: z.string().optional(),
          claddingManufacturerId: z.number().nullable().optional(),
          otherCladdingManufacturer: z.string().optional(),
          insulationTypeId: z.number().nullable().optional(),
          otherInsulationType: z.string().optional(),
          insulationManufacturerId: z.number().nullable().optional(),
          otherInsulationManufacturer: z.string().optional(),
        })
        .refine(
          schema => {
            if (!schema.claddingTypeId) {
              return false;
            }
            return true;
          },
          {
            message: 'Cladding type is required',
            path: ['claddingTypeId'],
          }
        )
        .refine(
          schema => {
            if (!schema.claddingManufacturerId) {
              return false;
            }
            return true;
          },
          {
            message: 'Cladding manufacturer is required',
            path: ['claddingManufacturerId'],
          }
        )
        .refine(
          schema => {
            if (!schema.insulationTypeId) {
              return false;
            }
            return true;
          },
          {
            message: 'Insulation type is required',
            path: ['insulationTypeId'],
          }
        )
        .refine(
          schema => {
            if (!schema.insulationManufacturerId) {
              return false;
            }
            return true;
          },
          {
            message: 'Insulation manufacturer is required',
            path: ['insulationManufacturerId'],
          }
        )
        .refine(
          schema => {
            if (
              claddingTypeList.find(({ id }) => schema.claddingTypeId === id)
                ?.isOther &&
              !schema.otherCladdingType
            ) {
              return false;
            }
            return true;
          },
          {
            message: 'Other cladding type is required',
            path: ['otherCladdingType'],
          }
        )
        .refine(
          schema => {
            if (
              manufactruresList.find(
                ({ id }) => schema.claddingManufacturerId === id
              )?.isOther &&
              !schema.otherCladdingManufacturer
            ) {
              return false;
            }
            return true;
          },
          {
            message: 'Other cladding type is required',
            path: ['otherCladdingManufacturer'],
          }
        )
        .refine(
          schema => {
            if (
              insulationTypeList.find(
                ({ id }) => schema.insulationTypeId === id
              )?.isOther &&
              !schema.otherInsulationType
            ) {
              return false;
            }
            return true;
          },
          {
            message: 'Other cladding type is required',
            path: ['otherInsulationType'],
          }
        )
        .refine(
          schema => {
            if (
              manufactruresList.find(
                ({ id }) => schema.insulationManufacturerId === id
              )?.isOther &&
              !schema.otherInsulationManufacturer
            ) {
              return false;
            }
            return true;
          },
          {
            message: 'Other cladding type is required',
            path: ['otherInsulationManufacturer'],
          }
        )
    ),

    changeReason: changeReasonSchema,
  });
};

export const zEvidenceForAuthorisationSchema: ZodType<
  Omit<IEvidenceForAuthorisationForm, 'applicationId'>
> = z.object({
  files: z
    .array(fileSchema)
    .min(1, 'At least one uploaded document is required'),
  changeReason: changeReasonSchema,
});

export const zEvidenceForChiefExecutiveSchema: ZodType<
  Omit<IEvidenceForChiefExecutiveForm, 'applicationId'>
> = z.object({
  files: z
    .array(fileSchema)
    .min(1, 'At least one uploaded document is required'),
  changeReason: changeReasonSchema,
});

export const zEvidenceForS151OfficerSchema: ZodType<
  Omit<IEvidenceForS151OfficerForm, 'applicationId'>
> = z.object({
  files: z
    .array(fileSchema)
    .min(1, 'At least one uploaded document is required'),
  changeReason: changeReasonSchema,
});
