import { ReactNode } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useCurrentUserPermissions } from 'hooks';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { getGbpAmount } from 'util/AppUtils';

export const PaymentRequestCostsFooterSection = ({
  totalGrantFunding = 0,
  totalScheduledCosts = 0,
  totalConfirmedCosts = 0,
  totalPaidCosts = 0,
  readOnly = false,
}: {
  totalGrantFunding: number;
  totalScheduledCosts: number;
  totalConfirmedCosts: number;
  totalPaidCosts: number;
  readOnly?: boolean;
}) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminApplicationEdit = doesUserHaveSinglePermission(
    'admin.application.edit'
  );
  
  const {
    reset,
    formState: { defaultValues },
  } = useFormContext();

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.PaymentRequest,
  });
  return (
    <>
      <Box>
        <Grid
          container
          wrap="nowrap"
          columnGap={8}
          sx={{
            borderBottom: '1px solid lightgray',
            pb: 2,
            pt: 4,
          }}
        >
          <Grid item xs={5}>
            <TitleValueBlock>
              <TitleText>Unprofiled costs</TitleText>
              <Typography
                variant="subtitle1"
                fontSize="1em"
                textAlign={'right'}
                color={
                  totalGrantFunding - totalConfirmedCosts > 0
                    ? '#ff0000'
                    : '#000000'
                }
              >
                {getGbpAmount({
                  value: totalGrantFunding - totalConfirmedCosts,
                })}
              </Typography>
            </TitleValueBlock>
          </Grid>
          <Grid item xs={3.5}>
            <TitleValueBlock>
              <TitleText>Total submitted schedule of works</TitleText>
              <ValueText>
                {getGbpAmount({ value: totalScheduledCosts })}
              </ValueText>
            </TitleValueBlock>
          </Grid>
          <Grid item xs={3.5}>
            <TitleValueBlock>
              <TitleText>Total confirmed schedule of works</TitleText>
              <Typography
                variant="subtitle1"
                fontSize="1em"
                fontWeight={800}
                textAlign={'right'}
              >
                {getGbpAmount({ value: totalConfirmedCosts })}
              </Typography>
            </TitleValueBlock>
          </Grid>
        </Grid>
        {!readOnly && hasAdminApplicationEdit && (
          <Grid container wrap="nowrap">
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Box
                  display="flex"
                  justifyContent="end"
                  mt={3}
                  bgcolor="white.100"
                >
                  <Box gap={2} display="flex">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        reset(defaultValues);
                      }}
                      disabled={isOnHoldForThisType || readOnly}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={
                        totalGrantFunding - totalConfirmedCosts < 0 ||
                        isOnHoldForThisType ||
                        readOnly
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

const TitleValueBlock = ({ children }: { children: ReactNode }) => (
  <Stack justifyContent="space-between" height="100%" width="fit-content">
    {children}
  </Stack>
);

const TitleText = ({ children }: { children: ReactNode }) => (
  <Typography variant="subtitle1" fontSize="1em">
    {children}
  </Typography>
);

const ValueText = ({ children }: { children: ReactNode }) => (
  <Typography variant="subtitle1" fontSize="1em" textAlign={'right'}>
    {children}
  </Typography>
);
