import { Box, Divider, Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useGetVariationChecksQuery,
  useGetVariationOverviewQuery,
  useSaveVariationChecksFieldsMutation,
} from 'api/application/variationApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { CardTitle } from 'common/components/cardTitle';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUser, useLocalSnackbar } from 'hooks';
import {
  IVariationOverview,
  VariationkChecksForm,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  ApprovedOrRejectedBanner,
  CostChangesBox,
  ScopeBox,
  SummaryCardRow,
  TimescaleChangesBox,
  VariationTypesBox,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/components';
import {
  allowedVariationVariancePercentage,
  useApplicationAndVariationIdOrNull,
  useApplicationAndVariationIdOrSkipToken,
  useIsVariationReadOnly,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import {
  ActionButtons,
  useApproveModal,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection';
import { QuestionAndComment } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection/common';
import { _formId } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection/utils';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

export const VariationChecksSection = () => {
  const queryParams = useApplicationAndVariationIdOrSkipToken();
  const overview = useGetVariationOverviewQuery(queryParams);
  const checks = useGetVariationChecksQuery(queryParams);
  const isLoading = overview.isLoading || checks.isLoading;
  const isSuccess = checks.isSuccess && checks.isSuccess;
  return (
    <RoundBorderBox>
      <CardTitle
        title="Variation request checks"
        sx={{ fontSize: '1.1em', p: 2 }}
      />
      <Divider />
      <Box p={2}>
        <Stack gap={2}>
          <SummaryCardRow />

          {isLoading ? <FormSkeleton /> : null}

          <AlertForQueryErrorOrNull
            isError={overview.isError}
            error={overview.error}
          />

          <AlertForQueryErrorOrNull
            isError={checks.isError}
            error={checks.error}
          />

          {isSuccess && overview.data && checks.data ? (
            <ConfirmSectionMain
              overviewData={overview.data}
              checksData={checks.data}
            />
          ) : null}
        </Stack>
      </Box>
    </RoundBorderBox>
  );
};

export const ConfirmSectionMain = ({
  overviewData,
  checksData,
}: {
  overviewData: IVariationOverview;
  checksData: Partial<VariationkChecksForm>;
}) => {
  const form = useForm<VariationkChecksForm>({
    defaultValues: {
      ...checksData,
    },
  });

  const { handleSubmit, getValues, trigger } = form;
  const [saveChecks, saveStatus] = useSaveVariationChecksFieldsMutation();
  const { createErrorSnackbar } = useLocalSnackbar();
  const queryParams = useApplicationAndVariationIdOrNull();
  const {
    costVariation,
    hasCostType,
    isVariancePercentageGreaterThanThreshold,
    isApprovedOrRejected,
    hasTimescaleType,
    hasScopeType,
    hasThirdPartyContributionType,
    variationPercentage,
  } = useVariationOverview();
  const { isHeUser } = useCurrentUser();

  const handleSave = async (onSuccess: () => void) => {
    if (!queryParams) {
      createErrorSnackbar('Could not get variation id');
      return;
    }

    const isValid = await trigger();
    if (!isValid) {
      createErrorSnackbar('Please complete all fields and try again');
      return;
    }

    const formData = getValues();

    saveChecks({
      ...queryParams,
      formData,
    })
      .unwrap()
      .then(() => {
        onSuccess();
      });
  };

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Variation,
  });

  const approvalModal = useApproveModal();

  const onSubmit = () => {
    handleSave(() => {
      if (hasCostType) {
        if (!costVariation) {
          createErrorSnackbar(`Could not get cost variation`);
          return;
        }

        // HE users are allowed to approve if over the threshold
        // Davies users are not allowed to approve over the threshold
        if (!isHeUser && isVariancePercentageGreaterThanThreshold) {
          createErrorSnackbar(
            `Cost variation percentage is ${variationPercentage}%. This must be ${allowedVariationVariancePercentage}% or below.`
          );
          return;
        }
      }

      approvalModal.showApproveModal();
    });
  };

  const isSubmitted = !!overviewData.dateSubmitted;
  const { isVariationReadOnly } = useIsVariationReadOnly();

  return (
    <>
      <FormProvider {...form}>
        <Stack gap={2}>
          {isApprovedOrRejected ? (
            <ApprovedOrRejectedBanner overviewData={overviewData} />
          ) : null}

          {isOnHoldForThisType && (
            <OnHoldBanner holdType={EOnHoldType.Variation} />
          )}

          <Grid container columnSpacing={2}>
            <Grid item xs={7}>
              {isSubmitted ? (
                <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                  <Box>
                    <QuestionAndComment
                      label="Has the variation been evidenced by reasonable justification for the change?"
                      fieldName={'variationEvidenceReasonable'}
                      readOnly={isVariationReadOnly}
                    />

                    {hasTimescaleType ? (
                      <QuestionAndComment
                        label="Is the proposed extension to the project end date greater than or equal to two months?"
                        fieldName={'proposedExtensionGreaterThanTwoMonths'}
                        readOnly={isVariationReadOnly}
                      />
                    ) : null}

                    {hasCostType ? (
                      <QuestionAndComment
                        label="Are all costs eligible for grant funding?"
                        fieldName={'allCostsEligibleForGrantFunding'}
                        readOnly={isVariationReadOnly}
                      />
                    ) : null}

                    {hasScopeType ? (
                      <QuestionAndComment
                        label="Has the scope of the project materially varied to impact project cost and/or time?"
                        fieldName={'hasScopeMateriallyVaried'}
                        readOnly={isVariationReadOnly}
                      />
                    ) : null}

                    {hasThirdPartyContributionType ? (
                      <QuestionAndComment
                        label="Have Homes England approved the third party contribution?"
                        fieldName={'thirdPartyContributionApproved'}
                        readOnly={isVariationReadOnly}
                      />
                    ) : null}
                  </Box>
                </form>
              ) : (
                <RoundBorderBox bgcolor={'gray.100'} p={3}>
                  <CardTitle title="Variation is yet to be submitted" />
                </RoundBorderBox>
              )}
            </Grid>
            <Grid item xs={5}>
              <Stack gap={2}>
                <VariationTypesBox data={overviewData} />
                <CostChangesBox data={overviewData} />
                <TimescaleChangesBox data={overviewData} />
                <ScopeBox data={overviewData} />
              </Stack>
            </Grid>
          </Grid>

          <AlertForQueryErrorOrNull
            isError={saveStatus.isError}
            error={saveStatus.error}
          />
        </Stack>

        {!isApprovedOrRejected && isSubmitted ? (
          <ActionButtons handleSave={handleSave} saveStatus={saveStatus} />
        ) : null}
      </FormProvider>

      {approvalModal.renderApproveModal()}
    </>
  );
};
