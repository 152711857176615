import { IFile } from 'common/types/File';
import { ERejectedPaymentStatus } from 'enums/ERejectedPaymentsStatus';
import { ERiskStatus } from 'enums/ERiskStatus';
import { ETeamRole } from 'enums/ETeamRole';
import { EReferralStatus } from 'types/applications/ReferralTypes';

export enum EPaymentRequestSection {
  Overview,
  PaymentRequest,
  VariationChecks,
  ProjectTeam,
  PaymentRequestChecks,
}

export enum EClosingPaymentRequestSection {
  Overview,
  PaymentRequest,
  ClosingChecks,
  ClosingPaymentRequestChecks,
}

export enum EPaymentRecommendationStatus {
  Todo = 1,
  InProgress = 2,
  Submitted = 3,
  Sent = 4,
  Approved = 5,
  Expired = 6,
  Rejected = 7,
  Missed = 8,
  Paid = 9,
}

export interface IPaymentRequestRequest {
  applicationId: string;
  paymentRequestId: string;
}

export interface ICommonPaymentTypes {
  totalGrantFunding: number;
  paymentMonth: string;
  recommendedAmount: number;
  additionalRecommendedAmountTitle: string;
  additionalRecommendedAmount?: number;
  requestedAmount: number;
  additionalRequestedAmountTitle: string;
  additionalRequestedAmount?: number;
  scheduledAmount: number;
}

export interface IPaymentRequestOverview extends ICommonPaymentTypes {
  totalGrantPaidToDate: number;
  totalUnClaimedGrant: number;
  projectDurationInMonths: number;
  operationsReasonForCostChange: string;
  applicantReasonForCostChange: string;
  paymentRecommendation: EPaymentRecommendationStatus;
  paymentRecommendationBy: string;
  paymentRecommendationDate: string;
  paymentRecommendationReason: string;
  costReports: Array<IFile>;
  submittedDate: string;
}

export interface IPaymentRequestClosingChecks {
  projectCostEvidenceFiles: Array<IFile>;
  buildingRegulatoryApprovalFiles: Array<IFile>;
  practicalCompletionCertificateFiles: Array<IFile>;
  exitFraewFiles: Array<IFile>;
  leaseholderAndResidentEvidenceFiles: Array<IFile>;
  worksContractFiles?: Array<IFile>;
}

export interface IClosingPaymentRequestOverview
  extends IPaymentRequestOverview {
  projectedCompletionDate: string;
  fraewRiskAssessment: ERiskStatus;
}

export interface IPaymentRequestChecksDetails extends ICommonPaymentTypes {
  buildingName: string;
}

export interface IClosingPaymentRequestChecksDetails
  extends ICommonPaymentTypes {
  buildingName: string;
  projectedCompletionDate: string;
  fraewRiskAssessment: ERiskStatus;
}

export interface IPaymentRequestProjectTeam {
  id: string;
  name: string;
  role: string;
  teamRole: ETeamRole;
  companyName: string;
  companyRegistration: string;
  primaryContactNumber: string;
  contractSigned: boolean;
  emailAddress: string;
  appropriateLevelsOfProfessionalIndemnity: boolean;
  fireRiskSafetyEngineerInvolved: boolean;
  hasChasCertification?: boolean;
  isGrantCertifyingOfficer: boolean;
  isNew: boolean;
}

export enum EPaymentRequestStatus {
  NotStarted = 1,
  InProgress = 2,
  Submitted = 3,
  Complete = 4,
}

export interface IPaymentRequestPaymentItem {
  itemName: string;
  amount: number;
  paid: boolean;
}

export interface IRejectedPaymentRequestItem {
  itemName: string;
  amount: number;
  status: ERejectedPaymentStatus;
}

export interface IPaymentRequestFinalPaymentItem {
  id?: string;
  itemName: string;
  scheduledCost: number;
  submittedCost: number;
  confirmedCost: number;
}

export interface IPaymentRequestCostItem {
  id: string;
  itemName: string;
  scheduledCost: number;
  submittedCost: number;
  confirmedCost?: number;
  isDirty: boolean;
}

export interface IPaymentRequestCosts {
  id: string;
  paymentRequestId?: string;
  grantPaidToDate: Array<IPaymentRequestPaymentItem> | null;
  rejectedPayments?: Array<IRejectedPaymentRequestItem> | null;
  currentPayments: Array<IPaymentRequestCostItem> | null;
  monthlyCosts: Array<IPaymentRequestCostItem> | null;
  finalPayment: IPaymentRequestFinalPaymentItem | null;
  reasonForCostChange: string;
}

export interface IPaymentRequestChecks extends IPaymentRequestReferralRequest {
  isPaymentRequestCostsConfirmed: boolean;
  basicChecksCompleted: boolean | null;
  basicChecksCompletedReason: string | null;
  correctBuildingOnCostReport: boolean | null;
  correctBuildingOnCostReportReason: string | null;
  signedOffByGrantCertifyingOfficer: boolean | null;
  signedOffByGrantCertifyingOfficerReason: string | null;
  costReportInFormatSpecified: boolean | null;
  costReportInFormatSpecifiedReason: string | null;
  paymentAmount: number;
  approve?: boolean;
}

export interface IClosingPaymentRequestChecks
  extends IPaymentRequestReferralRequest {
  isPaymentRequestCostsConfirmed: boolean;
  basicChecksCompleted: boolean | null;
  basicChecksCompletedReason: string | null;
  sufficientEvidenceToCoverTotalGrantClaimed: boolean | null;
  sufficientEvidenceToCoverTotalGrantClaimedReason: string | null;
  worksIdentifiedCompleted: boolean | null;
  worksIdentifiedCompletedReason: string | null;
  riskLevelMitigatedToTolerableLevel: boolean | null;
  riskLevelMitigatedToTolerableLevelReason: string | null;
  practicalCompletionCertificationProvidedAndApproved: boolean | null;
  practicalCompletionCertificationProvidedAndApprovedReason: string | null;
  uploadedCertification: boolean | null;
  uploadedCertificationReason: string | null;
  evidenceProvidedToLeaseHoldersAndSurvey: boolean | null;
  evidenceProvidedToLeaseHoldersAndSurveyReason: string | null;
  recommendPaymentApproval: boolean | null;
  recommendPaymentApprovalReason: string | null;
  paymentAmount: number;
  approve?: boolean;
}

export interface IPaymentRequestReferApplicationForm {
  reason: string;
}

export interface IGetPaymentRequestReferralsResponse {
  results: IPaymentRequestReferral[];
}

export interface IPaymentRequestReferral {
  id: string;
  reason: string;
  status: EReferralStatus;
  completeReason: string | null;
  referredByUserId: string;
}

export interface IPaymentRequestReferralRequest {
  applicationId: string;
  paymentRequestId: string;
}

export interface IAddPaymentRequestReferral
  extends IPaymentRequestReferralRequest {
  reason: string;
}

export interface IPaymentRequestCompleteReferralRequest
  extends IPaymentRequestCompleteReferral {
  applicationId: string;
  paymentRequestId: string;
  referralId: string;
}

export interface IPaymentRequestCompleteReferral {
  completeReason: string;
}

export const paymentRequestBooleanProps = [
  'basicChecksCompleted',
  'correctBuildingOnCostReport',
  'signedOffByGrantCertifyingOfficer',
  'costReportInFormatSpecified',
] as const;

export type PaymentRequestChecksBooleans = Record<
  (typeof paymentRequestBooleanProps)[number],
  boolean
>;

export type PaymentRequestChecksReasons = {
  [Property in keyof PaymentRequestChecksBooleans as `${Property}Reason`]: string;
};

export type PaymentRequestChecksForm = PaymentRequestChecksBooleans &
  PaymentRequestChecksReasons;

export const closingPaymentRequestBooleanProps = [
  'basicChecksCompleted',
  'sufficientEvidenceToCoverTotalGrantClaimed',
  'worksIdentifiedCompleted',
  'riskLevelMitigatedToTolerableLevel',
  'practicalCompletionCertificationProvidedAndApproved',
  'uploadedCertification',
  'evidenceProvidedToLeaseHoldersAndSurvey',
  'recommendPaymentApproval',
] as const;

export type ClosingPaymentRequestChecksBooleans = Record<
  (typeof closingPaymentRequestBooleanProps)[number],
  boolean
>;

export type ClosingPaymentRequestChecksReasons = {
  [Property in keyof ClosingPaymentRequestChecksBooleans as `${Property}Reason`]: string;
};

export type ClosingPaymentRequestChecksForm =
  ClosingPaymentRequestChecksBooleans & ClosingPaymentRequestChecksReasons;

export interface IPaymentRequestVariations {
  projectDatesChanged: boolean;
  unsafeCladdingBeenRemoved: boolean;
  monthOfUnsafeCladdingRemoval: string;
  costsChanged: boolean;
  thirdPartyContributionsChanged: boolean;
  originalStartDate: string;
  currentStartDate: string;
  originalEndDate: string;
  currentEndDate: string;
}

export interface IPaymentRequestRejectForm extends IPaymentRequestRequest {
  reason: string;
}

export interface IPaymentRequestExpireForm extends IPaymentRequestRequest {
  reason: string;
}

export interface IFRAEWChip {
  riskData: ERiskStatus;
}
