import { Stack, Typography } from '@mui/material';
import { useModalState } from 'hooks';
import {
  EVariationType,
  IEditVariationThirdPartyContributionForm,
  IVariationBoxProps,
  IVariationOverview,
  ThirdPartyContributionPursuingType,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { EditThirdPartyContributionDrawer } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/ThirdPartyContributionBox/EditThirdPartyContributionDrawer';
import { VariationOverviewBox } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';
import { isOfType } from 'util/AppUtils';

export const ThirdPartyContributionChangesBox = ({
  data,
  isEditable = false,
}: IVariationBoxProps) => {
  const variation = data.variations.find(
    isOfType(EVariationType.ThirdPartyContribution)
  );

  const {
    isShowing: isEditThirdPartyContributionDrawerShowing,
    showModal: showEditThirdPartyContributionDrawerDialog,
    hideModal: hideEditThirdPartyContributionDrawerDialog,
  } = useModalState();

  if (!variation) {
    return null;
  }

  const thirdPartyContributionInitialData: IEditVariationThirdPartyContributionForm =
    {
      contributionAmount: variation?.amount ?? 0,
      contributionPursuingTypes:
        variation?.pursuingTypes && variation?.pursuingTypes.length > 0
          ? variation?.pursuingTypes.map(({ id }) => id)
          : [],
      contributionNotes: variation?.notes ?? '',
    };

  return (
    <>
      <VariationOverviewBox
        title="Third party contribution changes"
        onEditClick={
          !isEditable
            ? undefined
            : () => {
                showEditThirdPartyContributionDrawerDialog();
              }
        }
      >
        <ThirdPartyContributionView variation={variation} />
      </VariationOverviewBox>

      {isEditThirdPartyContributionDrawerShowing && (
        <EditThirdPartyContributionDrawer
          onConfirm={() => {
            hideEditThirdPartyContributionDrawerDialog();
          }}
          onCancel={() => {
            hideEditThirdPartyContributionDrawerDialog();
          }}
          initialData={thirdPartyContributionInitialData}
        />
      )}
    </>
  );
};

const ThirdPartyContributionView = ({
  variation,
}: {
  variation: Extract<
    IVariationOverview['variations'][number],
    { type: EVariationType.ThirdPartyContribution }
  >;
}) => {
  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography>Amount</Typography>
        <Typography>{`£${variation.amount.toLocaleString()}`}</Typography>
      </Stack>

      {variation.pursuingTypes.length === 0 ? (
        <Stack direction="row" justifyContent={'space-between'}>
          <Typography>Contribution Type</Typography>
          <Typography>-</Typography>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent={'space-between'}>
          <Typography sx={{ width: '20em' }}>Contribution Type</Typography>
          <Stack direction="column">
            {variation.pursuingTypes.map(
              (
                pursuingType: ThirdPartyContributionPursuingType,
                index: number
              ) => (
                <Typography key={index} mb={1}>
                  {pursuingType.description}
                </Typography>
              )
            )}
          </Stack>
        </Stack>
      )}

      <Stack direction="row" justifyContent={'space-between'}>
        <Typography sx={{ width: '20em' }}>Details</Typography>
        <Typography>{variation.notes}</Typography>
      </Stack>
    </Stack>
  );
};
