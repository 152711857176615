import { useEffect } from 'react';
import {
  faPlus,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  useEditFraewCladdingSystemTypesMutation,
  useGetFraewCladdingSystemTypesQuery,
} from 'api/application/applicationEditApi';
import {
  useGetManufacturesQuery,
  useGetInsulationTypesQuery,
  useGetCladdingSystemTypesQuery,
} from 'api/application/lookUpApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewCladdingSystemsSchema } from 'types/applications/ApplicationEdit.zod';
import {
  IFraewCladdingSystemType,
  IFraewCladdingSystemsForm,
  IGetFraewCladdingSystems,
  INumberedCladdingSystem,
} from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditFraewCladdingSystemTypesDrawerProps {
  onSuccess: () => void;
  onClose: () => void;
}

export const EditFraewCladdingSystemTypesDrawer = ({
  onSuccess,
  onClose,
}: IEditFraewCladdingSystemTypesDrawerProps) => {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit FRAEW Cladding Systems</Typography>
          <Typography variant="body1" mt={2}>
            Identify each of the cladding systems which contribute to the life
            safety risk of the building. For each cladding system identify the
            cladding type and the insulation material as well as the relevant
            manufacturers were known
          </Typography>
        </DialogTitle>

        <EditFraewCladdingSystemTypesForm
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditFraewCladdingSystemRowProps {
  control: Control<IFraewCladdingSystemsForm>;
  row: FieldArrayWithId<IFraewCladdingSystemsForm, 'claddingSystems', 'id'>;
  index: number;
  claddingSystemTypesData: IFraewCladdingSystemType[];
  insulationTypesData: IFraewCladdingSystemType[];
  manufacturesData: IFraewCladdingSystemType[];
  onRemoveClick: (index: number) => void;
  fetchedFormData?: IGetFraewCladdingSystems;
}

const EditFraewCladdingSystemRow = ({
  control,
  row,
  index,
  claddingSystemTypesData,
  insulationTypesData,
  manufacturesData,
  fetchedFormData,
  onRemoveClick,
}: IEditFraewCladdingSystemRowProps) => {
  const theme = useTheme();
  const {
    formState: { errors },
  } = useFormContext<IFraewCladdingSystemsForm>();

  const hasRowGotAnError =
    Object.keys(errors?.claddingSystems?.[index] ?? {}).length !== 0
      ? true
      : false;

  /**
   * Aliasing the type here as object fetched to populate form contains claddingNumber,
   * but the request type coupled with RHF does not contain claddingNumber
   * */
  const claddingNumber =
    (row as INumberedCladdingSystem)?.claddingNumber || null;

  return (
    <Accordion key={row?.id} sx={{ mb: 2 }}>
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/** GET CLADDING NUMBER IN HERE */}
            <Typography mr={2}>
              Cladding System{' '}
              {claddingNumber !== null ? claddingNumber : '*New*'}
            </Typography>

            {hasRowGotAnError ? (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color={theme.palette.error.main}
              />
            ) : null}
          </Box>
          <IconButton size="small" onClick={() => onRemoveClick(index)}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2}>
          {/* CLADDING TYPE */}

          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.claddingTypeId`}
              render={({
                field: { value, onChange, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  options={claddingSystemTypesData?.map(({ id }) => id) || []}
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(opt, val) => opt === val}
                  getOptionLabel={optId =>
                    claddingSystemTypesData?.find(({ id }) => optId === id)
                      ?.name || ''
                  }
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Cladding Type"
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.otherCladdingType`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  label="If 'other' selected please describe here"
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          {/* CLADDING MANUFACTURER */}
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.claddingManufacturerId`}
              render={({
                field: { value, onChange, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  options={manufacturesData?.map(({ id }) => id) || []}
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(opt, val) => opt === val}
                  getOptionLabel={optId =>
                    manufacturesData?.find(({ id }) => optId === id)?.name || ''
                  }
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Cladding Manufacturer"
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.otherCladdingManufacturer`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  label="If 'other' selected please describe here"
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          {/* INSULATION TYPE */}
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.insulationTypeId`}
              render={({
                field: { value, onChange, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  options={insulationTypesData?.map(({ id }) => id) || []}
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(opt, val) => opt === val}
                  getOptionLabel={optId =>
                    insulationTypesData?.find(({ id }) => optId === id)?.name ||
                    ''
                  }
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Inuslation Type"
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.otherInsulationType`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  label="If 'other' selected please describe here"
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          {/* INSULATION MANUFACTURER */}
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.insulationManufacturerId`}
              render={({
                field: { value, onChange, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  options={manufacturesData?.map(({ id }) => id) || []}
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  isOptionEqualToValue={(opt, val) => opt === val}
                  getOptionLabel={optId =>
                    manufacturesData?.find(({ id }) => optId === id)?.name || ''
                  }
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Insulation Manufacturer"
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={`claddingSystems.${index}.otherInsulationManufacturer`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  label="If 'other' selected please describe here"
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface IEditFraewCladdingSystemTypesFormProps {
  onSuccess: () => void;
  onClose: () => void;
}

export const EditFraewCladdingSystemTypesForm = ({
  onSuccess,
  onClose,
}: IEditFraewCladdingSystemTypesFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: manufacturesData, isLoading: isManufacturesLoading } =
    useGetManufacturesQuery();
  const { data: insulationTypesData, isLoading: isInsulationTypesLoading } =
    useGetInsulationTypesQuery();
  const {
    data: claddingSystemTypesData,
    isLoading: isCladdingSystemTypesLoading,
  } = useGetCladdingSystemTypesQuery();

  const { data: formData, isLoading: isFormLoading } =
    useGetFraewCladdingSystemTypesQuery(applicationId);

  const [editFraewCladdingSystemTypes, editFraewCladdingSystemTypesResult] =
    useEditFraewCladdingSystemTypesMutation();

  const isLoading =
    isManufacturesLoading ||
    isInsulationTypesLoading ||
    isCladdingSystemTypesLoading ||
    isFormLoading;

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewCladdingSystemsForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(
      zFraewCladdingSystemsSchema({
        claddingTypeList: claddingSystemTypesData || [],
        insulationTypeList: insulationTypesData || [],
        manufactruresList: manufacturesData || [],
      })
    ),
  });
  const { handleSubmit, reset, control } = form;

  const { fields, append, remove } = useFieldArray<IFraewCladdingSystemsForm>({
    control,
    name: 'claddingSystems',
    rules: {
      maxLength: 20,
    },
  });

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  const onSubmit = async (formData: IFraewCladdingSystemsForm) => {
    try {
      formData.applicationId = applicationId;

      await editFraewCladdingSystemTypes(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`FRAEW cladding systems successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW cost`);
    }
  };

  const onInvalidSubmit = () => {
    createWarningSnackbar('Please correct form validation errors.');
  };

  return isLoading ? null : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit, onInvalidSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          {fields?.map((item, index) => {
            return (
              <EditFraewCladdingSystemRow
                key={`${item.id}`}
                control={control}
                index={index}
                row={item}
                fetchedFormData={formData}
                claddingSystemTypesData={claddingSystemTypesData || []}
                insulationTypesData={insulationTypesData || []}
                manufacturesData={manufacturesData || []}
                onRemoveClick={remove}
              />
            );
          })}
          {fields.length < 5 ? (
            <Button
              sx={{ mt: 2, width: '100%' }}
              variant="outlined"
              onClick={() =>
                append({
                  id: null,
                  claddingManufacturerId: null,
                  claddingTypeId: null,
                  insulationTypeId: null,
                  insulationManufacturerId: null,
                })
              }
            >
              Add Cladding System
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginLeft: '0.25rem' }}
              />
            </Button>
          ) : (
            <Typography variant="body1" mt={2}>
              Cladding system limit reached
            </Typography>
          )}

          <Grid container columnSpacing={2}>
            <Grid item xs={12} mt={4}>
              <Controller
                name="changeReason"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewCladdingSystemTypesResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
