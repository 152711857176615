import { Masonry } from '@mui/lab';
import { Box, Divider, Stack } from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ReferralsGrid } from 'common/components/referrals/ReferralsGrid';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { CardTitle } from 'pages/applicationPage/content/liveProject/common';
import { EVariationTypeLanguage } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  VariationTypesBox,
  CostChangesBox,
  SummaryCardRow,
  TimescaleChangesBox,
  ReasonBox,
  ScopeBox,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/components';
import { ApprovedOrRejectedBanner } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/ApprovedOrRejectedBanner';
import { ThirdPartyContributionChangesBox } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/ThirdPartyContributionBox/ThirdPartyContributionChangesBox';
import {
  useApplicationAndVariationIdOrNull,
  useIsVariationReadOnly,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { HomesEnglandReferralBanner } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationOverviewSection';
import { EReferralRecordType } from 'types/applications/ReferralTypes';

export const VariationOverviewSection = () => {
  const { query, isApprovedOrRejected } = useVariationOverview();
  const { isVariationReadOnly } = useIsVariationReadOnly();
  const { isLoading, isSuccess, isError, error, data } = query;

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess && data) {
    const hasAllVariationTypes =
      data.variations.length === Object.keys(EVariationTypeLanguage).length;

    return (
      <Stack gap={2}>
        <SummaryCardRow />

        <RoundBorderBox>
          <CardTitle title="Overview" sx={{ fontSize: '1.1em', p: 2 }} />
          <Divider />

          <Stack p={2} gap={2}>
            <ReferralsCard />

            <HomesEnglandReferralBanner />

            {isApprovedOrRejected ? (
              <ApprovedOrRejectedBanner overviewData={data} />
            ) : null}

            <Box sx={{ mr: -2 }}>
              <Masonry columns={2} spacing={2}>
                <VariationTypesBox
                  data={data}
                  isEditable={!isVariationReadOnly && !hasAllVariationTypes}
                />

                <CostChangesBox data={data} isEditable={!isVariationReadOnly} />

                <ScopeBox data={data} isEditable={!isVariationReadOnly} />

                <TimescaleChangesBox
                  data={data}
                  isEditable={!isVariationReadOnly}
                />

                <ThirdPartyContributionChangesBox
                  data={data}
                  isEditable={!isVariationReadOnly}
                />

                <ReasonBox data={data} isEditable={!isVariationReadOnly} />
              </Masonry>
            </Box>
          </Stack>
        </RoundBorderBox>
      </Stack>
    );
  }

  return null;
};

const ReferralsCard = () => {
  const appIdAndVariationId = useApplicationAndVariationIdOrNull();

  if (!appIdAndVariationId) {
    return null;
  }

  return (
    <ReferralsGrid
      referralRecordType={EReferralRecordType.Variation}
      variationId={appIdAndVariationId.variationId}
    />
  );
};
