import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditBankAccountVerificationContactDetailsMutation,
  useLazyGetBankAccountVerificationContactDetailsQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zBankAccountVerificationContactDetailsSchema } from 'types/applications/ApplicationEdit.zod';
import { IBankAccountVerificationContactDetails } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IBankAccountVerificationContactDetails) =>
  nameof<IBankAccountVerificationContactDetails>(fieldName);

interface IEditBankAccountVerificationContactDetailsDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditBankAccountVerificationContactDetailsDrawer({
  onClose,
  onSuccess,
}: IEditBankAccountVerificationContactDetailsDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            Edit Bank Account Verification Contact Details
          </Typography>
        </DialogTitle>
        <EditBankAccountVerificationContactDetailsForm
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IEditBankAccountVerificationContactDetailsFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditBankAccountVerificationContactDetailsForm = ({
  onClose,
  onSuccess,
}: IEditBankAccountVerificationContactDetailsFormProps) => {
  const { applicationId } = useApplicationContext();

  const [getBankAccountVerificationContactDetails] =
    useLazyGetBankAccountVerificationContactDetailsQuery();
  const [
    editBankAccountVerificationContactDetails,
    editBankAccountVerificationContactDetailsResult,
  ] = useEditBankAccountVerificationContactDetailsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [
    bankAccountVerificationContactDetails,
    setBankAccountVerificationContactDetails,
  ] = useState<IBankAccountVerificationContactDetails>();
  const [formLoading, setFormLoading] = useState(true);

  const form = useForm<IBankAccountVerificationContactDetails>({
    defaultValues: bankAccountVerificationContactDetails,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zBankAccountVerificationContactDetailsSchema),
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(bankAccountVerificationContactDetails);
  }, [bankAccountVerificationContactDetails, reset]);

  useEffect(() => {
    if (formLoading) {
      getBankAccountVerificationContactDetails(applicationId).then(result => {
        if (result && result.data) {
          setBankAccountVerificationContactDetails(result.data);
          setFormLoading(false);
        }
      });
    } else {
      setFormLoading(false);
    }
  }, [
    applicationId,
    formLoading,
    getBankAccountVerificationContactDetails,
    reset,
  ]);

  const onSubmit = async (formData: IBankAccountVerificationContactDetails) => {
    try {
      formData.applicationId = applicationId;
      await editBankAccountVerificationContactDetails(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `Bank account verification contact details updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(
        `Failed to edit bank account verification contact details`
      );
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('contactName')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Contact Name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('contactNumber')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Contact Number"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={
                  editBankAccountVerificationContactDetailsResult.isLoading
                }
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
