import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography, Box } from '@mui/material';
import { ILiveProjectReportItem } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { ProgressReportChip } from 'pages/applicationPage/content/liveProject/common/ProgressReportChip';
import {
  DateStack,
  Icon,
  labelColor,
  LabelText,
  ListViewBox,
  Row,
  headerFooterDefaultProps,
} from 'pages/applicationPage/content/liveProject/sections/OverviewSection/common';

export const VariationCard = ({ item }: { item: ILiveProjectReportItem }) => {
  return (
    <ListViewBox itemId={item.id} itemType={item.type}>
      {item.title || item.dueDate ? (
        <Box
          sx={headerFooterDefaultProps}
          bgcolor={'grey.300'}
          justifyContent={
            !item.title && item.dueDate ? 'flex-end' : 'space-between'
          }
          borderBottom={'1px solid'}
        >
          {item.title ? (
            <Typography>{item.title.toUpperCase()}</Typography>
          ) : null}

          {item.dueDate ? (
            <DateStack label="Due Date" value={item.dueDate} />
          ) : null}
        </Box>
      ) : null}

      <Box p={2}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={6}>
            <Typography variant="h2" fontSize={'1.1em'} fontWeight={700} mb={3}>
              {item.subTitle}
            </Typography>
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
            <Box mb={1} textAlign={'right'}>
              <ProgressReportChip status={item.status} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={headerFooterDefaultProps}
        justifyContent={'space-between'}
        borderTop={'1px solid'}
      >
        <Box>
          <DateStack
            label="Start Date"
            value={item.startDate ? item.startDate : '-'}
          />
        </Box>
        <Box>
          <DateStack
            label="Submitted Date"
            value={item.submittedDate ? item.submittedDate : '-'}
          />
        </Box>
        <Box>
          <DateStack
            label="Completed Date"
            value={item.completedDate ? item.completedDate : '-'}
          />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Row>
            {item.lastModifiedDate ? (
              <DateStack label="Last Modified" value={item.lastModifiedDate} />
            ) : null}
            {item.lastModifiedBy ? (
              <Row>
                <Icon icon={faUser} color={labelColor} />
                <LabelText value={item.lastModifiedBy} />
              </Row>
            ) : null}
          </Row>
        </Box>
      </Box>
    </ListViewBox>
  );
};
