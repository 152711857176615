import { Typography } from "@mui/material";

const _labelColor = 'grey.700';


export const LabelText = ({
  value,
  isBold = false,
}: {
  value: string;
  isBold?: boolean;
}) => {
  return (
    <Typography
      variant="body1"
      color={_labelColor}
      fontSize={'0.95em'}
      width={'8em'}
      sx={isBold ? { fontWeight: 600 } : null}
    >
      {value}
    </Typography>
  );
};