import { Box, Stack, Typography } from '@mui/material';
import { IWorksPackageOverview } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const ThirdPartyContributionsCard = ({
  title,
  data,
}: {
  title: string;
  data?: IWorksPackageOverview['thirdPartyContributions'];
}) => {
  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={4}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        {title}
      </Typography>

      <ThirdPartyContributionsCardData
        data={data as IWorksPackageOverview['thirdPartyContributions']}
      />
    </Box>
  );
};

export const ThirdPartyContributionsCardData = ({
  data,
}: {
  data: IWorksPackageOverview['thirdPartyContributions'];
}) => {
  const stackedData: [string, string | string[]][] = [
    [
      'Pursuing Third Party Contribution',
      data?.pursuingThirdPartyContribution !== undefined
        ? data?.pursuingThirdPartyContribution
          ? 'Yes'
          : 'No'
        : '-',
    ],
    [
      'Type',
      data?.contributionTypes && data?.contributionTypes.length > 0
        ? data?.contributionTypes
        : '-',
    ],
    [
      'Amount',
      data?.contributionAmount
        ? `£${data?.contributionAmount.toLocaleString()}`
        : '-',
    ],
    ['Details', data?.contributionNotes ?? '-'],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) =>
        typeof value === 'string' ? (
          <CardDetailsStacked key={i} title={title} value={value} />
        ) : (
          <CardDetailsListStacked key={i} title={title} value={value} />
        )
      )}
    </>
  );
};

const CardDetailsStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <CardDataTitle title={title} />
      <Typography fontSize={'0.8em'} fontWeight={600} marginLeft={2}>
        {value}
      </Typography>
    </Box>
  );
};

const CardDetailsListStacked = ({
  title,
  value,
}: {
  title: string;
  value: string[];
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <Stack direction="column" gap={2}>
          <CardDataTitle title={title} />
          {value.map((element, index) => {
            return (
              <Typography key={index} variant="body1" fontSize={'0.8em'} fontWeight={600} marginLeft={7} >
                {element}
              </Typography>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

const CardDataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
